import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Typography } from 'antd';
import AuthService, { user$ } from '../services/auth-service';
import { useObservable } from 'rxjs-hooks';
import { User } from '../models/user';
import { SearchOutlined } from '@ant-design/icons';
import { useEffect } from 'react';
import { PusherChannel } from 'laravel-echo/dist/channel';

const { Title, Paragraph } = Typography;


function IntroPage() {
    const navigate = useNavigate();
    const currentUser : User|null = useObservable(user$, null);
    const authService = AuthService.getInstance();

    
    // The following lines is an example for listening public channels
    // let webSocketsChannel : PusherChannel;
    // useEffect(() => {
    //     webSocketsChannel = window.Echo.channel('test-channel');
    //     webSocketsChannel.listen('TestEvent', (e:any)=>console.log(e));
    // }, []);

    // useEffect(() => () => {
    //     if (webSocketsChannel) webSocketsChannel.stopListening('TestEvent');
    // },[]);



    return (
        <div className='intro_page'>
            <Title level={4} style={{textAlign:'center',whiteSpace:'pre'}}>
                Καλώς ήρθατε στο Εθνικό Μητρώο Νεοπλασματικών Νοσημάτων
            </Title>
            <br/><br/>

            <div style={{maxWidth:800, margin:'auto'}}>
                <Paragraph>
                    Μέσα από τη διαδικτυακή αυτή πλατφόρμα, μπορείτε να καταχωρείτε στοιχεία για ενήλικες ασθενείς με νεοπλασματικά νοσήματα. 
                </Paragraph>

                <Paragraph>
                    Στη διαδικτυακή αυτή πλατφόρμα μπορείτε να ανασύρετε και να καταχωρήσετε πληροφορία για ασθενείς με νεοπλασματικά νοσήματα, στους οποίους ο φορέας παροχής υπηρεσιών υγείας στον οποίο υπηρετείτε παρέχει υπηρεσίες υγείας. 
                </Paragraph>

                <Paragraph>
                    Υποχρέωση καταχώρησης στοιχείων στο Εθνικό Μητρώο Ασθενών με Νεοπλασματικά Νοσήματα (ΕΜΝΝ) έχουν ιατροί των ειδικοτήτων που καθορίζονται με την απόφαση 6197/2024 του Υπουργείου Υγείας (<a href='/950b-24.pdf' target="_blank">ΦΕΚ Β950/9.2.2024</a>).
                </Paragraph>

                <Paragraph>
                    Λόγω της ανάγκης διασύνδεσης και επικοινωνίας με το Ευρωπαϊκό Πληροφοριακό Σύστημα για τον Καρκίνο (ECIS), η καταχώρηση διαγνώσεων νεοπλασματικών νοσημάτων στο παρόν Μητρώο υιοθετεί τη χρήση της κωδικοποίησης <a href="/icdO3-eng.pdf" target="_blank">ICD-O-3</a> (και στην <a href="/icdO3-el.pdf" target="_blank">ελληνική γλώσσα</a>). Η πρακτική αυτή κωδικοποιεί σε ενιαία διάγνωση την τοπογραφία, τη μορφολογία, τη συμπεριφορά και τον ιστολογικό βαθμό κακοήθειας της νόσου. Η καταγραφή των τοπογραφιών διαφέρει ελαφρά από την κωδικοποίηση <a href="/icd10-el.pdf" target="_blank">ICD-10</a>.
                </Paragraph>
                <Paragraph>
                    Για την είσοδό σας στη Διαδικτυακή Πλατφόρμα θα πρέπει να χρησιμοποιήσετε το όνομα χρήστη και τον κωδικό εισόδου που χρησιμοποιείτε στην Ηλεκτρονική Συνταγογράφηση.
                </Paragraph>
            </div>
            <br/>
             {<div style={{textAlign:'center'}}>
                {!currentUser ? <Button type='primary' onClick={()=>window.location.href = authService.getLoginUrl()}>Είσοδος</Button> : <Button type='primary' icon={<SearchOutlined/>} onClick={()=>navigate('/amka-search')}>Αναζήτηση</Button>}
            </div>}
        </div>
    );
}

export default IntroPage;