import { ReactNode, useEffect, useState } from "react";
import { DatePicker, Empty, Form, Input, InputNumber, Select } from "antd";
import { BaseFhirModel } from "../models/fhir/base-fhir-model";
import { FhirResourceFriendlyName, FhirResourceName, isFieldValueDifferent } from "../helpers/fhir-message-helper";
import { PathologoReport } from "../models/pathologoReport";
import dayjs from "dayjs";
import { DATE_FORMAT } from "../constants";
import { DiagnosticReport } from "../models/fhir/diagnostic-report";
import Icdo3Service from "../services/icdo3-service";
import TerminologyService from "../services/terminology-service";
import { Patient } from "../models/patient";
import { ContentComponentParams } from "./fields-container";

export class PathologoReportsComponentParams implements ContentComponentParams  {
    panelTitle:string = "Πορίσματα Παθολογοανατομικών Εξετάσεων";
    initialValues: PathologoReport[]=[];
    constructor(public patient:Patient) {}
    getComponents(key:string, isEditMode:boolean, initialValues?:PathologoReport[], onValueChange?: (itemIndex: number, newValue:PathologoReport, fhirMessages:BaseFhirModel[], isValid:boolean) => void) : ReactNode  {
        if (!initialValues || initialValues.length === 0) return <Empty />
        return initialValues.map((initialValue, index) => <div key={`${key}-${index}`}><PathologoReportComponent isEditMode={isEditMode} isAddingNew={false} initialValue={initialValue} patient={this.patient} onValueChange={(newValue, fhirMessages, isValid) => {
            if (onValueChange) onValueChange(index, newValue, fhirMessages, isValid);
        }} />
            {initialValues.length - 1 > index && <hr />}</div>);
    };
};



const PathologoReportComponent: React.FC<{
    isEditMode: boolean,
    initialValue: PathologoReport,
    patient: Patient,
    isAddingNew: boolean,
    onValueChange?: (newValue: PathologoReport, fhirMessages: BaseFhirModel[], isValid: boolean) => void
}> = ({ isEditMode, isAddingNew, initialValue, patient, onValueChange }) => {

    const [rawText, setRawText] = useState<string | undefined>(initialValue.rawText);
    const [laboratory, setLaborotory] = useState<string | undefined>(initialValue.laboratory);
    const [reportDate, setReportDate] = useState<dayjs.Dayjs | undefined>(initialValue.reportDate ? dayjs(initialValue.reportDate) : undefined);

    useEffect(() => {
        if ((isEditMode || isAddingNew) && !reportDate) {
            setReportDate(patient.dateOfDeath ? dayjs(patient.dateOfDeath) : dayjs());
        }

    }, [isEditMode, isAddingNew]);

    useEffect(() => {
        if (onValueChange) {
            const fhirMessages: BaseFhirModel[] = [];

            if (!dayjs(initialValue.reportDate).isSame(reportDate, 'day') ||
                isFieldValueDifferent(initialValue.rawText, rawText) ||
                isFieldValueDifferent(initialValue.laboratory, laboratory)) {
                fhirMessages.push({
                    fhirResourceName: FhirResourceName.diagnosticreport,
                    friendlyResourceName: FhirResourceFriendlyName.pathologoReport,
                    conclusion: rawText,
                    issued: reportDate ? reportDate.toDate() : undefined,
                    performer: laboratory,
                    isOccuredDateVisible: false,
                    wireData: {
                        id: initialValue.id,
                        patientId: patient.id,
                        text: rawText,
                        labName: laboratory,
                        effectiveDateTime: reportDate ? reportDate.toDate() : undefined,
                    }
                } as DiagnosticReport);
            }

            const newPathologoReport = new PathologoReport(initialValue.id);
            newPathologoReport.reportDate = reportDate ? reportDate.toDate() : undefined;
            newPathologoReport.rawText = rawText;
            newPathologoReport.laboratory = laboratory;

            onValueChange(newPathologoReport, fhirMessages, ((reportDate !== undefined && reportDate !== null) && rawText !== undefined));
        }
    }, [reportDate, rawText, laboratory]);

    return (
        <>
            <Form
                layout='vertical'
                autoComplete="off"
            >
                <Form.Item
                    label="Ημερομηνία εξέτασης"
                    validateStatus={reportDate ? 'success' : 'error'}
                    help={reportDate ? '' : 'Το πεδίο απαιτείται'}
                >
                    {isEditMode && <DatePicker disabledDate={(current)=>patient.dateOfDeath ? current.isAfter(dayjs(patient.dateOfDeath)): current.isAfter(dayjs())} format={DATE_FORMAT} value={reportDate} allowClear={true} onChange={(newDate) => setReportDate(newDate)} />}
                    {!isEditMode && <div className='field-value-text'>{reportDate ? reportDate.format(DATE_FORMAT) : "-"}</div>}
                </Form.Item>

                <Form.Item
                    style={{ width: '100%' }}
                    label="Εργαστήριο"
                >
                    {isEditMode && <Input max={255} value={laboratory} onChange={(e => setLaborotory(e.target.value))} />}
                    {!isEditMode && <div className='field-value-text'>{laboratory ?? '-'}</div>}
                </Form.Item>

                <Form.Item
                    style={{ width: '100%' }}
                    label="Πλήρες κείμενο εξέτασης"
                    validateStatus={rawText ? 'success' : 'error'}
                    help={rawText ? '' : 'Το πεδίο απαιτείται'}
                >
                    {isEditMode && <Input.TextArea maxLength={3000} rows={10} value={rawText} onChange={(e => setRawText(e.target.value))} />}
                    {!isEditMode && <div className='field-value-text' style={{ whiteSpace: 'pre-wrap', overflowY: 'auto', width: '100%', minWidth: 500 }}>{rawText ?? '-'}</div>}
                </Form.Item>


            </Form>
        </>
    );

}
export default PathologoReportComponent;