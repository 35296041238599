import { Observable, of, delay, Subscription, throwError } from "rxjs";
import axios, { AxiosError, AxiosResponse } from "axios";

class FhirService {

	public getPatientByAmka(amka: string) : Observable<any> {
		const obs$ = this.get(`/fhir/Patient/urn:patient-identifier:amka|${amka}`);
        return obs$;
	}

	public getAllPatientDataByAmka(amka: string) : Observable<any> {
		const obs$ = this.get(`/fhir/Patient/urn:patient-identifier:amka|${amka}/$everything`);
        return obs$;
	}

	public getEPrescriptionPatientByAmka(amka: string) : Observable<any> {
		const obs$ = this.get(`/fhir/Patient/urn:patient-identifier:amka|${amka}/$use-e-prescription`);
        return obs$;
	}

	public updatePatient(patientResource: object) : Observable<any> {
		const obs$ = this.put(`/fhir/Patient/`, patientResource);
        return obs$;
	}

	public addCondition(condition: object) : Observable<any> {
		const obs$ = this.post(`/fhir/Condition/`, condition);
        return obs$;
	}

    public validateCreateCondition(condition: object) : Observable<any> {
		const obs$ = this.post(`/fhir/Condition/$validate`, condition);
        return obs$;
	}

	public updateCondition(condition: object) : Observable<any> {
		const obs$ = this.put(`/fhir/Condition/`, condition);
        return obs$;
	}

    public validateUpdateCondition(condition: object) : Observable<any> {
		const obs$ = this.put(`/fhir/Condition/$validate`, condition);
        return obs$;
	}

	public addProcedure(procedure: object) : Observable<any> {
		const obs$ = this.post(`/fhir/Procedure/`, procedure);
        return obs$;
	}

	public updateProcedure(procedure: object) : Observable<any> {
		const obs$ = this.put(`/fhir/Procedure/`, procedure);
        return obs$;
	}

    public validateObservation(observation: object) : Observable<any> {
        const obs$ = this.post(`/fhir/Observation/$validate`, observation);
        return obs$;
    }

	public addObservation(observation: object) : Observable<any> {
		const obs$ = this.post(`/fhir/Observation/`, observation);
        return obs$;
	}

    public updateObservation(observation: object) : Observable<any> {
		const obs$ = this.put(`/fhir/Observation/`, observation);
        return obs$;
	}

	public addDiagnosticReport(diagnosticReport: object) : Observable<any> {
		const obs$ = this.post(`/fhir/DiagnosticReport/`, diagnosticReport);
        return obs$;
	}

	public updateDiagnosticReport(diagnosticReport: object) : Observable<any> {
		const obs$ = this.put(`/fhir/DiagnosticReport/`, diagnosticReport);
        return obs$;
	}

	public getPatientDistinctPerformers(patientId: string) : Observable<any> {
		const obs$ = this.get(`/fhir/Patient/${patientId}/$distinct-performers`);
        return obs$;
	}

    public getPatientHistoryRecords(patientId: string) : Observable<any> {
		const obs$ = this.get(`/fhir/Patient/${patientId}/$everything/_history`);
        return obs$;
	}


    public getValueSets() {
		const obs$ = this.get(`/fhir/ValueSet`);
        return obs$;
    }

	protected get(url: string) {
		const request = new BaseRequestModel(url, 'GET');
        return request.request();
	}

	protected put(url: string, data: any) {
		const request = new BaseRequestModel(url, 'PUT', data);
        return request.request();
	}

	protected post(url: string, data: any) {
		const request = new BaseRequestModel(url, 'POST', data);
        return request.request();
	}
}


class BaseRequestModel {
    constructor(private url: string, private method: string = 'GET', private body?: Body) {}

    prom$: Promise<any>|null = null;
    data: any|null = null;

    request(): Observable<any> {
        return new Observable(observer => {
            if(this.data) {
                observer.next(this.data);
                return;
            }

            if(this.prom$) {
                this.prom$.then(data => observer.next(this.data));
                return;
            }

            this.prom$ = axios.request({
                method: this.method,
                url: this.url,
                headers: {
                    'Content-Type': 'application/json',
                },
                data: this.body ? JSON.stringify(this.body) : undefined,
            });

            this.prom$
                .then(async (response: AxiosResponse) => {
                    return response.data;
                }, (error: AxiosError) => {
                    observer.error({
                        message: `There was a network error (Status:${error.status}, Body:${error.response?.data})!`,
                        statusCode: error.status,
                        responseBody: error.response?.data,
                    });
                })
                .then(data => {
                    this.data = data;
                    observer.next(data);
                    observer.complete();
                });
        });
    }
}

export default FhirService;
