import dayjs from "dayjs";
import TerminologyService from "../services/terminology-service";
import { BaseEditableModel } from "./base-editable-model";
import { Patient } from "./patient";
import { DATE_FORMAT } from "../constants";
import Icdo3Service from "../services/icdo3-service";

export class Diagnosis implements BaseEditableModel {
    id?:string;
    incidentType?:string;
    metastasisSiteCode?:string;
    icdO3DiagnosisCode?:string;
    icdO3GradeCode?:string;

    diagnosisDate?:Date;

    bodySiteLocationCode?:string;
    diagnosisMethodCode?:string;
    status?:string;
    dueToDiagnosisId?: string;

    
    public toString? = ():string => {
        const icdo3DiagnosisValueSetOptions = TerminologyService.getValueSetOptions('DiagnosisICDO3');
        const icdo3Service = new Icdo3Service(icdo3DiagnosisValueSetOptions);

        return [
            `Είδος: ${this.incidentType ? TerminologyService.getValueSetOption('IncidentType',this.incidentType)?.label : '-'}`, 
            `Ημερομηνία διάγνωσης: ${this.diagnosisDate ? dayjs(this.diagnosisDate).format(DATE_FORMAT) : '-'}`,
            this.icdO3DiagnosisCode ? `Κωδικός ICD-O-3: ${this.icdO3DiagnosisCode!}` : undefined,
            this.icdO3DiagnosisCode ? `Χαρακτηρισμός ICD-O-3: ${icdo3Service.getDiagnosisByCode(this.icdO3DiagnosisCode!)?.toDisplayText()}` : undefined,
            this.icdO3DiagnosisCode ? `ICD-O-3 Grade: ${TerminologyService.getValueSetOption('IcdO3Grade',this.icdO3GradeCode)?.label ?? '-'}`:undefined,
            !this.icdO3DiagnosisCode ? `Τοποθεσία μετάστασης: ${TerminologyService.getValueSetOption('MetastaticSite',this.metastasisSiteCode)?.label}` : undefined,
            this.bodySiteLocationCode ? `Θέση συμπαγούς όγκου: ${TerminologyService.getValueSetOption('BodySiteLocation',this.bodySiteLocationCode)?.label}`:undefined,
            `Μέθοδος διάγνωσης: ${this.diagnosisMethodCode ? TerminologyService.getValueSetOption('BasisOfDiagnosis',this.diagnosisMethodCode)?.label : '-'}`
        ].filter(s=>s).join("\r\n");
    }

    public static GroupDiagnoses(flatDiagnoses: Diagnosis[]): Diagnosis[][] {
        if (flatDiagnoses.length < 1) throw new Error("No diagnoses");
        const groupedDiagnoses: Diagnosis[][] = [];
        flatDiagnoses
            .filter(d => d.dueToDiagnosisId === undefined)
            .forEach(parentD => {
                const group : Diagnosis[] = [];
                group.push(parentD);
                groupedDiagnoses.push(group);
            });

        flatDiagnoses
            .filter(d => d.dueToDiagnosisId !== undefined)
            .forEach(childD => {
                const group = groupedDiagnoses.find(g => g[0].id === childD.dueToDiagnosisId)!;
                if(!group){
                    throw new Error(`No primary tumor found for secondary tumor with id ${childD.dueToDiagnosisId}`);
                }
                group.push(childD);
            });

        return groupedDiagnoses;
    }
}