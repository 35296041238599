import { Alert, Avatar, Form, FormProps, Input, Layout, List, Modal, Skeleton, Space, Statistic, StatisticProps, Typography, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AuthService, { LoginErrorCodes, user$ } from '../services/auth-service';
import { validateAMKAs } from '../helpers/amka-validator';
import axios, { AxiosResponse } from 'axios';
import { Organization } from '../models/organization';
import { User } from '../models/user';
import { orderBy } from 'lodash';


function OrganizationsPage() {
    let { orgId } = useParams();
    const authService = AuthService.getInstance();
    const [isFetchingOrg, setIsFetchingOrg] = useState<boolean>(true);
    const currentUser = AuthService.getInstance().getCurrentUser();
    const [organization, setOrganization] = useState<Organization|undefined>(undefined);
    const [users, setUsers] = useState<User[]|undefined>(undefined);
    const [userToAssignTo, setUserToAssignTo] = useState<User|undefined>(undefined);
    const [userToRevertFrom, setUserToRevertFrom] = useState<User|undefined>(undefined);
    const [amkaAssignmentsString, setAmkaAssignmentsString] = useState<string|undefined>(undefined);
    const [isAssignmentsUpdating, setIsAssignmentsUpdating] = useState<boolean>(false);

    useEffect(()=> {
        setIsFetchingOrg(true);
        axios.get(`/api/organizations/${orgId}`).then(result => {
            const organization = result.data.data as Organization;
            axios.get(`/api/organizations/${orgId}/users`).then(usersResult => {
                const users = usersResult.data.data as User[];
                setOrganization(organization);
                setUsers(users);
                setIsFetchingOrg(false);

            });
        });
        
    }, [orgId]);
    return (
        <>
            {!orgId ? <Alert type='error' description='Παρουσιάστηκε σφάλμα'></Alert> : 
            <>
                {isFetchingOrg && <Skeleton loading={true}></Skeleton>}
                {!isFetchingOrg &&<Layout className='ant-layout-page'>
                    {(organization && users) && <><Layout.Header className='hipaheader2 ant-layout-page-header'>
                        <Space className='personal_details_wrapper'>
                            <h1>{organization.name}</h1>
                            <Typography.Text type='secondary'>Γιατροί που έχουν ενταχθεί στο ΕΜΝΝ</Typography.Text>
                        </Space>
                        <div style={{flex:"1 auto"}}></div>
                    </Layout.Header>
                    <Layout.Content className='ant-layout-page-content'>
                        <List>
                            {orderBy(users, u=>u.name).map((u,i) => <List.Item key={i} actions={(!currentUser.is_trainee && u.is_trainee === true) ? [<a onClick={()=>setUserToAssignTo(u)} key="set">Ανάθεση πρόσβασης</a>, <a onClick={()=>setUserToRevertFrom(u)} key="revert">Ανάκληση πρόσβασης</a>]:[]}>
                                <List.Item.Meta
                                    avatar={<Avatar shape="square" size={50}>{i+1}</Avatar>}
                                    title={u.name + (u.is_trainee === true ? ' (Ειδικευόμενος/η)' : '')}
                                    description={u.idika_amka}
                                />
                            </List.Item>)}
                        </List>

                    </Layout.Content></>}
                </Layout>}

                <Modal 
                    destroyOnClose={true} 
                    open={userToRevertFrom !== undefined || userToAssignTo !== undefined} 
                    title={userToAssignTo !== undefined ?'Ανάθεση πρόσβασης ειδικευόμενου σε ασθενείς' : 'Ανάκληση πρόσβασης ειδικευόμενου από ασθενείς'} 
                    onCancel={()=>{
                        setUserToAssignTo(undefined); 
                        setUserToRevertFrom(undefined); 
                        setAmkaAssignmentsString(undefined);
                    }}
                    okText={userToAssignTo !== undefined ?'Ανάθεση' : 'Ανάκληση'}
                    cancelText='Άκυρο'
                    closable={false}
                    width={600}
                    okButtonProps={{loading:isAssignmentsUpdating, disabled:(!amkaAssignmentsString)}}
                    onOk={()=> {
                        setIsAssignmentsUpdating(true);
                        const dataToPost = userToAssignTo !== undefined ? {user_id:userToAssignTo.id, primary_user_id:authService.getCurrentUser().id, patient_identifers:amkaAssignmentsString?.split('\n')} : {user_id:userToRevertFrom!.id, patient_identifers:amkaAssignmentsString?.split('\n')};
                        axios.post(`/api/organizations/${orgId}/` + (userToAssignTo !== undefined ? 'trainee-patient-assignments' : 'trainee-patient-unassignments'), dataToPost ).then((response:AxiosResponse)=>{
                            let messageDescription = undefined;
                            if (userToAssignTo !== undefined) {
                                const existingAssignments = response.data.existing as string[];
                                const addedAssigments = response.data.added as string[];
                                if (addedAssigments.length === 0) messageDescription = "Δεν έγιναν νέες αναθέσεις."
                                else if (addedAssigments.length === 1) messageDescription = "Έγινε 1 νέα ανάθεση."
                                else messageDescription = `Έγιναν ${addedAssigments.length} νέες αναθέσεις.`;

                                if (existingAssignments.length === 1) messageDescription += ` ${existingAssignments.length} ανάθεση υπήρχε ήδη.`
                                else if (existingAssignments.length > 1) messageDescription += ` ${existingAssignments.length} αναθέσεις υπήρχαν ήδη.`
                            } else {
                                const deletedAssignments = response.data.deleted as string[];
                                const notFoundAssignments = response.data.not_found as string[];
                                if (deletedAssignments.length === 0) messageDescription = "Δεν έγιναν ανακλήσεις."
                                else if (deletedAssignments.length === 1) messageDescription = "Έγινε 1 ανάκληση."
                                else messageDescription = `Έγιναν ${deletedAssignments.length} ανακλήσεις.`;

                                if (notFoundAssignments.length === 1) messageDescription += ` ${notFoundAssignments.length} ανάκληση δεν έγινε γιατί δεν βρέθηκε η αντίστοιχη ανάθεση.`
                                if (notFoundAssignments.length > 1) messageDescription += ` ${notFoundAssignments.length} ανακλήσεις δεν έγιναν γιατί δεν βρέθηκαν οι αντίστοιχες αναθέσεις.`
                            }
                            notification.success({message:"Οι αναθέσεις ενημερώθηκαν επιτυχώς", description:messageDescription});
                            setIsAssignmentsUpdating(false);
                            setUserToAssignTo(undefined); 
                            setUserToRevertFrom(undefined); 
                            setAmkaAssignmentsString(undefined);
                        }).catch(()=>{
                            notification.error({message:"error"});
                            setIsAssignmentsUpdating(false);
                        });
                    }}
                    >
                        {(userToAssignTo || userToRevertFrom) && <Form
                            layout='vertical'
                            autoComplete="off"
                        >
                            <Form.Item 
                                label="Ειδικευόμενος/η"
                            >
                                {userToAssignTo ? `${userToAssignTo.name} (${userToAssignTo.idika_amka})`  :`${userToRevertFrom!.name} (${userToRevertFrom!.idika_amka})`}
                            </Form.Item>

                            <Form.Item 
                                label={userToAssignTo ? "Λίστα ΑΜΚΑ* ασθενών στους οποίους ο/η ειδικευόμενος/η θα αποκτήσουν πρόσβαση": "Λίστα ΑΜΚΑ ασθενών από τους οποίους ο/η ειδικευόμενος/η θα στερηθεί την πρόσβαση"}
                                help="*Ο χρήστης μπορεί να εισάγει πολλαπλά ΑΜΚΑ, ΠΑΜΚΑ, ΠΑΑΥΠΑ, ΠΑΥΠΕΚ, ένα ανά γραμμή."
                            >
                                <Input.TextArea maxLength={3000} disabled={isAssignmentsUpdating} value={amkaAssignmentsString} onChange={(e)=>setAmkaAssignmentsString(e.target.value)} rows={10}></Input.TextArea>
                            </Form.Item>
                        </Form>}
                </Modal>
            </>}
        </>
    );
}

export default OrganizationsPage;