import React, { useEffect, useState } from 'react';
import './App.css';
import { Button, Layout, Modal, Result, Spin, notification } from 'antd';
import { Route, Routes, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import IntroPage from './pages/intro-page';
import SearchByAmkaPage from './pages/search-by-amka-page';
import AddCitizenToRegistryPage from './pages/add-citizen-to-registry-page';
import PatientPage from './pages/patient-page';
import logo from './logo.png'
import greeceFooter from './Greece 2.0_footer.jpg';
import search_icon from './assets/search.png'
import file_upload_icon from './assets/file_upload.png'
import SelectActiveOrganizationPage from './pages/select-active-organization-page';
import './hipa_custom_styles.css'
import notif_icon from './assets/notif.png';
import setting_icon from './assets/settings.png';
import door_icon from './assets/door.png';
import { useObservable } from 'rxjs-hooks';
import AuthService, { org$, user$ } from './services/auth-service';
import { User } from './models/user';
import ProfilePage from './pages/profile-page';
import MessagesPage from './pages/messages-page';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { Organization } from './models/organization';
import ContactPage from './pages/contact-page';
import OrganizationsPage from './pages/organization-page';
import FileUploadPage from './pages/file-upload-page';
import AdministerSpecialtiesPage from './pages/administer-specialties-page';
import FieldMapperService from './services/field-mapper-service';
import TerminologyService from './services/terminology-service';
import FhirService from './services/fhir-service';
import ObjectOperationsService from './services/object-operations-service';
import GeneralErrorPage from './pages/general-error-page';
import { useServiceWorker } from './hooks/useServiceWorker';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

declare global {
  interface Window {
      Pusher: typeof Pusher; 
      Echo: Echo<'reverb'>; 
  }
}


function App() {
  const authService = AuthService.getInstance();
  const currentUser : User|null = useObservable(user$, null);
  const activeOrg : Organization|null = useObservable(org$, null);
  const [isAppAuthenticated, setIsAppAuthenticated] = useState(false);
  const [isGeneralError, setIsGeneralError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { waitingWorker, showReload, reloadPage } = useServiceWorker();
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams()
  let location = useLocation();

  const { info } = Modal;

  window.Pusher = Pusher;
  //window.Pusher.logToConsole=true;

  
  // decides when to show the toast
  useEffect(() => {
    if (showReload && waitingWorker) {
      info({
        title:'Νέα έκδοση',
        content:'Η εφαρμογή του ΕΜΝΝ θα ενημερωθεί στη νεότερη έκδοσή της.',
        onOk:()=>reloadPage()
      })
    }
  }, [waitingWorker, showReload, reloadPage]);

  useEffect(()=> {
    setIsLoading(true);
    axios.get('/api/oauth/auth-urls').then(response => {
        const data :any = response.data;
        authService.setAuthUrls(data.loginUrl, data.logoutUrl);
        if (location.pathname === "/auth-callback") {
          const authCode = searchParams.get("code");
          const sessionState = searchParams.get("session_state");
          const iss = searchParams.get("iss");
          if (!authCode) {
            authService.clearAuthInfoFromLocalStorage();
            window.location.href = '/';
            return;
          }
          authService.getAccessToken(authCode!, sessionState!, iss!).subscribe({
            next:(loginResponse) => {
              authService.initializeAxiosInterceptor();
              authService.getCurrentUserFromServer().subscribe({
                next:(userResponse) => {
                  if (userResponse.user.is_trainee && !userResponse.user.specialty) {
                    navigate("/specialties")
                  } else {
                    navigate("/active-organization");
                  }
                  setIsAppAuthenticated(true);
                  
                },
                error:(errorUserResponse)=>{
                  notification.error({message:errorUserResponse.message});
                  authService.clearAuthInfoFromLocalStorage();
                  setIsLoading(false);
                  navigate(`/general-error?error_code=${errorUserResponse.code}`)
                }
              });
            }, 
            error:(error:AxiosError) => {
              let errorCode = "auth";
              if (error.status === 403) {
                errorCode = "specialty";
              }
              notification.error({message:errorCode === 'auth' ? "Πρόβλημα κατά την αυθεντικοποίησή σας": "Δεν μπορείτε να χρησιμοποιήσετε τις υπηρεσίες του ΕΜΝΝ"});
              authService.clearAuthInfoFromLocalStorage();
              setIsLoading(false);
              navigate(`/general-error?error_code=${errorCode}`);
            }
          });
        } else if (authService.isValidAuthInfoInLocalStorage()) {
          if (authService.isAcessTokenNotExpired()) {
            authService.initializeAxiosInterceptor();
            authService.getCurrentUserFromServer().subscribe({
              next:(userResponse) => {

                if (userResponse.user.is_trainee && !userResponse.user.specialty) {
                  navigate("/specialties")
                } 
                setIsAppAuthenticated(true);
              },
              error:(errorUserResponse)=>{
                notification.error({message:errorUserResponse.message});
                authService.clearAuthInfoFromLocalStorage();
                setIsLoading(false);
                navigate(`/general-error?error_code=${errorUserResponse.code}`)
              }
            });
          } else {
            authService.clearAuthInfoFromLocalStorage();
            setIsLoading(false);
          }
        } else {
          authService.clearAuthInfoFromLocalStorage();
          setIsLoading(false);
        }
    }, (error) => {
      setIsGeneralError(true);
      setIsLoading(false);
    });
  },[]);


  useEffect(()=> {
    if (!isAppAuthenticated) return;
    setIsLoading(true);
    axios.get("/api/config").then((response:any)=> {
      const wsSettings = response.data.webSockets;
      window.Echo = new Echo({
        broadcaster:'reverb',
        key:wsSettings.key,
        wsHost: wsSettings.host, // just the host
        wsPort: wsSettings.port,
        wssPort: wsSettings.port,
        forceTLS: (wsSettings.scheme ?? 'https') === 'https',
        enabledTransports: ['ws', 'wss'],
        authEndpoint:`/broadcasting/auth`,
        auth: {
          headers: {
              Authorization: 'Bearer ' + authService.getBearerAccessTokenFromLocalStorage(),
          },
        },
      });
      FieldMapperService.setApiUrl(response.data.fhirApiUrl);
      TerminologyService.initialize(new FhirService(), new ObjectOperationsService()).subscribe({
        next:(value)=>{
          setIsLoading(false);
        },
        error:(error2)=> {
          setIsGeneralError(true);
          setIsLoading(false);
        }
      });
    }, error => {
      setIsLoading(false);
      setIsGeneralError(true);
    });

  }, [isAppAuthenticated])


  return (
      <Layout>
      <Layout.Header className="hipaheader">
        <div>
          <a onClick={()=>navigate('/')}>
            <img src={logo} alt="logo" height="130"/>
          </a>
        </div>
        <div style={{whiteSpace:'pre',lineHeight:'30px', fontSize:25, fontFamily:'auto',fontWeight:'bold', margin:'auto 10px'}}>
          {'ΕΘΝΙΚΟ\nΜΗΤΡΩΟ ΑΣΘΕΝΩΝ\nμε ΝΕΟΠΛΑΣΜΑΤΙΚΑ\nΝΟΣΗΜΑΤΑ'}
        </div>
        
        <div style={{flex:"1 auto"}}></div>
        {isAppAuthenticated && <div className='userstaff'>

          {currentUser && <div className='nameAndRole'>
            <div className='name'>
              {currentUser.name}
            </div>
            <div className='role'>
              {currentUser.specialty}
              {currentUser.is_trainee && <span> (Ειδικευόμενος)</span>}
            </div>
            <div className='org'>
              {activeOrg?.name ?? ''}
            </div>
          </div>}
          {/* <div className='notif_wrapper'>
            <img className='notification_icon' src={notif_icon} onClick={()=>navigate('/messages')}/>
            <div className='notification_number'>4</div>
          </div> */}
          <img className='search_icon' onClick={()=>navigate('/amka-search')} src={search_icon}/>
          {/* <img className='file_upload_icon' onClick={()=>navigate('/file-upload')} src={file_upload_icon}/> */}
          <img className='settings_icon' onClick={()=>navigate('/profile')} src={setting_icon} />
          <img className='exit_icon' onClick={()=>{
            authService.clearAuthInfoFromLocalStorage();
            window.location.href = authService.getLogoutUrl();
          }} src={door_icon} />
        </div>}
      </Layout.Header> 
      <Layout.Content className='hipacontent'>
        <div className="inner-content">
          {isGeneralError ? <Result status='error' title="Παρουσιάστηκε πρόβλημα κατά την είσοδό σας στην εφαρμογή." extra={
              <Button type="primary" onClick={()=>{
                setIsGeneralError(false);
                navigate("/");
              }}>Αρχική σελίδα</Button>
            }/> : <>
            {isLoading ? <div style={{display:'flex',alignItems: "center",justifyContent: "center",height:'100%'}}><Spin spinning={true}/></div> :
            <Routes>
              <Route path="/" element={<IntroPage/>}/>
              <Route path="/contact" element={<ContactPage/>}/>
              <Route path="/general-error" element={<GeneralErrorPage/>}/>
              {isAppAuthenticated ? <>
              <Route path="/profile" element={<ProfilePage/>}/>
              <Route path="/messages" element={<MessagesPage/>}/>
              <Route path="/active-organization" element={<SelectActiveOrganizationPage/>}/>
              <Route path="/specialties" element={<AdministerSpecialtiesPage/>}/>
              <Route path="/organizations/:orgId" element={<OrganizationsPage/>}/>
              <Route path="/amka-search" element={!currentUser?.specialty ? <AdministerSpecialtiesPage/>:  activeOrg?<SearchByAmkaPage/>:<SelectActiveOrganizationPage/>}/>
              <Route path="/file-upload" element={!currentUser?.specialty ? <AdministerSpecialtiesPage/>:activeOrg?<FileUploadPage/>:<SelectActiveOrganizationPage/>}/>
              <Route path="/citizens/:amka" element={!currentUser?.specialty ? <AdministerSpecialtiesPage/>:activeOrg?<AddCitizenToRegistryPage/>:<SelectActiveOrganizationPage/>}/>
              <Route path="/patients/:amka" element={!currentUser?.specialty ? <AdministerSpecialtiesPage/>:activeOrg?<PatientPage/>:<SelectActiveOrganizationPage/>}/>
              </>: <>
                <Route path="*" element={<IntroPage/>} />
              </>}
          </Routes>
            }
          
          </>}
          
          
        </div>
      </Layout.Content>
      <Layout.Footer className='hipafooter'>
        <div style={{margin:'auto 0'}}>ΗΔΙΚΑ Α.Ε.</div>
        <div><img src={greeceFooter}/></div>
      </Layout.Footer>
    </Layout>
    
  );
}

export default App;
