import TerminologyService from "../services/terminology-service";
import { BaseEditableModel } from "./base-editable-model";

export class Progress implements BaseEditableModel {
    readonly id: string | null;
    readonly conditionId: string;

    statusType?: string;
    progressDate?: Date;

    constructor(id: string | null, conditionId: string) {
        this.id = id;
        this.conditionId = conditionId;
    }

    public toString? = ():string => `Κατάσταση νόσου: ${this.statusType ? TerminologyService.getValueSetOption('DiseaseState',this.statusType)?.label : '-'}`;
}
