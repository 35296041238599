import TerminologyService from "../services/terminology-service";
import { BaseEditableModel } from "./base-editable-model";

export class PersonalInfo implements BaseEditableModel {
    district?:string;
    city?:string;
    address?:string;
    phone1?:string;
    phone2?:string;
    email1?:string;
    email2?:string;

    weight?:number;
    weightDate?:Date;
    height?:number;
    heightDate?:Date;
    bmi?:number;
    bmiDate?:Date;

    smokingStatus?:string;
    smokingStartYear?:number;
    smokingEndYear?:number;
    packYears?:number;
    diabetis?:string;

    public demographicsToString? = ():string=> [
        `Περιοχή: ${this.district ? TerminologyService.getValueSetOption('NUTS2',this.district)?.label : '-'}`, 
        `Πόλη: ${this.city ?? '-'}`, 
        `Διεύθυνση: ${this.address ?? '-'}`, 
        `Τηλέφωνο 1: ${this.phone1 ?? '-'}`,
        `Τηλέφωνο 2: ${this.phone2 ?? '-'}`,
        `Email 1: ${this.email1 ?? '-'}`,
        `Email 2: ${this.email2 ?? '-'}`,
    ].join("\r\n");
    
}