import { Avatar, Button, Checkbox, Input, Layout, List, Pagination, Popover, Space, Tag, Typography } from "antd";
import { Message } from "../models/message";
import { ReactNode, useEffect, useState } from "react";
import dayjs from 'dayjs';
import { DATE_FORMAT, DATE_TIME_FORMAT } from "../constants";
import { useNavigate } from 'react-router-dom';
import { FilterOutlined } from "@ant-design/icons";
import AuthService from "../services/auth-service";

interface MessagesFilter {
    patientAmka?:string;
    messageTypes?:string[];
}

function MessagesPage() {
    const navigate = useNavigate();
    const authService = AuthService.getInstance();
    const [isFilterPopoverOpen, setIsFilterPopoverOpen] = useState<boolean>(false);
    const [appliedFilter, setAppliedFilter] = useState<MessagesFilter|undefined>(undefined);
    const [selectedFilter, setSelectedFilter] = useState<MessagesFilter>({} as MessagesFilter);

    const availableMessageTypes = [
        {value:'error', label:<Tag color='error'>Σφάλμα υποβολής</Tag>},
        {value:'warning', label:<Tag color='warning'>Προειδοποίηση για υποβολή</Tag>},
        {value:'info', label:<Tag color='blue'>Ενημέρωση</Tag>}
    ];

    useEffect(()=>{
        if (!authService.getActiveOrganization()) {
            navigate('/active-organization');
            return;
        }
    },[]);

    const getMessageTitle = (message:Message) : ReactNode =>{
        return <div style={{display:'flex', flexDirection:'row'}}>
            <div style={{flex:1}}>
                {message.patient ? <span>Μήνυμα για ασθενή με όνομα {message.patient.lastName} {message.patient.firstName} και ΑΜΚΑ {message.patient.amka}</span>: 'Ενημέρωση'}
                <Typography.Text type="secondary"> {dayjs(message.date).format(DATE_TIME_FORMAT)}</Typography.Text>
                
            </div>
            <div>
            <Tag color={message.type === 'error' ?"error" : message.type === 'warning' ? 'warning' : "blue"}>{message.type === 'error' ? "Σφάλμα υποβολής" : message.type==='warning' ? "Προειδοποίηση για υποβολή" : "Ενημέρωση"}</Tag>
            </div>
        </div>
    }

    return (
        <>
            <Layout className='ant-layout-page'>
                <Layout.Header className='hipaheader2 ant-layout-page-header'>
                    <h1>Μηνύματα για τον χρήστη</h1>
                    <div style={{flex:"1 auto"}}></div>
                    <div style={{alignContent:'center'}}>
                        <Popover open={isFilterPopoverOpen} content={<>
                            <h4 style={{marginBottom:2}}>ΑΜΚΑ ασθενούς</h4>
                            <div><Input placeholder="Συμπληρώστε ΑΜΚΑ ασθενούς" value={selectedFilter.patientAmka} onChange={(e)=>setSelectedFilter({...selectedFilter, patientAmka:e.target.value})}/></div>
                            <h4 style={{marginBottom:2}}>Είδος</h4>
                            <div>
                                <Checkbox.Group options={availableMessageTypes} value={selectedFilter.messageTypes} onChange={(checkedValues)=>setSelectedFilter({...selectedFilter, messageTypes:checkedValues})}></Checkbox.Group>
                            </div>
                            <div style={{marginTop:20,textAlign:'right'}}>
                                <Space>
                                    <Button onClick={()=>{
                                        setSelectedFilter({} as MessagesFilter);
                                        setAppliedFilter(undefined);
                                        setIsFilterPopoverOpen(false);
                                    }}>Καθαρισμός</Button>
                                    <Button type='primary' onClick={()=> {
                                        if (!selectedFilter.patientAmka && (!selectedFilter.messageTypes || !selectedFilter.messageTypes.length)) setAppliedFilter(undefined);
                                        else setAppliedFilter({patientAmka:selectedFilter.patientAmka, messageTypes:selectedFilter.messageTypes ? [...selectedFilter.messageTypes]: undefined});
                                        setIsFilterPopoverOpen(false);
                                    }}>Εφαρμογή</Button>
                                </Space>
                            </div>
                        
                        </>} trigger="click" placement="left">
                            <Button icon={<FilterOutlined />} style={{color:appliedFilter?'red':'unset'}} onClick={()=>setIsFilterPopoverOpen(!isFilterPopoverOpen)}>Φίλτρα</Button>
                        </Popover>
                    </div>
                </Layout.Header>
                <Layout.Content className='ant-layout-page-content'>
                    <List>
                        {(!appliedFilter || ((!appliedFilter.patientAmka || appliedFilter.patientAmka==='13051300807') && (!appliedFilter.messageTypes || !appliedFilter.messageTypes.length || appliedFilter.messageTypes?.includes('error')))) && <List.Item key={0} actions={[
                            <Button onClick={()=>navigate(`/patients/${'13051300807'}`)} >Καρτέλα ασθενούς</Button>
                        ]}>
                            <List.Item.Meta
                                avatar={<Avatar shape="square" size={50}>1</Avatar>}
                                title={getMessageTitle({ type:'error', id:"2", date:dayjs().toDate(), patient:{ amka: '13051300807', firstName: 'Λασκαρίνα', lastName:'Μπουμπουλίνα'}, sender:"system", content:"dokimi" } as Message)}
                                description='Κατά την υποβολή της διάγνωσης: C501.4443/3 Breast cancer XXX προέκυψε το παρακάτω πρόβλημα. Υπάρχει ήδη η ίδια πρωτοπαθής νεοπλασία. Η υποβολή 33221-1111 αγνοήθηκε.'
                            />
                        </List.Item>}
                        {(!appliedFilter || ((!appliedFilter.patientAmka || appliedFilter.patientAmka==='13051300807') && (!appliedFilter.messageTypes || !appliedFilter.messageTypes.length || appliedFilter.messageTypes?.includes('warning')))) && <List.Item key={1} actions={[
                            <Button onClick={()=>navigate(`/patients/${'13051300807'}`)} >Καρτέλα ασθενούς</Button>
                        ]}>
                            <List.Item.Meta
                                avatar={<Avatar shape="square" size={50}>2</Avatar>}
                                title={getMessageTitle({ type:'warning', id:"3", date:dayjs().toDate(), patient:{ amka: '13051300807', firstName: 'Λασκαρίνα', lastName:'Μπουμπουλίνα'}, sender:"system", content:"dokimi" } as Message)}
                                description='Κατά την υποβολή της διάγνωσης: C501.4443/3 Breast cancer XXX προέκυψε η παρακάτω προειδοποίηση. Η συγκεκριμένη διάγνωση σε γυναίκες ηλικίας κάτω των 20 ετών θεωρείται εξαιρετικά σπάνια. Απαιτείται επανυποβολή με επιβεβαίωση.'
                            />
                        </List.Item>}
                        {(!appliedFilter || ((!appliedFilter.patientAmka || appliedFilter.patientAmka==='13051300807') && (!appliedFilter.messageTypes || !appliedFilter.messageTypes.length || appliedFilter.messageTypes?.includes('error')))) && <List.Item key={2} actions={[
                            <Button onClick={()=>navigate(`/patients/${'13051300807'}`)} >Καρτέλα ασθενούς</Button>
                        ]}>
                            <List.Item.Meta
                                avatar={<Avatar shape="square" size={50}>3</Avatar>}
                                title={getMessageTitle({ type:'error', id:"4", date:dayjs().toDate(), patient:{ amka: '13051300807', firstName: 'Λασκαρίνα', lastName:'Μπουμπουλίνα'}, sender:"system", content:"dokimi" } as Message)}
                                description='Κατά την υποβολή της διάγνωσης: C501.4443/3 Breast cancer XXX προέκυψε μη αναμενόμενο σφάλμα. Η υποβολή 44321-1111 αγνοήθηκε.'
                            />
                        </List.Item>}
                        {(!appliedFilter || (!appliedFilter.patientAmka && (!appliedFilter.messageTypes || !appliedFilter.messageTypes.length || appliedFilter.messageTypes?.includes('info')))) && <List.Item key={3} actions={[
                            <div style={{width:170}}></div>
                        ]}>
                            <List.Item.Meta
                                avatar={<Avatar shape="square" size={50}>4</Avatar>}
                                title={getMessageTitle({ type:'info', id:"5", date:dayjs().toDate(), sender:"system", content:"dokimi" } as Message)}
                                description='Το σύστημα θα παραμείνει εκτός λειτουργίας από την 12/05/2025 11:00 μέχρι την 13/05/2025 10:00 για λόγους συντήρησης.'
                            />
                        </List.Item>}
                        {(!appliedFilter || (!appliedFilter.patientAmka && (!appliedFilter.messageTypes || !appliedFilter.messageTypes.length || appliedFilter.messageTypes?.includes('info')))) && <List.Item key={4} actions={[
                            <div style={{width:170}}></div>
                        ]}>
                            <List.Item.Meta
                                avatar={<Avatar shape="square" size={50}>5</Avatar>}
                                title={getMessageTitle({ type:'info', id:"6", date:dayjs().toDate(), sender:"system", content:"dokimi" } as Message)}
                                description={<span>Σας ενημερώνουμε ότι η νέα έκδοση του εγχειρδίου χρήσης είναι διαθέσιμη <a>εδώ</a>.</span>}
                            />
                        </List.Item>}
                    </List>
                </Layout.Content>
                <Layout.Footer>
                </Layout.Footer>
            </Layout>
        </>
    );
}

export default MessagesPage;