import { Alert, Avatar, Button, Empty, Input, List, Modal, Spin, Table, Tag, notification } from "antd";
import { MutableRefObject, useEffect, useRef, useState } from "react";
import FhirService from "../services/fhir-service";
import { of } from "rxjs";
import { Doctor } from "../models/doctor";
import FieldMapperService from "../services/field-mapper-service";
import dayjs, { Dayjs } from "dayjs";
import { orderBy, uniqBy } from "lodash";
import { BaseEditableModel } from "../models/base-editable-model";
import { DATE_FORMAT, DATE_TIME_FORMAT } from "../constants";
import { Diagnosis } from "../models/diagnosis";
import TerminologyService from "../services/terminology-service";
import { normalizeString } from "../helpers/string-helpers";

interface FhirResourceHistoryEntry {
    fullUrl:string;
    resource:{resourceType:string, id:string, focus:[{reference:string}], reason:[{reference:{reference:string}}], meta:{lastUpdated:Date, source:string, tag:[{code:string}]}, performer:[{code:string|number,display:string, type:string,}]};
    search:{mode:string};
}

interface BaseHistoryEntry {
    key:string;
    id:string;
    date:dayjs.Dayjs,
    compositeFriendlyType:string;
    friendlyType:string;
    user:string;
    organization:string;
    humanFriendlyContent:string;
    status:string;
}

export const PatientSubmissionsComponent: React.FC<{patientId: string|undefined|null}> = ({patientId}) => {
    const allHistoryEntries : MutableRefObject<BaseHistoryEntry[]> = useRef([]);

    const fhirService = new FhirService();
    const fieldMapperService = new FieldMapperService();
    const [filteredHistoryEntries, setFilteredHistoryEntries] = useState([] as BaseHistoryEntry[]);
    const [isLoading, setIsLoading] = useState(false);

    const [historyRecordToShow, setHistoryRecordToShow] = useState<BaseHistoryEntry|undefined>(undefined);
    
    useEffect(() => {
        setIsLoading(true);
        const history$ = patientId
            ? fhirService.getPatientHistoryRecords(patientId!)
            : of([]);
            
        history$.subscribe({
            next(value) {
                setIsLoading(false);
                const diagnoses = (value.entry as FhirResourceHistoryEntry[]).filter(fr=>fr.resource.resourceType==="Condition").map((diagnosisObj) => fieldMapperService.mapDiagnosisFromFhir(diagnosisObj.resource));
                const entries = (value.entry as FhirResourceHistoryEntry[]).map((frhe,index) => {
                    let compositeFriendlyType = "";
                    let humanFriendlyContent = "";
                    let forDiagnosisText = undefined;
                    if (frhe.resource.focus && frhe.resource.focus.length && frhe.resource.focus[0].reference) {
                        const conditionId = frhe.resource.focus[0].reference.split('/')[1];
                        
                        const condition = fieldMapperService.mapDiagnosisFromFhir((value.entry as FhirResourceHistoryEntry[]).find(fr=>fr.resource.resourceType==="Condition" && fr.resource.id===conditionId)!.resource);
                        if (condition) forDiagnosisText = `(αφορά τη διάγνωση ${condition.icdO3DiagnosisCode})`;
                    } else if (frhe.resource.reason && frhe.resource.reason.length && frhe.resource.reason[0].reference && frhe.resource.reason[0].reference.reference) {
                        const conditionId = frhe.resource.reason[0].reference.reference.split('/')[1];
                        
                        const condition = fieldMapperService.mapDiagnosisFromFhir((value.entry as FhirResourceHistoryEntry[]).find(fr=>fr.resource.resourceType==="Condition" && fr.resource.id===conditionId)!.resource);
                        if (condition) forDiagnosisText = `(αφορά τη διάγνωση ${condition.icdO3DiagnosisCode})`;
                    }
                    if (frhe.resource.resourceType === "Patient") {
                        if (frhe.resource.meta && frhe.resource.meta.tag && frhe.resource.meta.tag.length && frhe.resource.meta.tag.find(t=>t.code === "personal-info")) {
                            compositeFriendlyType = "Προσωπικά στοιχεία";
                            const model = fieldMapperService.mapPersonalInfoFromFhir(frhe.resource);
                            humanFriendlyContent = model.demographicsToString!();

                        } else if (frhe.resource.meta && frhe.resource.meta.tag && frhe.resource.meta.tag.length && frhe.resource.meta.tag.find(t=>t.code === "attendant")) {
                            compositeFriendlyType = "Στοιχεία συνοδού";
                            const model = fieldMapperService.mapAttendantFromFhir(frhe.resource);
                            humanFriendlyContent = model.infoToString!();
                        } else {
                            compositeFriendlyType = "Προσωπικά στοιχεία / στοιχεία συνοδού";
                            const model = fieldMapperService.mapPatientFromFhir(frhe.resource);
                            humanFriendlyContent = [model.mainInfoToString(), model.personalInfo?.demographicsToString!()].join("\r\n");
                        }
                    }
                    else if (frhe.resource.resourceType === "Condition") {
                        const model = fieldMapperService.mapDiagnosisFromFhir(frhe.resource);
                        compositeFriendlyType = "Διάγνωση"
                        humanFriendlyContent = model.toString!();
                    } else if (frhe.resource.resourceType === "Observation") {
                        const model = fieldMapperService.mapObservationFromFhir(frhe.resource);
                        humanFriendlyContent = model.toString!();
                        compositeFriendlyType = "Παρατήρηση";
                        if (model.code === "SmokingStatus" || model.code === "Weight" || model.code === "BMI" || model.code === "Height" || model.code === "SmokingPackYears") {
                            compositeFriendlyType = "Προσωπικά στοιχεία";
                        }  else if (model.code === "pNeoplasmAjccStaging" || model.code === "cNeoplasmAjccStaging") {
                            compositeFriendlyType = `Σταδιοποίηση ${model.code === "pNeoplasmAjccStaging" ? '(Pathological)':'(Clinical)'}`;
                            const stagingObservation = fieldMapperService.mapStagingObservations([frhe.resource])[0];
                            humanFriendlyContent = stagingObservation.toDisplayText();
                        } else if (model.code!.startsWith("Biomarker_")) {
                            compositeFriendlyType = "Βιοδείκτες";
                            const biomarker = fieldMapperService.mapBiomarkers([frhe.resource], diagnoses)[0].biomarkers[0];
                            const valueSetOptions = TerminologyService.getValueSetOption("Biomarker", biomarker.biomarkerCode!);
                            humanFriendlyContent = `Ημερ. καταγραφής: ${model.issuedDate ? dayjs(model.issuedDate).format(DATE_FORMAT) : '-'}` + "\r\n" + `${valueSetOptions?.label}: ${valueSetOptions?.properties?.inputType==='select' ?  TerminologyService.getValueSetOption(valueSetOptions.properties.valueSet, biomarker.biomarkerValue as string)?.label : biomarker.biomarkerValue}`;
                        } else if (model.code === "DiseaseState") {
                            compositeFriendlyType = "Καταγραφή Κατάστασης Νόσου";
                            const progress = fieldMapperService.mapProgresses([frhe.resource])[0];
                            humanFriendlyContent = progress.toString!();
                        } else {
                            compositeFriendlyType = "Πρόσθετα Παθολογοανατομικά/Εργαστηριακά Δεδομένα";
                            
                        }
                    } else if (frhe.resource.resourceType === "Procedure") {
                        const procedure = fieldMapperService.mapProcedures([frhe.resource])[0];
                        humanFriendlyContent = procedure.toString!();
                        compositeFriendlyType = "Θεραπεία";
                    } else if (frhe.resource.resourceType === "DiagnosticReport") {
                        compositeFriendlyType = "Πόρισμα";
                        const report = fieldMapperService.mapDiagnosticReport([frhe.resource])[0];
                        humanFriendlyContent = report.toString!();
                    }
                    const friendlyType = compositeFriendlyType;
                    if (forDiagnosisText) compositeFriendlyType += ` ${forDiagnosisText}`;
                    return {
                        id : frhe.resource.id, 
                        key:index.toString(), 
                        date:dayjs(frhe.resource.meta.lastUpdated), 
                        friendlyType,
                        compositeFriendlyType, 
                        humanFriendlyContent,
                        user : frhe.resource.performer.find(p=>p.type === "Practitioner")?.display,
                        organization : frhe.resource.performer.find(p=>p.type === "Organization")?.display
                     } as BaseHistoryEntry
                });
                allHistoryEntries.current = entries;
                setFilteredHistoryEntries(entries);
            },
            error(err) {
                setIsLoading(false);
            },
        });
    }, [patientId]);


    return (
        <Spin spinning={isLoading}>
            <div style={{display:'flex',flexDirection:'row', width:'100%'}}>
                <h3 style={{flex:1}}>Ιστορικό υποβολών</h3>
                <div style={{width:300}}>
                    <Input.Search placeholder="Αναζήτηση στις υποβολές" allowClear onChange={(e)=> {
                        if (!e.target.value) setFilteredHistoryEntries(allHistoryEntries.current);
                    }} onSearch={(searchValue)=> {
                        setFilteredHistoryEntries(allHistoryEntries.current.filter(he => normalizeString(JSON.stringify(he)).toLowerCase().includes(normalizeString(searchValue).toLowerCase())))
                    }} onClear={()=>setFilteredHistoryEntries(allHistoryEntries.current)} />
                </div>
            </div>
            
            {!isLoading && !patientId
            ? <Empty />
            : <><Table pagination={false} onRow={(record, rowIndex) => {
                return {
                    onClick: (event) => {setHistoryRecordToShow(record)}, // click row
                };
            }} columns={[
                {
                    title:'Ημερομηνία',
                    dataIndex:'date',
                    key:'date',
                    showSorterTooltip: { target: 'full-header' },
                    sorter: (a, b) => a.date.isBefore(b.date) ? -1:1,
                    defaultSortOrder: 'ascend',
                    sortDirections:['ascend','descend'],
                    render: (value, record, index)=> {
                        return (value as Dayjs).format(DATE_TIME_FORMAT)
                    },
                },
                {
                    title:'Είδος',
                    dataIndex:'compositeFriendlyType',
                    key:'compositeFriendlyType',
                    filters: !allHistoryEntries ? [] : uniqBy(allHistoryEntries.current, entry=>entry.friendlyType).map(h => { return {text:h.friendlyType, value:h.friendlyType};}),
                    onFilter: (value, record) => record.friendlyType.indexOf(value as string) === 0,
                },
                {
                    title:'Χρήστης',
                    dataIndex:'user',
                    key:'user',
                    filters: !allHistoryEntries ? [] : uniqBy(allHistoryEntries.current, entry=>entry.user).map(h => { return {text:h.user, value:h.user};}),
                    onFilter: (value, record) => record.user.indexOf(value as string) === 0,
                },
                {
                    title:'Μονάδα υγείας',
                    dataIndex:'organization',
                    key:'organization',
                    filters: !allHistoryEntries ? [] : uniqBy(allHistoryEntries.current, entry=>entry.organization).map(h => { return {text:h.organization, value:h.organization};}),
                    onFilter: (value, record) => record.organization.indexOf(value as string) === 0,
                },
                {
                    title:'Υποβολή',
                    dataIndex:'humanFriendlyContent',
                    key:'humanFriendlyContent',
                    render:(value, record, index)=>{
                        return <div style={{whiteSpace:'pre-line'}}>{value}</div>
                    }
                }
            ]} dataSource={orderBy(filteredHistoryEntries, hr=>hr.date)}/>

            <Modal width={800} open={historyRecordToShow !== undefined} 
                title={historyRecordToShow && <div style={{display:'flex', flexDirection:'row'}}>
                    <div style={{flex:1}}>{historyRecordToShow.compositeFriendlyType}</div>
                </div>} 
                onCancel={()=>setHistoryRecordToShow(undefined)}
                closable={false}
                footer={
                    [
                        <Button type='default' onClick={()=>setHistoryRecordToShow(undefined)}>Κλείσιμο</Button>
                    ]
                }
            >
                {historyRecordToShow && <>
                    <h3>{dayjs(historyRecordToShow.date).format(DATE_TIME_FORMAT)}</h3>
                    <div style={{marginBottom:10}}>Υποβλήθηκε από: {historyRecordToShow.user} {historyRecordToShow.organization}</div>
                    <div style={{whiteSpace:'pre-line',maxHeight:200,overflowY:'auto'}}>{historyRecordToShow.humanFriendlyContent}</div>
                </>}
                
            </Modal></>
            }
        </Spin>
    )
}
