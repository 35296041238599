import dayjs from "dayjs";
import { BaseFhirModel } from "../models/fhir/base-fhir-model";
import { Observation } from "../models/fhir/observation";
import { DATE_FORMAT } from "../constants";
import { Patient } from "../models/fhir/patient";
import { HistologicalObservation } from "../models/fhir/histological-observation";
import { Diagnosis } from "../models/fhir/diagnosis";
import { BiomarkerObservation } from "../models/fhir/biomarker-observation";
import { Staging } from "../models/fhir/staging";
import TerminologyService from "../services/terminology-service";
import Icdo3Service from "../services/icdo3-service";
import { NeoplasmType } from "../enums/neoplasm-type-enum";
import MetastaticSiteService from "../services/metastatic-site-service";
import { DiagnosticReport } from "../models/fhir/diagnostic-report";
import { Therapy } from "../models/fhir/therapy";

function generateFhirMessageSubmissionTitle(fhirMessage: BaseFhirModel) : string {
    let result: string = (fhirMessage.friendlyResourceName as string) + ": ";
    if (fhirMessage.friendlyResourceName === FhirResourceFriendlyName.patientDemographicsAndContact) {
    }
    if (fhirMessage.friendlyResourceName === FhirResourceFriendlyName.patientAttendant) {
    }
    if (fhirMessage.friendlyResourceName === FhirResourceFriendlyName.weight) {
        const weightMessage = fhirMessage as Observation;
        result +=  `${getFieldValueOrDash(weightMessage.value, 'χωρίς τιμή')} kg`
    }
    if (fhirMessage.friendlyResourceName === FhirResourceFriendlyName.height) {
        const heightMessage = fhirMessage as Observation;
        result += `${getFieldValueOrDash(heightMessage.value, 'χωρίς τιμή')} cm`
    }
    if (fhirMessage.friendlyResourceName === FhirResourceFriendlyName.bmi) {
        const bmiMessage = fhirMessage as Observation;
        result += `${getFieldValueOrDash(bmiMessage.value, 'χωρίς τιμή')} kg/m2`
    }
    if (fhirMessage.friendlyResourceName === FhirResourceFriendlyName.smokingStatus) {
        const smokingStatusMessage = fhirMessage as Observation;
        result += getFieldValueOrDash(smokingStatusMessage.friendlyValue, 'χωρίς δήλωση');
    }
    if (fhirMessage.friendlyResourceName === FhirResourceFriendlyName.packYears) {
        const packYearsMessage = fhirMessage as Observation;
        result += `${getFieldValueOrDash(packYearsMessage.value, 'χωρίς τιμή')} p/y`;
    }
    if (fhirMessage.fhirResourceName === FhirResourceName.diagnosis) {
        const diagnosisMessage = fhirMessage as Diagnosis;
        result += diagnosisMessage.incidentType === 'IT.001' ? 'Πρωτοπαθές νεόπλασμα' : diagnosisMessage.incidentType === 'IT.004' ? 'Μετασχηματισμός νεοπλάσματος' : 'Μεταστατικό νεόπλασμα';
    }
    if (fhirMessage.friendlyResourceName === FhirResourceFriendlyName.histological) {
        const histologicalMessage = fhirMessage as HistologicalObservation;
        result = histologicalMessage.fieldName + ": " + (histologicalMessage.friendlyValue ?? "-");
    }
    if (fhirMessage.friendlyResourceName === FhirResourceFriendlyName.biomarker) {
        const biomarkerMessage = fhirMessage as BiomarkerObservation;
        result += biomarkerMessage.fieldName;
    }
    if (fhirMessage.friendlyResourceName === FhirResourceFriendlyName.progress) {
        const progressMessage = fhirMessage as Observation;
        result += dayjs(progressMessage.effectiveStartDate).format(DATE_FORMAT);
    }
    if (fhirMessage.friendlyResourceName === FhirResourceFriendlyName.pathologoReport) {
        const progressMessage = fhirMessage as DiagnosticReport;
        result += dayjs(progressMessage.issued).format(DATE_FORMAT) + ' ' + (progressMessage.performer ?? '');
    }
    if (fhirMessage.fhirResourceName === FhirResourceName.therapy) {
        const therapyMessage = fhirMessage as Therapy;
        result += TerminologyService.getValueSetOption('TreatmentTypeForNeoplasm', therapyMessage.therapyKind)?.label ?? "-";
    }
    return result;
}

function generateFhirMessageSubmissionDescription(fhirMessage: BaseFhirModel) : string {
    let result: string = '';
    if (fhirMessage.friendlyResourceName === FhirResourceFriendlyName.patientDemographicsAndContact) {
        const patientMessage = fhirMessage as Patient;
        result += 'Περιοχή: ' + getFieldValueOrDash(patientMessage.friendlyDistrict) + '\n';
        result += 'Πόλη: ' + getFieldValueOrDash(patientMessage.city) + '\n';
        result += 'Διεύθυνση: ' + getFieldValueOrDash(patientMessage.address) + '\n';
        result += 'Τηλέφωνο 1: ' + getFieldValueOrDash(patientMessage.phone1) + '\n';
        result += 'Τηλέφωνο 2: ' + getFieldValueOrDash(patientMessage.phone2) + '\n';
        result += 'Email 1: ' + getFieldValueOrDash(patientMessage.email1) + '\n';
        result += 'Email 2: ' + getFieldValueOrDash(patientMessage.email2) + '\n';
    }
    if (fhirMessage.friendlyResourceName === FhirResourceFriendlyName.patientAttendant) {
        const patientMessage = fhirMessage as Patient;
        result += 'Όνομα: ' + getFieldValueOrDash(patientMessage.attendantFirstName) + '\n';
        result += 'Επώνυμο: ' + getFieldValueOrDash(patientMessage.attendantLastName) + '\n';
        result += 'Διεύθυνση: ' + getFieldValueOrDash(patientMessage.attendantAddress) + '\n';
        result += 'Τηλέφωνο 1: ' + getFieldValueOrDash(patientMessage.attendantPhone1) + '\n';
        result += 'Τηλέφωνο 2: ' + getFieldValueOrDash(patientMessage.attendantPhone2) + '\n';
        result += 'Email 1: ' + getFieldValueOrDash(patientMessage.attendantEmail1) + '\n';
        result += 'Email 2: ' + getFieldValueOrDash(patientMessage.attendantEmail2) + '\n';
    }
    if (fhirMessage.friendlyResourceName === FhirResourceFriendlyName.weight) {
        const weightMessage = fhirMessage as Observation;
        result += !weightMessage.effectiveStartDate ? 'χωρίς ημερομηνία καταγραφής βάρους' : `ημερομηνία καταγραφής βάρους ${dayjs(weightMessage.effectiveStartDate!).format(DATE_FORMAT)}`;
    }
    if (fhirMessage.friendlyResourceName === FhirResourceFriendlyName.height) {
        const heightMessage = fhirMessage as Observation;
        result += !heightMessage.effectiveStartDate ? 'χωρίς ημερομηνία καταγραφής ύψους' : `ημερομηνία καταγραφής ύψους ${dayjs(heightMessage.effectiveStartDate!).format(DATE_FORMAT)}`;
    }
    if (fhirMessage.friendlyResourceName === FhirResourceFriendlyName.bmi) {
        const bmiMessage = fhirMessage as Observation;
        result += !bmiMessage.effectiveStartDate ? 'χωρίς ημερομηνία καταγραφής BMI' : `ημερομηνία καταγραφής BMI ${dayjs(bmiMessage.effectiveStartDate!).format(DATE_FORMAT)}`;
    }
    if (fhirMessage.friendlyResourceName === FhirResourceFriendlyName.smokingStatus) {
        const smokingStatusMessage = fhirMessage as Observation;
        if (smokingStatusMessage.value && !smokingStatusMessage.effectiveStartDate && !smokingStatusMessage.effectiveEndDate) result += 'χωρίς έτος έναρξης και διακοπής';
        else if (smokingStatusMessage.value && !smokingStatusMessage.effectiveStartDate && smokingStatusMessage.effectiveEndDate) result += `έτος διακοπής καπνίσματος ${dayjs(smokingStatusMessage.effectiveEndDate!).format('YYYY')}`;
        else if (smokingStatusMessage.value && smokingStatusMessage.effectiveStartDate && !smokingStatusMessage.effectiveEndDate) result += `έτος έναρξης καπνίσματος ${dayjs(smokingStatusMessage.effectiveStartDate!).format('YYYY')}`;
        else if (smokingStatusMessage.value) result += `έτος έναρξης καπνίσματος ${dayjs(smokingStatusMessage.effectiveStartDate!).format('YYYY')} και έτος διακοπής καπνίσματος ${dayjs(smokingStatusMessage.effectiveEndDate!).format('YYYY')}`;
    }
    if (fhirMessage.fhirResourceName === FhirResourceName.diagnosis) {
        const diagnosisMessage = fhirMessage as Diagnosis;
        const icdo3DiagnosisValueSetOptions = TerminologyService.getValueSetOptions('DiagnosisICDO3');
        const icdo3Service = new Icdo3Service(icdo3DiagnosisValueSetOptions);

        result += "Ημερομηνία διάγνωσης: " + (diagnosisMessage.diagnosisDate ? dayjs(diagnosisMessage.diagnosisDate).format(DATE_FORMAT) : '-') + '\n';
        const metastaticSiteEntry = TerminologyService.getValueSetOption('MetastaticSite', diagnosisMessage.metastasisSiteType);
        if (metastaticSiteEntry) result += "Metastasis site: " + metastaticSiteEntry.label + '\n';
        let neoplasmType: NeoplasmType|undefined;

        if (diagnosisMessage.icdO3DiagnosisType) {
            const diagnosis = icdo3Service.getDiagnosisByCode(diagnosisMessage.icdO3DiagnosisType);
            neoplasmType = diagnosis?.neoplasmType;

            result += "Κωδικός ICD-O-3: " + diagnosis?.code + '\n';
            result += "Χαρακτηρισμός ICD-O-3: " + diagnosis?.toDisplayText() + '\n';
            result += 'Βαθμός: ' + (TerminologyService.getValueSetOption('IcdO3Grade', diagnosisMessage.gradeType)?.label ?? '-') + '\n';
        }
        else {
            neoplasmType = diagnosisMessage.bodySiteLocationType ? MetastaticSiteService.getNeoplasmType(diagnosisMessage.bodySiteLocationType) : undefined;
        }
        
        //diagnosisMessage.incidentType
        if (neoplasmType === NeoplasmType.Solid && diagnosisMessage.incidentType === "IT.002") result += "Θέση συμπαγούς όγκου (laterality): " + (TerminologyService.getValueSetOption('BodySiteLocation', diagnosisMessage.bodySiteLocationType)?.label ?? '-') + '\n';
        result += "Μέθοδος διάγνωσης: " + (TerminologyService.getValueSetOption('BasisOfDiagnosis', diagnosisMessage.diagnosisMethodType)?.label ?? '-') + '\n';
    }
    if (fhirMessage.friendlyResourceName === FhirResourceFriendlyName.histological) {
        const histologicalMessage = fhirMessage as HistologicalObservation;
    }
    if (fhirMessage.friendlyResourceName === FhirResourceFriendlyName.staging) {
        const stagingMessage = fhirMessage as Staging;

        if (stagingMessage.clinicalStageResultType || stagingMessage.clinicalTType || stagingMessage.clinicalNType || stagingMessage.clinicalMType) {
            result += "Στάδιο νόσου: " + (TerminologyService.getValueSetOption('cNeoplasmAjccStaging', stagingMessage.clinicalStageResultType)?.label ?? '-') + '\n';
            result += "T (clinical): " + (TerminologyService.getValueSetOption('cTNM_T', stagingMessage.clinicalTType)?.label ?? '-') + '\n';
            result += "N (clinical): " + (TerminologyService.getValueSetOption('cTNM_N', stagingMessage.clinicalNType)?.label ?? '-') + '\n';
            result += "M (clinical): " + (TerminologyService.getValueSetOption('cTNM_M', stagingMessage.clinicalMType)?.label ?? '-') + '\n';
        }

        if (stagingMessage.pathologocialStageResultType || stagingMessage.pathologicalTType || stagingMessage.pathologicalNType || stagingMessage.pathologicalMType) {
            result += "Στάδιο νόσου: " + (TerminologyService.getValueSetOption('pNeoplasmAjccStaging', stagingMessage.pathologocialStageResultType)?.label ?? '-') + '\n';
            result += "T (pathological): " + (TerminologyService.getValueSetOption('pTNM_T', stagingMessage.pathologicalTType)?.label ?? '-') + '\n';
            result += "N (pathological): " + (TerminologyService.getValueSetOption('pTNM_N', stagingMessage.pathologicalNType)?.label ?? '-') + '\n';
            result += "M (pathological): " + (TerminologyService.getValueSetOption('pTNM_M', stagingMessage.pathologicalMType)?.label ?? '-') + '\n';
        }
    }
    if (fhirMessage.friendlyResourceName === FhirResourceFriendlyName.biomarker) {
        const biomarkerMessage = fhirMessage as BiomarkerObservation;
        result += getFieldValueOrDash(biomarkerMessage.value);
    }
    if (fhirMessage.friendlyResourceName === FhirResourceFriendlyName.progress) {
        const progressMessage = fhirMessage as Observation;
        result += getFieldValueOrDash(progressMessage.friendlyValue);
    }
    if (fhirMessage.friendlyResourceName === FhirResourceFriendlyName.pathologoReport) {
        const progressMessage = fhirMessage as DiagnosticReport;
        result += getFieldValueOrDash(progressMessage.conclusion);
    }
    if (fhirMessage.fhirResourceName === FhirResourceName.therapy) {
        const therapyMessage = fhirMessage as Therapy;
        result += therapyMessage.occuredDate ? dayjs(therapyMessage.occuredDate).format(DATE_FORMAT) : "-";
    }
    return result;
}

function isFieldValueDifferent<T>(value1:T, value2:T): boolean {
    if (dayjs.isDayjs(value1) && dayjs.isDayjs(value2) && value1.isSame(value2, 'D')) return false;
    if (value1 instanceof Date && value2 instanceof Date && dayjs(value1).isSame(dayjs(value2), 'D')) return false;

    if (value1 === undefined && value2 === undefined) return false;
    if (value1 === null && value2 === null) return false;
    if (value1 === '' && (value2 === null || value2 === undefined)) return false;
    if (value2 === '' && (value1 === null || value1 === undefined)) return false;
    if (value1 === undefined && value2 !== undefined) return true;
    if (value1 === null && value2 !== null) return true;
    if (value1 !== undefined && value2 === undefined) return true;
    if (value1 !== null && value2 === null) return true;
    
    
    return value1 !== value2;
}

function getFieldValueOrDash(value?:string, noValueText?:string): string {
    if (!noValueText) noValueText = "-";
    if (!value) return noValueText;
    if (value=== '') return noValueText;
    return value;
}

enum FhirResourceFriendlyName {
    weight = 'Βάρος',
    height = 'Ύψος',
    bmi = 'BMI',
    smokingStatus = 'Καπνιστής',
    packYears = 'Pack years',
    patientDemographicsAndContact = 'Προσωπικά στοιχεία ασθενούς',
    patientAttendant = 'Στοιχεία συνοδού',
    histological = "Πρόσθετα Παθολογοανατομικά/Εργαστηριακά Δεδομένα",
    diagnosis = 'Διάνγωση νόσου',
    staging = 'Σταδιοποίηση',
    biomarker = 'Βιοδείκτης',
    therapy = 'Θεραπεία',
    progress = 'Καταγραφή Κατάστασης Νόσου',
    pathologoReport = 'Πόρισμα εξέτασης',
}

enum FhirResourceName {
    observation = 'observation',
    patient = 'patient',
    diagnosis = 'diagnosis',
    therapy = 'therapy',
    diagnosticreport = 'diagnosticreport',
}

export {FhirResourceName, FhirResourceFriendlyName, generateFhirMessageSubmissionTitle, generateFhirMessageSubmissionDescription, isFieldValueDifferent, getFieldValueOrDash};