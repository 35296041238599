import { NeoplasmType } from "../enums/neoplasm-type-enum";

class MetastaticSiteService {
	public static getNeoplasmType(metastaticSiteCode: string) : NeoplasmType{
		switch(metastaticSiteCode){
			case 'MetastaticSite.001':
			case 'MetastaticSite.002':
			case 'MetastaticSite.003':
			case 'MetastaticSite.004':
			case 'MetastaticSite.005':
			case 'MetastaticSite.006':
			case 'MetastaticSite.008':
				return NeoplasmType.Solid;

			case 'MetastaticSite.007':
				return NeoplasmType.Hematological;

			default:
				return NeoplasmType.Both;
		}
	}
}

export default MetastaticSiteService;
