import { useEffect, useState } from "react";
import { Histology } from "../models/histology";
import { MultiTabContentComponentParams } from "./multi-tab-fields-container";
import { Empty, Form, Input, InputNumber, Select } from "antd";
import { some } from "lodash";
import { BaseFhirModel } from "../models/fhir/base-fhir-model";
import { FhirResourceFriendlyName, FhirResourceName, isFieldValueDifferent } from "../helpers/fhir-message-helper";
import { HistologicalObservation } from "../models/fhir/histological-observation";
import TerminologyService from "../services/terminology-service";
import Icdo3Service, { RelatedOrgan } from "../services/icdo3-service";
import { NeoplasmType } from "../enums/neoplasm-type-enum";
import { ObservationWireData } from "../models/observation-wire-data";
import { Patient } from "../models/patient";

export class HistologyComponentParams implements MultiTabContentComponentParams  {
    panelTitle:string = "Πρόσθετα Παθολογοανατομικά/Εργαστηριακά Δεδομένα";
    initialValuesOfTabs:{tabTitle:string, initialValues:Histology[]}[]=[];
    constructor(public patient:Patient) {}
    getTabComponents(key:string, isEditMode:boolean, initialValues?:Histology[], onTabValueItemChange?: (itemIndex:number, newValue:Histology, fhirMessages:BaseFhirModel[], isValid:boolean) => void) : React.ReactNode {
        if (!initialValues || initialValues.length === 0) return <Empty/>
        return initialValues.map((initialValue,index)=> {
            return <div key={`${key}-${index}`}><HistologyComponent isEditMode={isEditMode} initialValue={initialValue} patient={this.patient} onValueChange={(newValue, fhirMessages, patient, isValid) => {
            if (onTabValueItemChange) onTabValueItemChange(index, newValue, fhirMessages, isValid);
        }} />
        {initialValues.length-1 > index && <hr />}</div>});
    } 
};



const HistologyComponent: React.FC<{ 
    isEditMode:boolean, 
    initialValue: Histology, 
    patient: Patient,
    onValueChange?:(newValue: Histology, fhirMessages:BaseFhirModel[], patient: Patient, isValid:boolean) => void }> = ({ isEditMode, initialValue, patient, onValueChange }) => {

    const icdo3DiagnosisValueSetOptions = TerminologyService.getValueSetOptions('DiagnosisICDO3');
    const icdo3Service = new Icdo3Service(icdo3DiagnosisValueSetOptions);
    const relatedDiagnoses = patient.diagnoses.filter(d => d.id === initialValue.conditionId || d.dueToDiagnosisId === initialValue.conditionId);
    const icdO3Diagnoses = relatedDiagnoses.map(x => icdo3Service.getDiagnosisByCode(x.icdO3DiagnosisCode!));
        
    const [eksomielikiNososType, setEksomielikiNososType] = useState<string|undefined>(initialValue.isExtramedularDisease);
    const [cytogeneticTestType, setCytogeneticTestType] = useState<string|undefined>(initialValue.cytogeneticTestType);

    const [histologicalGradeType, setHistologocialGradeType] = useState<string|undefined>(initialValue.histologicalGradeType);
    const [fuhrmanGradeType, setFuhrmanGradeType] = useState<string|undefined>(initialValue.fuhrmanGradeType);
    const [isupGradeType, setIsupGradeType] = useState<string|undefined>(initialValue.isupGradeType);
    const [gleasonGrowthType, setGleasonGrowthType]= useState<string|undefined>(initialValue.gleasonGrowthType);
    const [gleasonScoreType, setGleasonScoreType]= useState<string|undefined>(initialValue.gleasonScoreType);
    const [gradeGroupType, setGradeGroupType]=useState<string|undefined>(initialValue.gradeGroupType);
    const [pinExistenceType, setPinExistenceType] = useState<string|undefined>(initialValue.pinGradeType);
    const [hipatitisExistenceType, setHipatitisExistenceType] = useState<string|undefined>(initialValue.isHepatitisOrCirrhosisPresent);
    const [hashimotoThyreoiditisExistenceType, setHashimotoThyreoiditisExistenceType] = useState<string|undefined>(initialValue.isHashimotoThyreoiditisPresent);
    const [hpvExistenceType, setHpvExistenceType] = useState<string|undefined>(initialValue.isHpvPresent);
    const [epitheliumDamagesExistenceType, setEpitheliumDamagesExistenceType] = useState<string|undefined>(initialValue.areIntraepithelialLesionsPresent);
    const [melanomaGrowthType, setMelanomaGrowthType] = useState<string|undefined>(initialValue.melanomaGrowthType);
    const [clarkStageType, setClarkStageType] = useState<string|undefined>(initialValue.clarkStageType);
    const [breslowGrowth, setBreslowGrowth] = useState<number|undefined>(initialValue.breslowGrowth);
    const [moleExistenceType, setMoleExistenceType] = useState<string|undefined>(initialValue.isMolePresent);

    const availableCytogeneticTestTypes = TerminologyService.getValueSetOptions('CytogeneticTestOfHematologicalNeoplasm');
    const availableHistologicalGradeTypes = TerminologyService.getValueSetOptions('SolidTumorHistologicalGrade');
    const availableFuhrmanGradeTypes = TerminologyService.getValueSetOptions('RenalNuclearFuhrmanGrade');
    const availableIsupGradeTypes = TerminologyService.getValueSetOptions('RenalNuclearIsupGrade');
    const availableProstateGleasonGrowthTypes = TerminologyService.getValueSetOptions('ProstateGleasonGrowth');
    const availableProstateGleasonScoreTypes = TerminologyService.getValueSetOptions('ProstateGleasonScore');
    const availableProstateGradeGroupTypes = TerminologyService.getValueSetOptions('ProstateGradeGroup');
    const availableMelanomaGrowthTypes = TerminologyService.getValueSetOptions('MelanomaGrowthType');
    const availableMelanomaClarkStageTypes = TerminologyService.getValueSetOptions('MelanomaClarkStage');
    const availableYesNoTypes = TerminologyService.getValueSetOptions('BooleanValueSet');

    useEffect(()=> {
        if (onValueChange) {
            const fhirMessages: BaseFhirModel[] = [];
            if (isFieldValueDifferent(initialValue?.isExtramedularDisease, eksomielikiNososType)) {
                fhirMessages.push({
                    fhirResourceName:FhirResourceName.observation,
                    friendlyResourceName:FhirResourceFriendlyName.histological,
                    fieldName:'Εξωμυελική νόσος',
                    value:eksomielikiNososType,
                    friendlyValue:eksomielikiNososType?availableYesNoTypes.find(ht => ht.value === eksomielikiNososType)?.label : undefined,
                    isOccuredDateVisible:true,
                    wireData: {
                        patientId: patient.id,
                        conditionId: initialValue.conditionId,
                        code: 'ExtramedularDiseaseOfHematologicalNeoplasm',
                        valueCodeSystem: 'BooleanValueSet',
                        valueCode: eksomielikiNososType,
                        effectiveDateTime: new Date(),
                        effectiveDateTimeEnd: undefined,
                        issuedDate: new Date(),
                    } as ObservationWireData
                } as HistologicalObservation);
            }
            if (isFieldValueDifferent(initialValue?.cytogeneticTestType, cytogeneticTestType)) {
                fhirMessages.push({
                    fhirResourceName:FhirResourceName.observation,
                    friendlyResourceName:FhirResourceFriendlyName.histological,
                    fieldName:'Κυτταρογενετικός έλεγχος',
                    value:cytogeneticTestType,
                    friendlyValue:cytogeneticTestType?availableCytogeneticTestTypes.find(ht => ht.value === cytogeneticTestType)?.label : undefined,
                    isOccuredDateVisible:true,
                    wireData: {
                        patientId: patient.id,
                        conditionId: initialValue.conditionId,
                        code: 'CytogeneticTestOfHematologicalNeoplasm',
                        valueCodeSystem: 'CytogeneticTestOfHematologicalNeoplasm',
                        valueCode: cytogeneticTestType,
                        effectiveDateTime: new Date(),
                        effectiveDateTimeEnd: undefined,
                        issuedDate: new Date(),
                    } as ObservationWireData
                } as HistologicalObservation);
            }
            if (isFieldValueDifferent(initialValue?.histologicalGradeType, histologicalGradeType)) {
                fhirMessages.push({
                    fhirResourceName:FhirResourceName.observation,
                    friendlyResourceName:FhirResourceFriendlyName.histological,
                    fieldName:'Ιστολογικός βαθμός κακοήθειας συμπαγούς όγκου',
                    value:histologicalGradeType,
                    friendlyValue:histologicalGradeType?availableHistologicalGradeTypes.find(ht => ht.value === histologicalGradeType)?.label : undefined,
                    isOccuredDateVisible:true,
                    wireData: {
                        patientId: patient.id,
                        conditionId: initialValue.conditionId,
                        code: 'SolidTumorHistologicalGrade',
                        valueCodeSystem: 'SolidTumorHistologicalGrade',
                        valueCode: histologicalGradeType,
                        effectiveDateTime: new Date(),
                        effectiveDateTimeEnd: undefined,
                        issuedDate: new Date(),
                    } as ObservationWireData
                } as HistologicalObservation);
            }
            if (isFieldValueDifferent(initialValue?.fuhrmanGradeType, fuhrmanGradeType)) {
                fhirMessages.push({
                    fhirResourceName:FhirResourceName.observation,
                    friendlyResourceName:FhirResourceFriendlyName.histological,
                    fieldName:'Πυρηνικός Βαθμός Κακοήθειας (κατά Fuhrman)',
                    value:fuhrmanGradeType,
                    friendlyValue:fuhrmanGradeType?availableFuhrmanGradeTypes.find(ht => ht.value === fuhrmanGradeType)?.label : undefined,
                    isOccuredDateVisible:true,
                    wireData: {
                        patientId: patient.id,
                        conditionId: initialValue.conditionId,
                        code: 'RenalNuclearFuhrmanGrade',
                        valueCodeSystem: 'RenalNuclearFuhrmanGrade',
                        valueCode: fuhrmanGradeType,
                        effectiveDateTime: new Date(),
                        effectiveDateTimeEnd: undefined,
                        issuedDate: new Date(),
                    } as ObservationWireData
                } as HistologicalObservation);
            }
            if (isFieldValueDifferent(initialValue?.isupGradeType, isupGradeType)) {
                fhirMessages.push({
                    fhirResourceName:FhirResourceName.observation,
                    friendlyResourceName:FhirResourceFriendlyName.histological,
                    fieldName:'Πυρηνικός Βαθμός Κακοήθειας (κατά ISUP)',
                    value:isupGradeType,
                    friendlyValue:isupGradeType?availableIsupGradeTypes.find(ht => ht.value === isupGradeType)?.label : undefined,
                    isOccuredDateVisible:true,
                    wireData: {
                        patientId: patient.id,
                        conditionId: initialValue.conditionId,
                        code: 'RenalNuclearIsupGrade',
                        valueCodeSystem: 'RenalNuclearIsupGrade',
                        valueCode: isupGradeType,
                        effectiveDateTime: new Date(),
                        effectiveDateTimeEnd: undefined,
                        issuedDate: new Date(),
                    } as ObservationWireData
                } as HistologicalObservation);
            }
            if (isFieldValueDifferent(initialValue?.gleasonGrowthType, gleasonGrowthType)) {
                fhirMessages.push({
                    fhirResourceName:FhirResourceName.observation,
                    friendlyResourceName:FhirResourceFriendlyName.histological,
                    fieldName:'Ιστολογικό πρότυπο ανάπτυξης κατά Gleason',
                    value:gleasonGrowthType,
                    friendlyValue:gleasonGrowthType?availableProstateGleasonGrowthTypes.find(ht => ht.value === gleasonGrowthType)?.label : undefined,
                    isOccuredDateVisible:true,
                    wireData: {
                        patientId: patient.id,
                        conditionId: initialValue.conditionId,
                        code: 'ProstateGleasonGrowth',
                        valueCodeSystem: 'ProstateGleasonGrowth',
                        valueCode: gleasonGrowthType,
                        effectiveDateTime: new Date(),
                        effectiveDateTimeEnd: undefined,
                        issuedDate: new Date(),
                    } as ObservationWireData
                } as HistologicalObservation);
            }
            if (isFieldValueDifferent(initialValue?.gleasonScoreType, gleasonScoreType)) {
                fhirMessages.push({
                    fhirResourceName:FhirResourceName.observation,
                    friendlyResourceName:FhirResourceFriendlyName.histological,
                    fieldName:'Gleason Score',
                    value:gleasonScoreType,
                    friendlyValue:gleasonScoreType?availableProstateGleasonScoreTypes.find(ht => ht.value === gleasonScoreType)?.label : undefined,
                    isOccuredDateVisible:true,
                    wireData: {
                        patientId: patient.id,
                        conditionId: initialValue.conditionId,
                        code: 'ProstateGleasonScore',
                        valueCodeSystem: 'ProstateGleasonScore',
                        valueCode: gleasonScoreType,
                        effectiveDateTime: new Date(),
                        effectiveDateTimeEnd: undefined,
                        issuedDate: new Date(),
                    } as ObservationWireData
                } as HistologicalObservation);
            }
            if (isFieldValueDifferent(initialValue?.gradeGroupType, gradeGroupType)) {
                fhirMessages.push({
                    fhirResourceName:FhirResourceName.observation,
                    friendlyResourceName:FhirResourceFriendlyName.histological,
                    fieldName:'Grade Group',
                    value:gradeGroupType,
                    friendlyValue:gradeGroupType?availableProstateGradeGroupTypes.find(ht => ht.value === gradeGroupType)?.label : undefined,
                    isOccuredDateVisible:true,
                    wireData: {
                        patientId: patient.id,
                        conditionId: initialValue.conditionId,
                        code: 'ProstateGradeGroup',
                        valueCodeSystem: 'ProstateGradeGroup',
                        valueCode: gradeGroupType,
                        effectiveDateTime: new Date(),
                        effectiveDateTimeEnd: undefined,
                        issuedDate: new Date(),
                    } as ObservationWireData
                } as HistologicalObservation);
            }
            if (isFieldValueDifferent(initialValue?.pinGradeType, pinExistenceType)) {
                fhirMessages.push({
                    fhirResourceName:FhirResourceName.observation,
                    friendlyResourceName:FhirResourceFriendlyName.histological,
                    fieldName:'Υψηλόβαθμο PIN',
                    value:pinExistenceType,
                    friendlyValue:pinExistenceType?availableYesNoTypes.find(ht => ht.value === pinExistenceType)?.label : undefined,
                    isOccuredDateVisible:true,
                    wireData: {
                        patientId: patient.id,
                        conditionId: initialValue.conditionId,
                        code: 'ProstaticIntraepithelialNeoplasiaGrade',
                        valueCodeSystem: 'BooleanValueSet',
                        valueCode: pinExistenceType,
                        effectiveDateTime: new Date(),
                        effectiveDateTimeEnd: undefined,
                        issuedDate: new Date(),
                    } as ObservationWireData
                } as HistologicalObservation);
            }
            if (isFieldValueDifferent(initialValue?.isHepatitisOrCirrhosisPresent, hipatitisExistenceType)) {
                fhirMessages.push({
                    fhirResourceName:FhirResourceName.observation,
                    friendlyResourceName:FhirResourceFriendlyName.histological,
                    fieldName:'Συνύπαρξη ηπατίδας ή κίρρωσης',
                    value:hipatitisExistenceType,
                    friendlyValue:hipatitisExistenceType?availableYesNoTypes.find(ht => ht.value === hipatitisExistenceType)?.label : undefined,
                    isOccuredDateVisible:true,
                    wireData: {
                        patientId: patient.id,
                        conditionId: initialValue.conditionId,
                        code: 'Comorbidity_HepatitisOrCirrhosis',
                        valueCodeSystem: 'BooleanValueSet',
                        valueCode: hipatitisExistenceType,
                        effectiveDateTime: new Date(),
                        effectiveDateTimeEnd: undefined,
                        issuedDate: new Date(),
                    } as ObservationWireData
                } as HistologicalObservation);
            }
            if (isFieldValueDifferent(initialValue?.isHashimotoThyreoiditisPresent, hashimotoThyreoiditisExistenceType)) {
                fhirMessages.push({
                    fhirResourceName:FhirResourceName.observation,
                    friendlyResourceName:FhirResourceFriendlyName.histological,
                    fieldName:'Συνύπαρξη θυρεοειδίτιδας Hashimoto',
                    value:hashimotoThyreoiditisExistenceType,
                    friendlyValue:hashimotoThyreoiditisExistenceType?availableYesNoTypes.find(ht => ht.value === hashimotoThyreoiditisExistenceType)?.label : undefined,
                    isOccuredDateVisible:true,
                    wireData: {
                        patientId: patient.id,
                        conditionId: initialValue.conditionId,
                        code: 'Comorbidity_HashimotoThyreoiditis',
                        valueCodeSystem: 'BooleanValueSet',
                        valueCode: hashimotoThyreoiditisExistenceType,
                        effectiveDateTime: new Date(),
                        effectiveDateTimeEnd: undefined,
                        issuedDate: new Date(),
                    } as ObservationWireData
                } as HistologicalObservation);
            }
            if (isFieldValueDifferent(initialValue?.isHpvPresent, hpvExistenceType)) {
                fhirMessages.push({
                    fhirResourceName:FhirResourceName.observation,
                    friendlyResourceName:FhirResourceFriendlyName.histological,
                    fieldName:'Συνύπαρξη HPV αλλοιώσεων',
                    value:hpvExistenceType,
                    friendlyValue:hpvExistenceType?availableYesNoTypes.find(ht => ht.value === hpvExistenceType)?.label : undefined,
                    isOccuredDateVisible:true,
                    wireData: {
                        patientId: patient.id,
                        conditionId: initialValue.conditionId,
                        code: 'Comorbidity_HpvLesions',
                        valueCodeSystem: 'BooleanValueSet',
                        valueCode: hpvExistenceType,
                        effectiveDateTime: new Date(),
                        effectiveDateTimeEnd: undefined,
                        issuedDate: new Date(),
                    } as ObservationWireData
                } as HistologicalObservation);
            }
            if (isFieldValueDifferent(initialValue?.areIntraepithelialLesionsPresent, epitheliumDamagesExistenceType)) {
                fhirMessages.push({
                    fhirResourceName:FhirResourceName.observation,
                    friendlyResourceName:FhirResourceFriendlyName.histological,
                    fieldName:'Συνύπαρξη ενδοεπιθηλιακών βλαβών (LGSIL/HGSIL)',
                    value:epitheliumDamagesExistenceType,
                    friendlyValue:epitheliumDamagesExistenceType?availableYesNoTypes.find(ht => ht.value === epitheliumDamagesExistenceType)?.label : undefined,
                    isOccuredDateVisible:true,
                    wireData: {
                        patientId: patient.id,
                        conditionId: initialValue.conditionId,
                        code: 'Comorbidity_IntraepithelialLesions',
                        valueCodeSystem: 'BooleanValueSet',
                        valueCode: epitheliumDamagesExistenceType,
                        effectiveDateTime: new Date(),
                        effectiveDateTimeEnd: undefined,
                        issuedDate: new Date(),
                    } as ObservationWireData
                } as HistologicalObservation);
            }
            if (isFieldValueDifferent(initialValue?.melanomaGrowthType, melanomaGrowthType)) {
                fhirMessages.push({
                    fhirResourceName:FhirResourceName.observation,
                    friendlyResourceName:FhirResourceFriendlyName.histological,
                    fieldName:'Τρόπος ανάπτυξης μελανώματος',
                    value:melanomaGrowthType,
                    friendlyValue:melanomaGrowthType?availableMelanomaGrowthTypes.find(ht => ht.value === melanomaGrowthType)?.label : undefined,
                    isOccuredDateVisible:true,
                    wireData: {
                        patientId: patient.id,
                        conditionId: initialValue.conditionId,
                        code: 'MelanomaGrowthType',
                        valueCodeSystem: 'MelanomaGrowthType',
                        valueCode: melanomaGrowthType,
                        effectiveDateTime: new Date(),
                        effectiveDateTimeEnd: undefined,
                        issuedDate: new Date(),
                    } as ObservationWireData
                } as HistologicalObservation);
            }
            if (isFieldValueDifferent(initialValue?.clarkStageType, clarkStageType)) {
                fhirMessages.push({
                    fhirResourceName:FhirResourceName.observation,
                    friendlyResourceName:FhirResourceFriendlyName.histological,
                    fieldName:'Στάδιο κατά Clark',
                    value:clarkStageType,
                    friendlyValue:clarkStageType?availableMelanomaClarkStageTypes.find(ht => ht.value === clarkStageType)?.label : undefined,
                    isOccuredDateVisible:true,
                    wireData: {
                        patientId: patient.id,
                        conditionId: initialValue.conditionId,
                        code: 'MelanomaClarkStage',
                        valueCodeSystem: 'MelanomaClarkStage',
                        valueCode: clarkStageType,
                        effectiveDateTime: new Date(),
                        effectiveDateTimeEnd: undefined,
                        issuedDate: new Date(),
                    } as ObservationWireData
                } as HistologicalObservation);
            }
            if (isFieldValueDifferent(initialValue?.breslowGrowth, breslowGrowth)) {
                fhirMessages.push({
                    fhirResourceName:FhirResourceName.observation,
                    friendlyResourceName:FhirResourceFriendlyName.histological,
                    fieldName:'Ανάπτυξη κατά Breslow',
                    value:breslowGrowth,
                    friendlyValue:breslowGrowth,
                    isOccuredDateVisible:true,
                    wireData: {
                        patientId: patient.id,
                        conditionId: initialValue.conditionId,
                        code: 'MelanomaBreslowGrowth',
                        valueCodeSystem: 'MelanomaBreslowGrowth',
                        valueCode: breslowGrowth,
                        effectiveDateTime: new Date(),
                        effectiveDateTimeEnd: undefined,
                        issuedDate: new Date(),
                    } as ObservationWireData
                } as HistologicalObservation);
            }
            if (isFieldValueDifferent(initialValue?.isMolePresent, moleExistenceType)) {
                fhirMessages.push({
                    fhirResourceName:FhirResourceName.observation,
                    friendlyResourceName:FhirResourceFriendlyName.histological,
                    fieldName:'Παρουσία προϋπάρχοντος σπίλου',
                    value:moleExistenceType,
                    friendlyValue:moleExistenceType?availableYesNoTypes.find(yn=>yn.value === moleExistenceType)?.label : undefined,
                    isOccuredDateVisible:true,
                    wireData: {
                        patientId: patient.id,
                        conditionId: initialValue.conditionId,
                        code: 'MelanomaHadPreexistingMole',
                        valueCodeSystem: 'BooleanValueSet',
                        valueCode: moleExistenceType,
                        effectiveDateTime: new Date(),
                        effectiveDateTimeEnd: undefined,
                        issuedDate: new Date(),
                    } as ObservationWireData
                } as HistologicalObservation);
            }

            const newHistology = new Histology(initialValue.conditionId);
            newHistology.isExtramedularDisease = eksomielikiNososType;
            newHistology.cytogeneticTestType = cytogeneticTestType;
            newHistology.histologicalGradeType = histologicalGradeType;
            newHistology.fuhrmanGradeType = fuhrmanGradeType;
            newHistology.isupGradeType = isupGradeType;
            newHistology.gleasonGrowthType = gleasonGrowthType;
            newHistology.gleasonScoreType = gleasonScoreType;
            newHistology.gradeGroupType = gradeGroupType;
            newHistology.pinGradeType = pinExistenceType;
            newHistology.isHepatitisOrCirrhosisPresent = hipatitisExistenceType;
            newHistology.isHashimotoThyreoiditisPresent = hashimotoThyreoiditisExistenceType;
            newHistology.isHpvPresent = hpvExistenceType;
            newHistology.areIntraepithelialLesionsPresent = epitheliumDamagesExistenceType;
            newHistology.melanomaGrowthType = melanomaGrowthType;
            newHistology.clarkStageType = clarkStageType;
            newHistology.breslowGrowth = breslowGrowth;
            newHistology.isMolePresent = moleExistenceType;

            onValueChange(newHistology, fhirMessages, patient, !getAllFieldsAreEmpty());
        }
    }, [eksomielikiNososType, cytogeneticTestType, histologicalGradeType, fuhrmanGradeType, isupGradeType, gleasonGrowthType,gleasonScoreType, gradeGroupType, pinExistenceType, hipatitisExistenceType, hashimotoThyreoiditisExistenceType, epitheliumDamagesExistenceType, melanomaGrowthType, clarkStageType, breslowGrowth, moleExistenceType]);

    function getAllFieldsAreEmpty() : boolean {
        return (!eksomielikiNososType && !cytogeneticTestType && !histologicalGradeType && !fuhrmanGradeType && !isupGradeType && !gleasonGrowthType && !gleasonScoreType && !gradeGroupType && !pinExistenceType && !hipatitisExistenceType && !hashimotoThyreoiditisExistenceType && !hpvExistenceType && !epitheliumDamagesExistenceType && !melanomaGrowthType && !clarkStageType && !breslowGrowth && !moleExistenceType);
    }
    return (
        <>
            <Form
                layout='vertical'
                style={{ display: 'flex', flexDirection:'row' }}
                autoComplete="off"
            >
                {some(icdO3Diagnoses, d => d?.neoplasmType === NeoplasmType.Hematological) && <>
                    <div className='patient-fields-column'>
                        <Form.Item
                            label="Εξωμυελική νόσος"
                        >
                            {isEditMode && <Select options={availableYesNoTypes} allowClear={true} value={eksomielikiNososType} onChange={(value) => {
                                setEksomielikiNososType(value);
                            }}/>}
                            {!isEditMode && <div className='field-value-text'>{eksomielikiNososType ? availableYesNoTypes.find(yn=>yn.value === eksomielikiNososType)!.label: '-'}</div>}
                        </Form.Item>
                    </div>
                    <div className='patient-fields-column'>
                        <Form.Item
                            label="Κυτταρογενετικός έλεγχος"
                        >
                            {isEditMode && <Select options={availableCytogeneticTestTypes} allowClear={true} value={cytogeneticTestType} onChange={(value) => {
                                setCytogeneticTestType(value);
                            }}/>}
                            {!isEditMode && <div className='field-value-text'>{cytogeneticTestType ? availableCytogeneticTestTypes.find(yn=>yn.value === cytogeneticTestType)!.label: '-'}</div>}
                        </Form.Item>
                    </div>
                    <div className='patient-fields-column'>

                    </div>
                </>}

                {some(icdO3Diagnoses, d => d?.neoplasmType === NeoplasmType.Solid) && <>
                    <div className='patient-fields-column'>
                        <Form.Item
                            label="Ιστολογικός τύπος συμπαγούς όγκου"
                        >
                            <div className='field-value-text'>{ icdO3Diagnoses && icdO3Diagnoses[0]?.histologyAndBehaviorDescription}</div>
                        </Form.Item>

                        {some(icdO3Diagnoses, d=>(d?.relatedOrgan !== RelatedOrgan.Kidney && d?.relatedOrgan !== RelatedOrgan.Prostate)) && <Form.Item
                            label="Ιστολογικός βαθμός κακοήθειας συμπαγούς όγκου"
                        >
                            {isEditMode && <Select options={availableHistologicalGradeTypes} allowClear={true} value={histologicalGradeType} onChange={(value) => {
                                setHistologocialGradeType(value);
                            }}/>}
                            {!isEditMode && <div className='field-value-text'>{histologicalGradeType ? availableHistologicalGradeTypes.find(hgt=>hgt.value === histologicalGradeType)!.label: '-'}</div>}
                        </Form.Item>}


                        
                    </div>
                    <div className='patient-fields-column'>
                        { some(icdO3Diagnoses, d => d?.relatedOrgan === RelatedOrgan.Kidney) && <> <Form.Item
                            label="Πυρηνικός Βαθμός Κακοήθειας (κατά Fuhrman)"
                        >
                            {isEditMode && <Select options={availableFuhrmanGradeTypes} allowClear={true} value={fuhrmanGradeType} onChange={(value) => {
                                setFuhrmanGradeType(value);
                            }}/>}
                            {!isEditMode && <div className='field-value-text'>{fuhrmanGradeType ? availableFuhrmanGradeTypes.find(fgt=>fgt.value === fuhrmanGradeType)!.label: '-'}</div>}
                        </Form.Item>
                        <Form.Item
                            label="Πυρηνικός Βαθμός Κακοήθειας (κατά ISUP)"
                        >
                            {isEditMode && <Select options={availableIsupGradeTypes} allowClear={true} value={isupGradeType} onChange={(value) => {
                                setIsupGradeType(value);
                            }}/>}
                            {!isEditMode && <div className='field-value-text'>{isupGradeType ? availableIsupGradeTypes.find(fgt=>fgt.value === isupGradeType)!.label: '-'}</div>}
                        </Form.Item></>}


                        { some(icdO3Diagnoses, d => d?.relatedOrgan === RelatedOrgan.Prostate) && <><Form.Item
                            label="Ιστολογικό πρότυπο ανάπτυξης κατά Gleason"
                        >
                            {isEditMode && <Select options={availableProstateGleasonGrowthTypes} allowClear={true} value={gleasonGrowthType} onChange={(value) => {
                                setGleasonGrowthType(value);
                            }}/>}
                            {!isEditMode && <div className='field-value-text'>{gleasonGrowthType ? availableProstateGleasonGrowthTypes.find(ggt=>ggt.value === gleasonGrowthType)!.label: '-'}</div>}
                        </Form.Item>
                        <Form.Item
                            label="Gleason Score"
                        >
                            {isEditMode && <Select options={availableProstateGleasonScoreTypes} allowClear={true} value={gleasonScoreType} onChange={(value) => {
                                setGleasonScoreType(value);
                            }}/>}
                            {!isEditMode && <div className='field-value-text'>{gleasonScoreType ? availableProstateGleasonScoreTypes.find(gst=>gst.value === gleasonScoreType)!.label: '-'}</div>}
                        </Form.Item>
                        <Form.Item
                            label="Grade Group"
                        >
                            {isEditMode && <Select options={availableProstateGradeGroupTypes} allowClear={true} value={gradeGroupType} onChange={(value) => {
                                setGradeGroupType(value);
                            }}/>}
                            {!isEditMode && <div className='field-value-text'>{gradeGroupType ? availableProstateGradeGroupTypes.find(ggt=>ggt.value === gradeGroupType)!.label: '-'}</div>}
                        </Form.Item>
                        <Form.Item
                            label="Υψηλόβαθμο PIN"
                        >
                            {isEditMode && <Select options={availableYesNoTypes} allowClear={true} value={pinExistenceType} onChange={(value) => {
                                setPinExistenceType(value);
                            }}/>}
                            {!isEditMode && <div className='field-value-text'>{pinExistenceType ? availableYesNoTypes.find(yn=>yn.value === pinExistenceType)!.label: '-'}</div>}
                        </Form.Item></>}


                        { (some(icdO3Diagnoses, d => d?.relatedOrgan === RelatedOrgan.Liver) || some(icdO3Diagnoses, d => d?.relatedOrgan === RelatedOrgan.BileDuct)) && <Form.Item
                            label="Συνύπαρξη ηπατίδας ή κίρρωσης"
                        >
                            {isEditMode && <Select options={availableYesNoTypes} allowClear={true} value={hipatitisExistenceType} onChange={(value) => {
                                setHipatitisExistenceType(value);
                            }}/>}
                            {!isEditMode && <div className='field-value-text'>{hipatitisExistenceType ? availableYesNoTypes.find(yn=>yn.value === hipatitisExistenceType)!.label: '-'}</div>}
                        </Form.Item>}

                        { some(icdO3Diagnoses, d => d?.relatedOrgan === RelatedOrgan.ThyroidGland) && <Form.Item
                            label="Συνύπαρξη θυρεοειδίτιδας Hashimoto"
                        >
                            {isEditMode && <Select options={availableYesNoTypes} allowClear={true} value={hashimotoThyreoiditisExistenceType} onChange={(value) => {
                                setHashimotoThyreoiditisExistenceType(value);
                            }}/>}
                            {!isEditMode && <div className='field-value-text'>{hashimotoThyreoiditisExistenceType ? availableYesNoTypes.find(yn=>yn.value === hashimotoThyreoiditisExistenceType)!.label: '-'}</div>}
                        </Form.Item>}


                        { some(icdO3Diagnoses, d => d?.relatedOrgan === RelatedOrgan.Utery) && <><Form.Item
                            label="Συνύπαρξη HPV αλλοιώσεων"
                        >
                            {isEditMode && <Select options={availableYesNoTypes} allowClear={true} value={hpvExistenceType} onChange={(value) => {
                                setHpvExistenceType(value);
                            }}/>}
                            {!isEditMode && <div className='field-value-text'>{hpvExistenceType ? availableYesNoTypes.find(yn=>yn.value === hpvExistenceType)!.label: '-'}</div>}
                        </Form.Item>
                         <Form.Item
                            label="Συνύπαρξη ενδοεπιθηλιακών βλαβών (LGSIL/HGSIL)"
                        >
                            {isEditMode && <Select options={availableYesNoTypes} allowClear={true} value={epitheliumDamagesExistenceType} onChange={(value) => {
                                setEpitheliumDamagesExistenceType(value);
                            }}/>}
                            {!isEditMode && <div className='field-value-text'>{epitheliumDamagesExistenceType ? availableYesNoTypes.find(yn=>yn.value === epitheliumDamagesExistenceType)!.label: '-'}</div>}
                        </Form.Item></>}


                        { some(icdO3Diagnoses, d => d?.relatedOrgan === RelatedOrgan.SkinMelanoma) && <><Form.Item
                            label="Τρόπος ανάπτυξης μελανώματος"
                        >
                            {isEditMode && <Select options={availableMelanomaGrowthTypes} allowClear={true} value={melanomaGrowthType} onChange={(value) => {
                                setMelanomaGrowthType(value);
                            }}/>}
                            {!isEditMode && <div className='field-value-text'>{melanomaGrowthType ? availableMelanomaGrowthTypes.find(yn=>yn.value === melanomaGrowthType)!.label: '-'}</div>}
                        </Form.Item><Form.Item
                            label="Στάδιο κατά Clark"
                        >
                            {isEditMode && <Select options={availableMelanomaClarkStageTypes} allowClear={true} value={clarkStageType} onChange={(value) => {
                                setClarkStageType(value);
                            }}/>}
                            {!isEditMode && <div className='field-value-text'>{clarkStageType ? availableMelanomaClarkStageTypes.find(yn=>yn.value === clarkStageType)!.label: '-'}</div>}
                        </Form.Item>
                        <Form.Item
                            label="Ανάπτυξη κατά Breslow"
                        >
                            {isEditMode && <InputNumber min={0} precision={0} value={breslowGrowth} onChange={(value) => {
                                setBreslowGrowth(value !== null ? value: undefined);
                            }}/>}
                            {!isEditMode && <div className='field-value-text'>{breslowGrowth ?? "-"}</div>}
                        </Form.Item>
                        <Form.Item
                            label="Παρουσία προϋπάρχοντος σπίλου"
                        >
                            {isEditMode && <Select options={availableYesNoTypes} allowClear={true} value={moleExistenceType} onChange={(value) => {
                                setMoleExistenceType(value);
                            }}/>}
                            {!isEditMode && <div className='field-value-text'>{moleExistenceType ? availableYesNoTypes.find(yn=>yn.value === moleExistenceType)!.label: '-'}</div>}
                        </Form.Item></>}
                    </div>
                    <div className='patient-fields-column'>
                        
                        
                        
                    </div>
                </>}
                
            </Form>
        </>
    );
    
}
export default HistologyComponent;