import { useEffect, useState } from "react";
import { MultiTabContentComponentParams } from "./multi-tab-fields-container";
import { Empty, Form, Select } from "antd";
import { Staging } from "../models/staging";
import { Staging as FhirStaging } from "../models/fhir/staging";
import { BaseFhirModel } from "../models/fhir/base-fhir-model";
import { FhirResourceFriendlyName, FhirResourceName, isFieldValueDifferent } from "../helpers/fhir-message-helper";
import TerminologyService from "../services/terminology-service";
import { ValueSetOption } from "../models/fhir/value-set";
import { Patient } from "../models/patient";
import { normalizeString } from "../helpers/string-helpers";

export class StagingComponentParams implements MultiTabContentComponentParams  {
    panelTitle:string = "Σταδιοποίηση";
    initialValuesOfTabs:{tabTitle:string, initialValues:Staging[]}[]=[];
    constructor(public patient:Patient) {}
    getTabComponents(key:string, isEditMode:boolean, initialValues?:Staging[], onTabValueItemChange?: (itemIndex:number, newValue:Staging,fhirMessages:BaseFhirModel[], isValid:boolean) => void) : React.ReactNode {
        if (!initialValues || initialValues.length === 0) return <Empty/>
        return initialValues.map((initialValue,index)=> <div key={`${key}-${index}`}><StagingComponent isEditMode={isEditMode} initialValue={initialValue} patient={this.patient} onValueChange={(newValue, fhirMessages, patient, isValid) => {
            if (onTabValueItemChange) onTabValueItemChange(index, newValue, fhirMessages, isValid);
        }} />
        {initialValues.length-1 > index && <hr/>}</div>);
    } 
};


const StagingComponent: React.FC<{ 
    isEditMode: boolean, 
    initialValue: Staging,
    patient: Patient,
    onValueChange?:(newValue: Staging, fhirMessages:BaseFhirModel[], patient: Patient, isValid:boolean) => void }> = ({ isEditMode, initialValue, patient, onValueChange }) => {
    
    const processStagingValues = (values: Array<any>) => {
        return values.filter(x => !x.value.includes('.None'));
    };
    const availableClinicalAjccStages = processStagingValues(TerminologyService.getValueSetOptions('cNeoplasmAjccStaging'));
    const availablePathologicalAjccStages = processStagingValues(TerminologyService.getValueSetOptions('pNeoplasmAjccStaging'));
    const availableClinicalTTypes = processStagingValues(TerminologyService.getValueSetOptions('cTNM_T'));
    const availableClinicalNTypes = processStagingValues(TerminologyService.getValueSetOptions('cTNM_N'));
    const availableClinicalMTypes = processStagingValues(TerminologyService.getValueSetOptions('cTNM_M'));
    const availablePathologicalTTypes = processStagingValues(TerminologyService.getValueSetOptions('pTNM_T'));
    const availablePathologicalNTypes = processStagingValues(TerminologyService.getValueSetOptions('pTNM_N'));
    const availablePathologicalMTypes = processStagingValues(TerminologyService.getValueSetOptions('pTNM_M'));

    const [stageResultType, setStageResultType] = useState<string|undefined>(initialValue.stageResultType);
    const [availableStageResultTypes, setAvailableStageResultTypes] = useState<ValueSetOption[]>(availableClinicalAjccStages);
    const [isStagePathological, setIsStagePathological] = useState<boolean>(false);
    const [clinicalTType, setClinicalTType]  = useState<string|undefined>(initialValue.clinicalTType);
    const [clinicalNType, setClinicalNType]  = useState<string|undefined>(initialValue.clinicalNType);
    const [clinicalMType, setClinicalMType]  = useState<string|undefined>(initialValue.clinicalMType);
    const [pathologicalTType, setPathologicalTType]  = useState<string|undefined>(initialValue.pathologicalTType);
    const [pathologicalNType, setPathologicalNType]  = useState<string|undefined>(initialValue.pathologicalNType);
    const [pathologicalMType, setPathologicalMType]  = useState<string|undefined>(initialValue.pathologicalMType);

    useEffect(()=> {
        if (onValueChange) {
            const fhirMessages: BaseFhirModel[] = [];
            if (isFieldValueDifferent(initialValue?.stageResultType, stageResultType) ||
                isFieldValueDifferent(initialValue?.clinicalTType, clinicalTType) ||
                isFieldValueDifferent(initialValue?.clinicalNType, clinicalNType) ||
                isFieldValueDifferent(initialValue?.clinicalMType, clinicalMType)) {
                fhirMessages.push({
                    isOccuredDateVisible:true,
                    fhirResourceName:FhirResourceName.observation,
                    friendlyResourceName:FhirResourceFriendlyName.staging,
                    clinicalTType,
                    clinicalNType,
                    clinicalMType,
                    clinicalStageResultType: stageResultType,
                    wireData: {
                        patientId: patient.id,
                        conditionId: initialValue.conditionId,
                        code: 'cNeoplasmAjccStaging',
                        valueCodeSystem: 'cNeoplasmAjccStaging',
                        valueCode: isStagePathological ? 'cAjccStaging.None' : stageResultType ?? 'cAjccStaging.None',
                        effectiveDateTime: new Date(),
                        effectiveDateTimeEnd: undefined,
                        issuedDate: new Date(),
                        components: [
                            {
                                code: 'cTNM_T',
                                valueSystem: 'cTNM_T',
                                valueCode: clinicalTType ?? 'cTNM_T.None',
                            },
                            {
                                code: 'cTNM_N',
                                valueSystem: 'cTNM_N',
                                valueCode: clinicalNType ?? 'cTNM_N.None',
                            },
                            {
                                code: 'cTNM_M',
                                valueSystem: 'cTNM_M',
                                valueCode: clinicalMType ?? 'cTNM_M.None',
                            },
                        ]
                    }
                } as FhirStaging);
            }
            if (isFieldValueDifferent(initialValue?.stageResultType, stageResultType) ||
                isFieldValueDifferent(initialValue?.pathologicalTType, pathologicalTType) ||
                isFieldValueDifferent(initialValue?.pathologicalNType, pathologicalNType) ||
                isFieldValueDifferent(initialValue?.pathologicalMType, pathologicalMType)) {
                fhirMessages.push({
                    isOccuredDateVisible:true,
                    fhirResourceName:FhirResourceName.observation,
                    friendlyResourceName:FhirResourceFriendlyName.staging,
                    pathologicalTType,
                    pathologicalNType,
                    pathologicalMType,
                    pathologocialStageResultType: stageResultType,
                    wireData: {
                        patientId: patient.id,
                        conditionId: initialValue.conditionId,
                        code: 'pNeoplasmAjccStaging',
                        valueCodeSystem: 'pNeoplasmAjccStaging',
                        valueCode: isStagePathological ? stageResultType ?? 'pAjccStaging.None' : 'pAjccStaging.None',
                        effectiveDateTime: new Date(),
                        effectiveDateTimeEnd: undefined,
                        issuedDate: new Date(),
                        components: [
                            {
                                code: 'pTNM_T',
                                valueSystem: 'pTNM_T',
                                valueCode: pathologicalTType ?? 'pTNM_T.None',
                            },
                            {
                                code: 'pTNM_N',
                                valueSystem: 'pTNM_N',
                                valueCode: pathologicalNType ?? 'pTNM_N.None',
                            },
                            {
                                code: 'pTNM_M',
                                valueSystem: 'pTNM_M',
                                valueCode: pathologicalMType ?? 'pTNM_M.None',
                            },
                        ]
                    }
                } as FhirStaging);
            }
            const newStaging = new Staging(initialValue.conditionId);
            newStaging.stageResultType = stageResultType;
            newStaging.clinicalTType = clinicalTType;
            newStaging.clinicalNType = clinicalNType;
            newStaging.clinicalMType = clinicalMType;
            newStaging.pathologicalTType = pathologicalTType;
            newStaging.pathologicalNType = pathologicalNType;
            newStaging.pathologicalMType = pathologicalMType;
            onValueChange(newStaging,fhirMessages, patient, true);
        }
    }, [stageResultType, clinicalTType, clinicalNType, clinicalMType, pathologicalTType, pathologicalNType, pathologicalMType]);
    
    useEffect(() => {
        if(pathologicalTType && pathologicalNType && pathologicalMType) {
            setAvailableStageResultTypes(availablePathologicalAjccStages);
            setIsStagePathological(true);
        }
        else {
            setAvailableStageResultTypes(availableClinicalAjccStages);
            setIsStagePathological(false);
        }
        
        if(pathologicalTType && pathologicalNType && pathologicalMType)
        {
            if(stageResultType?.startsWith('p'))
                return;
        }

        if (!!(pathologicalTType && pathologicalNType && pathologicalMType)) setStageResultType(undefined);
    }, [!!(pathologicalTType && pathologicalNType && pathologicalMType)]);
    
    return (
        <>
            <Form
                layout='vertical'
                style={{ display: 'flex', flexDirection:'row' }}
                autoComplete="off"
            >
                    <div className='patient-fields-column'>
                        <Form.Item
                            label="Στάδιο νόσου"
                        >
                            {isEditMode && <Select options={availableStageResultTypes} allowClear={true} value={stageResultType} onChange={(value) => {
                                setStageResultType(value);
                            }} showSearch filterOption={(input, option) =>
                                normalizeString(option?.label ?? '').toLowerCase().includes(normalizeString(input??'').toLowerCase())
                            } />}
                            {!isEditMode && <div className='field-value-text'>{stageResultType ? availableStageResultTypes.find(yn=>yn.value === stageResultType)?.label: '-'}</div>}
                        </Form.Item>
                    </div>

                    <div className='patient-fields-column'>
                        <Form.Item
                            label="Clinical T"
                        >
                            {isEditMode && <Select options={availableClinicalTTypes} allowClear={true} value={clinicalTType} onChange={(value) => {
                                setClinicalTType(value);
                            }} showSearch filterOption={(input, option) =>
                                normalizeString(option?.label ?? '').toLowerCase().includes(normalizeString(input??'').toLowerCase())
                            } />}
                            {!isEditMode && <div className='field-value-text'>{clinicalTType ? availableClinicalTTypes.find(yn=>yn.value === clinicalTType)?.label: '-'}</div>}
                        </Form.Item>
                        <Form.Item
                            label="Clinical N"
                        >
                            {isEditMode && <Select options={availableClinicalNTypes} allowClear={true} value={clinicalNType} onChange={(value) => {
                                setClinicalNType(value);
                            }} showSearch filterOption={(input, option) =>
                                normalizeString(option?.label ?? '').toLowerCase().includes(normalizeString(input??'').toLowerCase())
                            } />}
                            {!isEditMode && <div className='field-value-text'>{clinicalNType ? availableClinicalNTypes.find(yn=>yn.value === clinicalNType)?.label: '-'}</div>}
                        </Form.Item>
                        <Form.Item
                            label="Clinical M"
                        >
                            {isEditMode && <Select options={availableClinicalMTypes} allowClear={true} value={clinicalMType} onChange={(value) => {
                                setClinicalMType(value);
                            }} showSearch filterOption={(input, option) =>
                                normalizeString(option?.label ?? '').toLowerCase().includes(normalizeString(input??'').toLowerCase())
                            } />}
                            {!isEditMode && <div className='field-value-text'>{clinicalMType ? availableClinicalMTypes.find(yn=>yn.value === clinicalMType)?.label: '-'}</div>}
                        </Form.Item>
                    </div>

                    <div className='patient-fields-column'>
                        <Form.Item
                            label="Pathological T"
                        >
                            {isEditMode && <Select options={availablePathologicalTTypes} allowClear={true} value={pathologicalTType} onChange={(value) => {
                                setPathologicalTType(value);
                            }} showSearch filterOption={(input, option) =>
                                normalizeString(option?.label ?? '').toLowerCase().includes(normalizeString(input??'').toLowerCase())
                            } />}
                            {!isEditMode && <div className='field-value-text'>{pathologicalTType ? availablePathologicalTTypes.find(yn=>yn.value === pathologicalTType)?.label: '-'}</div>}
                        </Form.Item>
                        <Form.Item
                            label="Pathological N"
                        >
                            {isEditMode && <Select options={availablePathologicalNTypes} allowClear={true} value={pathologicalNType} onChange={(value) => {
                                setPathologicalNType(value);
                            }} showSearch filterOption={(input, option) =>
                                normalizeString(option?.label ?? '').toLowerCase().includes(normalizeString(input??'').toLowerCase())
                            } />}
                            {!isEditMode && <div className='field-value-text'>{pathologicalNType ? availablePathologicalNTypes.find(yn=>yn.value === pathologicalNType)?.label: '-'}</div>}
                        </Form.Item>
                        <Form.Item
                            label="Pathological M"
                        >
                            {isEditMode && <Select options={availablePathologicalMTypes} allowClear={true} value={pathologicalMType} onChange={(value) => {
                                setPathologicalMType(value);
                            }} showSearch filterOption={(input, option) =>
                                normalizeString(option?.label ?? '').toLowerCase().includes(normalizeString(input??'').toLowerCase())
                            } />}
                            {!isEditMode && <div className='field-value-text'>{pathologicalMType ? availablePathologicalMTypes.find(yn=>yn.value === pathologicalMType)?.label: '-'}</div>}
                        </Form.Item>
                    </div>
            </Form>
        </>
    );
    
}
export default StagingComponent;