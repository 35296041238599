
export class Doctor {
    public readonly id: string;
    public readonly name: string;
    public readonly surname: string;
    public readonly organization: string;

    constructor(id: string, name: string, surname: string, organization: string) {
        this.id = id;
        this.name = name;
        this.surname = surname;
        this.organization = organization;
    }
}
