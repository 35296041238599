import { Empty, Form, Input } from 'antd';
import React, { ReactNode, useEffect, useState } from 'react';
import { ContentComponentParams } from './fields-container';
import { Attendant } from '../models/attendant';
import validatePhone from '../helpers/phone-validator';
import validateEmail from '../helpers/email-validator';
import { BaseFhirModel } from '../models/fhir/base-fhir-model';
import { FhirResourceFriendlyName, FhirResourceName, isFieldValueDifferent } from '../helpers/fhir-message-helper';
import { Patient } from '../models/fhir/patient';
import { Patient as PatientViewModel } from '../models/patient';


export class AttendantComponentParams implements ContentComponentParams {
    panelTitle:string = "Στοιχεία συνοδού";
    initialValues:Attendant[] = [];
    constructor(public patient:PatientViewModel) {}
    getComponents(key:string, isEditMode:boolean, initialValues?:Attendant[], onValueChange?: (itemIndex: number, newValue:Attendant, fhirMessages:BaseFhirModel[], isValid:boolean) => void) : ReactNode  {
        if (!initialValues || initialValues.length === 0) return <Empty />
        return initialValues.map((initialValue, index) => <div key={`${key}-${index}`}><AttendantComponent isEditMode={isEditMode} initialValue={initialValue} patient={this.patient} onValueChange={(newValue, fhirMessages, isValid) => {
            if (onValueChange) onValueChange(index, newValue, fhirMessages, isValid);
        }} />
            {initialValues.length - 1 > index && <hr />}</div>);
    };

};

const AttendantComponent: React.FC<{ isEditMode:boolean, initialValue?:Attendant, patient: PatientViewModel, onValueChange?:(newValue:Attendant, fhirMessages:BaseFhirModel[], isValid:boolean)=>void }> = ({ isEditMode, initialValue, patient, onValueChange }) => {
    const [firstName, setFirstName] = useState<string | undefined>(initialValue?.firstName);
    const [lastName, setLastName] = useState<string | undefined>(initialValue?.lastName);
    const [address, setAddress] = useState<string | undefined>(initialValue?.address);
    const [email1, setEmail1] = useState<string | undefined>(initialValue?.email1);
    const [email2, setEmail2] = useState<string | undefined>(initialValue?.email2);
    const [phone1, setPhone1] = useState<string | undefined>(initialValue?.phone1);
    const [phone2, setPhone2] = useState<string | undefined>(initialValue?.phone2);

    useEffect(() => {
        if (onValueChange) {
            const fhirMessages: BaseFhirModel[] = [];
            if (isFieldValueDifferent(initialValue?.firstName, firstName) ||
                isFieldValueDifferent(initialValue?.lastName, lastName) ||
                isFieldValueDifferent(initialValue?.address, address) ||
                isFieldValueDifferent(initialValue?.phone1, phone1) ||
                isFieldValueDifferent(initialValue?.phone2, phone2) ||
                isFieldValueDifferent(initialValue?.email1, email1) ||
                isFieldValueDifferent(initialValue?.email2, email2)
            ) {
                fhirMessages.push({
                    fhirResourceName:FhirResourceName.patient,
                    friendlyResourceName:FhirResourceFriendlyName.patientAttendant,
                    attendantFirstName:firstName,
                    attendantLastName:lastName,
                    attendantAddress:address,
                    attendantPhone1:phone1,
                    attendantPhone2:phone2,
                    attendantEmail1:email1,
                    attendantEmail2:email2,
                    isOccuredDateVisible:false,
                    occuredDate: undefined,
                } as Patient);
            }
            onValueChange({
                firstName, 
                lastName, 
                address,
                phone1,
                phone2,
                email1,
                email2}, fhirMessages, isFirstNameValid() && isLastNameValid() && isContactValid(email1, email2, phone1, phone2));
        }
    }, [firstName, lastName, address, phone1, phone2, email1, email2]);

    const isFirstNameValid  = () : boolean => {
        if ((lastName || address || phone1 || phone2 || email1 || email2) && !firstName) return false;
        return true;
    }

    const isLastNameValid  = () : boolean => {
        if ((firstName || address || phone1 || phone2 || email1 || email2) && !lastName) return false;
        return true;
    }

    const isContactValid = (email1?:string, email2?:string, phone1?:string, phone2?:string) : boolean => {
        if (email1 && !validateEmail(email1)) return false;
        if (email2 && !validateEmail(email2)) return false;
        if (phone1 && !validatePhone(phone1)) return false;
        if (phone2 && !validatePhone(phone2)) return false;

        return true;
    }

    return (<>
        <Form
            layout='vertical'
            style={{ display: 'flex', flexDirection:'row' }}
            autoComplete="off"
        >
            <div className='patient-fields-column'>
                <Form.Item
                    label="Όνομα"
                    validateStatus={isFirstNameValid() ? 'success':'error'}
                    help={!isFirstNameValid() ? 'Το πεδίο απαιτείται':''}
                >
                    {isEditMode && <Input max={255} value={firstName} onChange={(e)=>setFirstName(e.target.value)}/>}
                    {!isEditMode && <div className='field-value-text'>{firstName ?? "-"}</div>}
                </Form.Item>
                <Form.Item
                    label="Επώνυμο"
                    validateStatus={isLastNameValid() ? 'success':'error'}
                    help={!isLastNameValid() ? 'Το πεδίο απαιτείται':''}
                >
                    {isEditMode && <Input max={255} value={lastName} onChange={(e)=>setLastName(e.target.value)}/>}
                    {!isEditMode && <div className='field-value-text'>{lastName ?? "-"}</div>}
                </Form.Item>
            </div>            
            <div className='patient-fields-column'>
                <Form.Item
                    label="Τηλέφωνο 1"
                    validateStatus={phone1 ? (validatePhone(phone1) ? 'success':'error') : undefined}
                    help={phone1 ? (validatePhone(phone1) ? undefined:'Συμπληρώστε έγκυρο τηλεφωνικό αριθμό') : undefined}
                >
                    {isEditMode && <Input max={255} value={phone1} onChange={(e)=>setPhone1(e.target.value)}/>}
                    {!isEditMode && <div className='field-value-text'>{phone1??"-"}</div>}
                </Form.Item>
                <Form.Item
                    label="Τηλέφωνο 2"
                    validateStatus={phone2 ? (validatePhone(phone2) ? 'success':'error') : undefined}
                    help={phone2 ? (validatePhone(phone2) ? undefined:'Συμπληρώστε έγκυρο τηλεφωνικό αριθμό') : undefined}
                >
                    {isEditMode && <Input max={255} value={phone2} onChange={(e)=>setPhone2(e.target.value)}/>}
                    {!isEditMode && <div className='field-value-text'>{phone2??"-"}</div>}
                </Form.Item>
                <Form.Item
                    label="Email 1"
                    validateStatus={email1 ? (validateEmail(email1) ? 'success':'error') : undefined}
                    help={email1 ? (validateEmail(email1) ? undefined:'Συμπληρώστε έγκυρη ηλεκτρονική διεύθυνση') : undefined}
                >
                    {isEditMode && <Input max={255} value={email1} onChange={(e)=>setEmail1(e.target.value)}/>}
                    {!isEditMode && <div className='field-value-text'>{email1??"-"}</div>}
                </Form.Item>
                <Form.Item
                    label="Email 2"
                    validateStatus={email2 ? (validateEmail(email2) ? 'success':'error') : undefined}
                    help={email2 ? (validateEmail(email2) ? undefined:'Συμπληρώστε έγκυρη ηλεκτρονική διεύθυνση') : undefined}
                >
                    {isEditMode && <Input max={255} value={email2} onChange={(e)=>setEmail2(e.target.value)}/>}
                    {!isEditMode && <div className='field-value-text'>{email2??"-"}</div>}
                </Form.Item>
            </div>            
            <div className='patient-fields-column'>
                <Form.Item
                    label="Διεύθυνση"
                >
                    {isEditMode && <Input.TextArea maxLength={255} rows={5} value={address} onChange={(e=>setAddress(e.target.value))}/>}
                    {!isEditMode && <div className='field-value-text' style={{maxHeight:120, whiteSpace:'pre-wrap', overflowY:'auto'}}>{address??"-"}</div>}
                </Form.Item>
            </div>
                
        </Form>
    </>);
};


export default AttendantComponent;