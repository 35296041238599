import { Button, DatePicker, Divider, Empty, Form, Input, InputNumber, Modal, Select, Tooltip } from 'antd';
import React, { ReactNode, useEffect, useState } from 'react';
import { ContentComponentParams } from './fields-container';
import { PersonalInfo } from '../models/personal-info';
import dayjs from 'dayjs';
import { DATE_FORMAT } from '../constants';
import { CalculatorOutlined } from '@ant-design/icons';
import validatePhone from '../helpers/phone-validator';
import validateEmail from '../helpers/email-validator';
import BmiCalculatorComponent from './bmi-calculator-component';
import PackYearsCalculatorComponent from './pack-years-calculator-component';
import { BaseFhirModel } from '../models/fhir/base-fhir-model';
import { Observation } from '../models/fhir/observation';
import { FhirResourceFriendlyName, FhirResourceName, isFieldValueDifferent } from '../helpers/fhir-message-helper';
import { Patient } from '../models/fhir/patient';
import { Patient as PatientViewModel } from '../models/patient';
import _ from 'lodash';
import TerminologyService from '../services/terminology-service';


export class PersonalInfoComponentParams implements ContentComponentParams {
    panelTitle:string = "Προσωπικά στοιχεία";
    initialValues: PersonalInfo[]=[];
    constructor(public patient:PatientViewModel) {}
    getComponents(key:string, isEditMode:boolean, initialValues?:PersonalInfo[], onValueChange?: (itemIndex: number, newValue:PersonalInfo, fhirMessages:BaseFhirModel[], isValid:boolean) => void) : ReactNode  {
        if (!initialValues || initialValues.length === 0) return <Empty />
        return initialValues.map((initialValue, index) => <div key={`${key}-${index}`}><PersonalInfoComponent isEditMode={isEditMode} initialValue={initialValue} patient={this.patient} onValueChange={(newValue, fhirMessages, isValid) => {
            if (onValueChange) onValueChange(index, newValue, fhirMessages, isValid);
        }} />
            {initialValues.length - 1 > index && <hr />}</div>);
    };
};


const PersonalInfoComponent: React.FC<{ isEditMode:boolean, initialValue?:PersonalInfo, patient:PatientViewModel, onValueChange?:(newValue:PersonalInfo, fhirMessages:BaseFhirModel[], isValid:boolean)=>void }> = ({ isEditMode, initialValue, patient, onValueChange }) => {
    
    const availableNuts2States = _.orderBy(TerminologyService.getValueSetOptions('NUTS2'), x => x.label);
    const availableSmokingStatuses = TerminologyService.getValueSetOptions('SmokingStatus');

    const [district, setDistrict] = useState<string | undefined>(initialValue?.district);
    const [city, setCity] = useState<string | undefined>(initialValue?.city);
    const [address, setAddress] = useState<string | undefined>(initialValue?.address);
    const [email1, setEmail1] = useState<string | undefined>(initialValue?.email1);
    const [email2, setEmail2] = useState<string | undefined>(initialValue?.email2);
    const [phone1, setPhone1] = useState<string | undefined>(initialValue?.phone1);
    const [phone2, setPhone2] = useState<string | undefined>(initialValue?.phone2);

    const [weight, setWeight] = useState<number | undefined>(initialValue?.weight);
    const [weightDate, setWeightDate] = useState<dayjs.Dayjs | undefined>(initialValue?.weightDate ? dayjs(initialValue.weightDate) : undefined);
    const [height, setHeight] = useState<number | undefined>(initialValue?.height);
    const [heightDate, setHeightDate] = useState<dayjs.Dayjs | undefined>(initialValue?.heightDate ? dayjs(initialValue.heightDate): undefined);
    const [bmi, setBmi] = useState<number | undefined>(initialValue?.bmi);
    const [bmiDate, setBmiDate] = useState<dayjs.Dayjs | undefined>(initialValue?.bmiDate ? dayjs(initialValue.bmiDate): undefined);
    const [isBmiCalculatorOpen, setIsBmiCalculatorOpen] = useState<boolean>(false);
    const [calculatedBmi, setCalculatedBmi] = useState<number|undefined>();

    const [smokingStatus, setSmokingStatus] = useState<string | undefined>(initialValue?.smokingStatus);
    const [smokingStartYear, setSmokingStartYear] = useState<number | undefined>(initialValue?.smokingStartYear);
    const [smokingEndYear, setSmokingEndYear] = useState<number | undefined>(initialValue?.smokingEndYear);
    const [packYears, setPackYears] = useState<number | undefined>(initialValue?.packYears);
    const [isPackYearsCalculatorOpen, setIsPackYearsCalculatorOpen] = useState<boolean>(false);
    const [calculatedPackYears, setCalculatedPackYears] = useState<number|undefined>();

    
    useEffect(() => {
        if (weight && !initialValue?.weight) setWeightDate(patient.dateOfDeath ? dayjs(patient.dateOfDeath) : dayjs());
    }, [weight]);

    useEffect(() => {
        if (height && !initialValue?.height) setHeightDate(patient.dateOfDeath ? dayjs(patient.dateOfDeath) : dayjs());
    }, [height]);

    useEffect(() => {
        if (bmi && !initialValue?.bmi) setBmiDate(patient.dateOfDeath ? dayjs(patient.dateOfDeath) : dayjs());
    }, [bmi]);
    
    useEffect(() => {
        if (onValueChange) {
            console.log(initialValue);
            const fhirMessages: BaseFhirModel[] = [];
            if (isFieldValueDifferent(initialValue?.district, district) ||
            isFieldValueDifferent(initialValue?.city, city) ||
                isFieldValueDifferent(initialValue?.address, address) ||
                isFieldValueDifferent(initialValue?.phone1, phone1) ||
                isFieldValueDifferent(initialValue?.phone2, phone2) ||
                isFieldValueDifferent(initialValue?.email1, email1) ||
                isFieldValueDifferent(initialValue?.email2, email2)
            ) {
                fhirMessages.push({
                    fhirResourceName:FhirResourceName.patient,
                    friendlyResourceName:FhirResourceFriendlyName.patientDemographicsAndContact,
                    address:address,
                    districtType:district,
                    friendlyDistrict:district ? availableNuts2States.find(s=>s.value === district)?.label:undefined,
                    city,
                    phone1:phone1,
                    phone2:phone2,
                    email1:email1,
                    email2:email2,
                    isOccuredDateVisible:false,
                    occuredDate: undefined,
                } as Patient);
            }

            if (isFieldValueDifferent(initialValue?.weight, weight) ||
                isFieldValueDifferent(initialValue?.weightDate, weightDate?weightDate.toDate():undefined)) {
                    fhirMessages.push({
                        fhirResourceName:FhirResourceName.observation,
                        friendlyResourceName:FhirResourceFriendlyName.weight,
                        value:weight,
                        effectiveStartDate:weightDate ? weightDate.toDate() : undefined,
                        isOccuredDateVisible:false,
                        occuredDate:weightDate ? weightDate.toDate() : undefined,
                    } as Observation);
            }
            if (isFieldValueDifferent(initialValue?.height, height) ||
                isFieldValueDifferent(initialValue?.heightDate, heightDate?heightDate.toDate():undefined)) {
                    fhirMessages.push({
                        fhirResourceName:FhirResourceName.observation,
                        friendlyResourceName:FhirResourceFriendlyName.height,
                        value:height,
                        effectiveStartDate:heightDate ? heightDate.toDate() : undefined,
                        isOccuredDateVisible:false,
                        occuredDate:heightDate ? heightDate.toDate() : undefined,
                    } as Observation);
            }
            if (isFieldValueDifferent(initialValue?.bmi, bmi) ||
                isFieldValueDifferent(initialValue?.bmiDate, bmiDate?bmiDate.toDate():undefined)) {
                    fhirMessages.push({
                        fhirResourceName:FhirResourceName.observation,
                        friendlyResourceName:FhirResourceFriendlyName.bmi,
                        value:bmi ? bmi.toFixed(2) : undefined,
                        effectiveStartDate:bmiDate ? bmiDate.toDate() : undefined,
                        isOccuredDateVisible:false,
                        occuredDate:bmiDate ? bmiDate.toDate() : undefined,
                    } as Observation);
            }
            if (isFieldValueDifferent(initialValue?.smokingStatus, smokingStatus) ||
                isFieldValueDifferent(initialValue?.smokingStartYear, smokingStartYear) ||
                isFieldValueDifferent(initialValue?.smokingEndYear, smokingEndYear)) {
                fhirMessages.push({
                    fhirResourceName:FhirResourceName.observation,
                    friendlyResourceName:FhirResourceFriendlyName.smokingStatus,
                    value:smokingStatus,
                    friendlyValue:smokingStatus ? availableSmokingStatuses.find(ss=>ss.value === smokingStatus)?.label : undefined,
                    effectiveStartDate:smokingStartYear ? dayjs().startOf('year').set('year', smokingStartYear) : undefined,
                    effectiveEndDate:smokingEndYear ? dayjs().endOf('year').set('year', smokingEndYear) : undefined,
                    isOccuredDateVisible:false,
                    occuredDate:smokingStartYear ? dayjs().startOf('year').set('year', smokingStartYear) : smokingEndYear ? dayjs().endOf('year').set('year', smokingEndYear) : undefined,
                } as Observation);
            if (isFieldValueDifferent(initialValue?.packYears, packYears)) {
                fhirMessages.push({
                    fhirResourceName:FhirResourceName.observation,
                    friendlyResourceName:FhirResourceFriendlyName.packYears,
                    value:packYears ? packYears.toFixed(2): undefined,
                    isOccuredDateVisible:false,
                    effectiveStartDate:smokingStartYear ? dayjs().startOf('year').set('year', smokingStartYear) : undefined,
                    effectiveEndDate:smokingEndYear ? dayjs().endOf('year').set('year', smokingEndYear) : undefined,
                    occuredDate:smokingStartYear ? dayjs().startOf('year').set('year', smokingStartYear) : smokingEndYear ? dayjs().endOf('year').set('year', smokingEndYear) : undefined,
                } as Observation);
            }
        }

            onValueChange({
                district,
                city,
                address,
                email1,
                email2, 
                phone1, 
                phone2, 
                weight, 
                weightDate: weightDate ? weightDate.toDate(): undefined, 
                height, 
                heightDate: heightDate ? heightDate.toDate() : undefined, 
                bmi,
                bmiDate: bmiDate ? bmiDate.toDate() : undefined, 
                smokingStatus, 
                smokingStartYear, 
                smokingEndYear, 
                packYears, 
                diabetis: initialValue?.diabetis,
            },fhirMessages, isValid(email1, email2, phone1, phone2));
        }
    }, [district, city, address, email1, email2, phone1, phone2, weight, weightDate, height, heightDate, bmi, bmiDate, smokingStatus, smokingStartYear, smokingEndYear, packYears]);

    const isValid = (email1?:string, email2?:string, phone1?:string, phone2?:string) : boolean => {
        if (email1 && !validateEmail(email1)) return false;
        if (email2 && !validateEmail(email2)) return false;
        if (phone1 && !validatePhone(phone1)) return false;
        if (phone2 && !validatePhone(phone2)) return false;

        return true;
    }
    
    return (<>
        <Divider orientation="left">Στοιχεία επικοινωνίας</Divider>
        <Form
            layout='vertical'
            style={{ display: 'flex', flexDirection:'row' }}
            autoComplete="off"
        >
                <div className='patient-fields-column'>
                    <Form.Item
                        label="Περιοχή (κατά την πρώτη διάγνωση)"
                    >
                        {isEditMode && <Select options={availableNuts2States} placeholder="Επιλέξτε περιοχή" allowClear={true} value={district} onChange={(value) => {
                                    setDistrict(value);
                                }}/>}
                        {!isEditMode && <div className='field-value-text'>{district ? availableNuts2States.find(ss=>ss.value === district)?.label ?? '-' : '-' }</div>}
                    </Form.Item>
                    <Form.Item
                        label="Πόλη"
                    >
                        {isEditMode && <Input max={255} value={city} onChange={(e=>setCity(e.target.value))}/>}
                        {!isEditMode && <div className='field-value-text' >{city??"-"}</div>}
                    </Form.Item>
                    <Form.Item
                        label="Διεύθυνση"
                    >
                        {isEditMode && <Input.TextArea maxLength={255} rows={1} value={address} onChange={(e=>setAddress(e.target.value))}/>}
                        {!isEditMode && <div className='field-value-text' style={{maxHeight:120, whiteSpace:'pre-wrap', overflowY:'auto'}}>{address??"-"}</div>}
                    </Form.Item>
                </div>
                <div className='patient-fields-column'>
                    <Form.Item
                        label="Τηλέφωνο 1"
                        validateStatus={phone1 ? (validatePhone(phone1) ? 'success':'error') : undefined}
                        help={phone1 ? (validatePhone(phone1) ? undefined:'Συμπληρώστε έγκυρο τηλεφωνικό αριθμό') : undefined}
                    >
                        {isEditMode && <Input max={255} value={phone1} onChange={(e)=>setPhone1(e.target.value)}/>}
                        {!isEditMode && <div className='field-value-text'>{phone1??'-'}</div>}
                    </Form.Item>
                    <Form.Item
                        label="Τηλέφωνο 2"
                        validateStatus={phone2 ? (validatePhone(phone2) ? 'success':'error') : undefined}
                        help={phone2 ? (validatePhone(phone2) ? undefined:'Συμπληρώστε έγκυρο τηλεφωνικό αριθμό') : undefined}
                    >
                        {isEditMode && <Input max={255} value={phone2} onChange={(e)=>setPhone2(e.target.value)}/>}
                        {!isEditMode && <div className='field-value-text'>{phone2??'-'}</div>}
                    </Form.Item>
                </div>
                <div className='patient-fields-column'>
                    <Form.Item
                        label="Email 1"
                        validateStatus={email1 ? (validateEmail(email1) ? 'success':'error') : undefined}
                        help={email1 ? (validateEmail(email1) ? undefined:'Συμπληρώστε έκγυρη ηλεκτρονική διεύθυνση') : undefined}
                    >
                        {isEditMode && <Input max={255} value={email1} onChange={(e)=>setEmail1(e.target.value)}/>}
                        {!isEditMode && <div className='field-value-text'>{email1??'-'}</div>}
                    </Form.Item>
                    <Form.Item
                        label="Email 2"
                        validateStatus={email2 ? (validateEmail(email2) ? 'success':'error') : undefined}
                        help={email2 ? (validateEmail(email2) ? undefined:'Συμπληρώστε έκγυρη ηλεκτρονική διεύθυνση') : undefined}
                    >
                        {isEditMode && <Input max={255} value={email2} onChange={(e)=>setEmail2(e.target.value)}/>}
                        {!isEditMode && <div className='field-value-text'>{email2??'-'}</div>}
                    </Form.Item>
                </div>            
        </Form>

        <Divider orientation="left">Σωματομετρικά</Divider>
        <Form
            layout='vertical'
            style={{ display: 'flex', flexDirection:'row' }}
            autoComplete="off"
        >
            <div className='patient-fields-column'>
                <Form.Item
                    label="Βάρος (kg)"
                >
                    {isEditMode && <InputNumber min={0} max={300} precision={0} value={weight} onChange={newValue=>setWeight(newValue!)} suffix='kg'/>}
                    {!isEditMode && <div className='field-value-text'>{weight === undefined ? '-' : weight}</div>}
                </Form.Item>
                <Form.Item
                    label="Ημερομηνία καταγραφής βάρους"
                >
                    {isEditMode && <DatePicker disabledDate={(current)=>patient.dateOfDeath ? current.isAfter(dayjs(patient.dateOfDeath)): current.isAfter(dayjs())} format={DATE_FORMAT} value={weightDate} allowClear={true} onChange={(newDate=>setWeightDate(newDate))}></DatePicker>}
                    {!isEditMode && <div className='field-value-text'>{weightDate ? weightDate.format(DATE_FORMAT) : "-"}</div>}
                </Form.Item>
            </div>
            <div className='patient-fields-column'>
                <Form.Item
                    label="Ύψος (cm)"
                >
                    {isEditMode && <InputNumber min={0} max={250} precision={0} value={height} onChange={newValue=>setHeight(newValue!)} suffix='cm' />}
                    {!isEditMode && <div className='field-value-text'>{height === undefined ? '-' : height}</div>}
                </Form.Item>
                <Form.Item
                    label="Ημερομηνία καταγραφής ύψους"
                >
                    {isEditMode && <DatePicker disabledDate={(current)=>patient.dateOfDeath ? current.isAfter(dayjs(patient.dateOfDeath)): current.isAfter(dayjs())} format={DATE_FORMAT} value={heightDate} allowClear={true} onChange={(newDate=>setHeightDate(newDate))}></DatePicker>}
                    {!isEditMode && <div className='field-value-text'>{heightDate ? heightDate.format(DATE_FORMAT) : "-"}</div>}
                </Form.Item>
            </div>            
            <div className='patient-fields-column'>
                <Form.Item
                    label={<span>BMI (<span>kg/m<sup>2</sup></span>)</span>}
                >
                    {isEditMode && <>
                        <InputNumber key="field" style={{width:150}} min={0} max={300} precision={2} value={bmi}  onChange={(e)=>setBmi(e??undefined)} suffix={<span>kg/m<sup>2</sup></span>}/>
                        <Tooltip key="button" title='Πατήστε για υπολογισμό από το συμπληρωμένο βάρος και ύψος'><Button size='small' style={{marginLeft:5}} className='inline' icon={<CalculatorOutlined/>} disabled={!weight || !height} onClick={()=>setIsBmiCalculatorOpen(true)}></Button></Tooltip>
                    </>} 
                    {!isEditMode && <div className='field-value-text'>{bmi === undefined ? '-' : bmi.toFixed(2)}</div>}
                </Form.Item>
                <Form.Item
                    label="Ημερομηνία καταγραφής ΒΜΙ"
                >
                    {isEditMode && <DatePicker disabledDate={(current)=>patient.dateOfDeath ? current.isAfter(dayjs(patient.dateOfDeath)): current.isAfter(dayjs())} format={DATE_FORMAT} value={bmiDate} allowClear={true} onChange={(newDate=>setBmiDate(newDate))}></DatePicker>}
                    {!isEditMode && <div className='field-value-text'>{bmiDate ? bmiDate.format(DATE_FORMAT) : "-"}</div>}
                </Form.Item>

                <Modal 
                    open={isBmiCalculatorOpen} 
                    title="Υπολογισμός BMI" 
                    closable={false}
                    destroyOnClose={true}
                    okText='Ενημέρωση BMI'
                    cancelText='Άκυρο'
                    onOk={()=>{
                        setBmi(calculatedBmi);
                        setIsBmiCalculatorOpen(false);
                    }}
                    onCancel={()=>setIsBmiCalculatorOpen(false)}
                    >
                    <BmiCalculatorComponent initialHeightInCm={height} initialWeightInKg={weight} onValueChange={(result)=>{
                        setCalculatedBmi(result);}}/>
                </Modal>
            </div>
                
        </Form>

        <Divider orientation="left">Κάπνισμα</Divider>
        <Form
            layout='vertical'
            style={{ display: 'flex', flexDirection:'row' }}
            autoComplete="off"
        >
            <div className='patient-fields-column'>
                <Form.Item
                    label="Καπνιστής"
                >
                    {isEditMode && <Select options={availableSmokingStatuses} allowClear={true} value={smokingStatus} onChange={(value) => {
                                setSmokingStatus(value);
                                if (value === "1" || value === undefined) {
                                    setSmokingStartYear(undefined);
                                    setSmokingEndYear(undefined);
                                    setPackYears(undefined);
                                }
                                
                            }}/>}
                    {!isEditMode && <div className='field-value-text'>{smokingStatus ? availableSmokingStatuses.find(ss=>ss.value === smokingStatus)?.label ?? '-' : '-' }</div>}
                </Form.Item>
            </div>
            <div className='patient-fields-column'>
                <Form.Item
                    label="Έναρξη καπνίσματος (Έτος)"
                >
                    {isEditMode && <InputNumber disabled={!smokingStatus || smokingStatus === "SmokingStatus.001"} min={1900} max={2100} precision={0} value={smokingStartYear} onChange={newValue=>setSmokingStartYear(newValue!)} />}
                    {!isEditMode && <div className='field-value-text'>{smokingStartYear === undefined ? '-' : smokingStartYear}</div>}
                </Form.Item>

                <Form.Item
                    label="Διακοπή καπνίσματος (Έτος)"
                    validateStatus={smokingStartYear && smokingEndYear && smokingEndYear < smokingStartYear ? 'error' : undefined}
                    help={smokingStartYear && smokingEndYear && smokingEndYear < smokingStartYear ? 'Το έτος διακοπής καπνίσματος πρέπει να έπεται του έτους έναρξης' : undefined}
                >
                    {isEditMode && <InputNumber disabled={!smokingStatus || smokingStatus === "SmokingStatus.001"} min={1900} max={2100} precision={0} value={smokingEndYear} onChange={newValue=>setSmokingEndYear(newValue!)} />}
                    {!isEditMode && <div className='field-value-text'>{smokingEndYear === undefined ? '-' : smokingEndYear}</div>}
                </Form.Item>
            </div>
            <div className='patient-fields-column'>
                <Form.Item
                    label="Pack years"
                >
                    {isEditMode && <>
                        <InputNumber key="field" style={{width:150}} disabled={!smokingStatus || smokingStatus === "SmokingStatus.001"} min={0} max={1000} precision={2} value={packYears} onChange={newValue=>setPackYears(newValue!)} />
                        <Tooltip key="button" title='Πατήστε για υπολογισμό'><Button size='small' style={{marginLeft:5}} className='inline' icon={<CalculatorOutlined/>} disabled={!smokingStatus || smokingStatus === "SmokingStatus.001"} onClick={()=>setIsPackYearsCalculatorOpen(true)}></Button></Tooltip>
                    </>} 
                    {!isEditMode && <div className='field-value-text'>{packYears === undefined ? '-' : packYears}</div>}
                </Form.Item>

                <Modal 
                    open={isPackYearsCalculatorOpen} 
                    title="Υπολογισμός Pack years" 
                    closable={false}
                    destroyOnClose={true}
                    okText='Ενημέρωση Pack years'
                    cancelText='Άκυρο'
                    onOk={()=>{
                        setPackYears(calculatedPackYears);
                        setIsPackYearsCalculatorOpen(false);
                    }}
                    width={800}
                    onCancel={()=>setIsPackYearsCalculatorOpen(false)}
                    >
                    <PackYearsCalculatorComponent onValueChange={(result)=>{
                        setCalculatedPackYears(result);}}/>
                </Modal>
            </div>
        </Form>

        <Divider orientation="left">Διαβήτης</Divider>
        <Form
            layout='vertical'
            style={{ display: 'flex', flexDirection:'row' }}
            autoComplete="off"
        >
            <div className='patient-fields-column'>
            <Form.Item
                    label="Σακχαρώδης διαβήτης"
                >
                    <div className='field-value-text'>{initialValue?.diabetis ?? '-'}</div>
                </Form.Item>
                </div>
            
        </Form>
    </>);
};


export default PersonalInfoComponent;