import { Button, Result } from 'antd';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function GeneralErrorPage() {
    const query = useQuery();
    const error_code = query.get("error_code") ?? "general";
    const errorMessage = ErrorCodes[error_code] ?? "Παρουσιάστηκε πρόβλημα στην εφαρμογή";

    const [isLoading, setIsLoading] = useState(true);
    const [logoutUrl, setLogoutUrl] = useState<string|undefined>(undefined);

    useEffect(()=> {
        setIsLoading(true);
        axios.get('/api/oauth/auth-urls').then(response => {
          const data :any = response.data;
          setLogoutUrl(data.logoutUrl);
          setIsLoading(false);
        });
    }, []);
    
    return (
        <div className='intro_page'> 
            <Result status='error' title={errorMessage} extra={
              <Button type="primary" loading={isLoading} disabled={isLoading || !logoutUrl} onClick={()=>{
                window.location.href = logoutUrl!;
              }}>Αποσύνδεση</Button>
            }/>
        </div>
    );
}

const ErrorCodes : {[key:string]: string} = {
  "auth":"Πρόβλημα αυθεντικοποίησης",
  "specialty":"Δεν σας έχει αποδοθεί ειδικότητα με δυνατότητα πρόσβασης στο μητρώο",
  "organizations":"Δεν έχετε συσχετιστεί με μονάδες παροχής υπηρεσιών υγείας",
  "general":"Παρουσιάστηκε πρόβλημα στην εφαρμογή"
}

export default GeneralErrorPage;