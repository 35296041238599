import { Button, Layout, Space, Statistic, StatisticProps, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import AuthService, { LoginErrorCodes, user$ } from '../services/auth-service';
import dayjs from 'dayjs';
import { DATE_FORMAT } from '../constants';


function ProfilePage() {
    const navigate = useNavigate();
    const currentUser = AuthService.getInstance().getCurrentUser();
    const orgsFormatter:StatisticProps['formatter'] = (value:any) => {
        if ((value as string).indexOf('@') <0) return <>{value}</>;
        return <div>{(value as string).split('|').map(compOrg => {
            const orgParts = compOrg.split('@');
            return {value:orgParts[0], label:orgParts[1]};
        }).map((org,index)=> <div key={index}>{org.label} <Button type='link' size='small' onClick={()=>navigate(`/organizations/${org.value}`)}>Λεπτομέρειες</Button></div>)}</div>;
      };
    return (
        <>
            <Layout className='ant-layout-page'>
                <Layout.Header className='hipaheader2 ant-layout-page-header'>
                    <Space className='personal_details_wrapper'>
                        <h1>{currentUser.name}</h1>
                        <h2><Typography.Text type='secondary'>AMKA: {currentUser.idika_amka}</Typography.Text></h2>
                    </Space>
                    <div style={{flex:"1 auto"}}></div>
                </Layout.Header>
                <Layout.Content className='ant-layout-page-content'>
                    <div className='header_information_row'>
                        <Statistic title="Username (ηλ. συνταγογράφηση)" value={currentUser.idika_username} />
                        <Statistic title="Ειδικότητα" style={{whiteSpace:'pre'}} value={currentUser.specialty + (currentUser.is_trainee ? '\n(Ειδικευόμενος)': '')} />
                        <Statistic title="Έναρξη χρήσης ΕΜΝΝ"  value={dayjs(currentUser.created_at).format(DATE_FORMAT)} />
                        <Statistic title={[<span key='title'>Μονάδες υγείας</span>,<span key='link' style={{marginLeft:10}}><Button size='small' disabled={!currentUser.organizations || !currentUser.organizations.length} type='link' onClick={()=>navigate("/active-organization")}>Ενεργή μονάδα</Button></span>]}  formatter={orgsFormatter} value={(currentUser.organizations && currentUser.organizations.length) ? currentUser.organizations.map(org=>`${org.id}@${org.name}`).join('|') : "Δεν έχετε ενταχθεί σε μονάδα υγείας."}/>
                    </div>


                </Layout.Content>
            </Layout>
        </>
    );
}

export default ProfilePage;