import { Avatar, Empty, List, Spin } from "antd";
import { useEffect, useState } from "react";
import FhirService from "../services/fhir-service";
import { of } from "rxjs";
import FieldMapperService from "../services/field-mapper-service";
import { groupBy } from "lodash";

interface DoctorWithMultipleOrgs {
    id: string;
    name: string;
    surname: string;
    organizations: string;
}
export const PatientDoctorsComponent: React.FC<{patientId: string|undefined|null}> = ({patientId}) => {
    const fieldMapperService = new FieldMapperService();
    const fhirService = new FhirService();

    const [doctors, setDoctors] = useState([] as DoctorWithMultipleOrgs[]);
    const [isLoading, setIsLoading] = useState(false);
    
    useEffect(() => {
        setIsLoading(true);
        const perf$ = patientId
            ? fhirService.getPatientDistinctPerformers(patientId!)
            : of([]);
            
        perf$.subscribe({
            next(value) {
                setIsLoading(false);
                
                const docs = fieldMapperService.mapPatientDoctorBundle(value);
                const docGroups = Object.values(groupBy(docs, d=>d.id)).map(dg => {
                    return {id:dg[0].id, name:dg[0].name, surname:dg[0].surname, organizations:dg.map(d=>d.organization).join(', ') } as DoctorWithMultipleOrgs;
                })
                setDoctors(docGroups);
            },
            error(err) {
                setIsLoading(false);
                setDoctors([]);
            },
        });
    }, [patientId]);


    return (
        <Spin spinning={isLoading}>
            <h3>Θεράποντες ιατροί</h3>
            {!isLoading && !patientId
            ? <Empty />
            : <List>
                {doctors?.map((x, ix) => (
                    <List.Item key={ix}>
                        <List.Item.Meta
                            avatar={<Avatar shape="square" size={50}>{ix+1}</Avatar>}
                            title={x.surname + ' ' + x.name}
                            description={x.organizations}
                        />
                    </List.Item>
                ))}
                </List>
            }
        </Spin>
    )
}
