import dayjs from "dayjs";
import TerminologyService from "../services/terminology-service";
import { BaseEditableModel } from "./base-editable-model";
import { DATE_FORMAT } from "../constants";

export class Therapy implements BaseEditableModel {
    readonly id: string|null;
    readonly conditionId: string;

    therapyDate?:Date;
    therapyKind?:string;

    radioTherapyType?:string;
    chemoTherapyType?:string;
    otherTherapyType?:string;
    
    surgeryEtipCode?:string;
    // surgeryStagingId?:string;
    surgeryLimitsType?:string;
    surgeryNumberOfExcludedLymphNodes?:number;
    surgeryNumberOfInfiltratedLymphNodes?:number;
    surgeryLymphInfiltrationExistenceType?:string;
    surgeryNevreFiltrationExistenceType?:string;
    
    constructor(id: string|null, conditionId: string) {
        this.id = id;
        this.conditionId = conditionId;
    }

    private isSurgery? = (): boolean=> {
        return (this.therapyKind === 'TTFN.SOL.001' || this.therapyKind === 'TTFN.HEM.001');
    }

    private isRadioTherapy? = (): boolean=> {
        return (this.therapyKind === 'TTFN.SOL.006' || this.therapyKind === 'TTFN.HEM.005');
    }

    private isChemoTherapy? = ():boolean=> {
        return (this.therapyKind === 'TTFN.SOL.002' || this.therapyKind === 'TTFN.SOL.012' || this.therapyKind === 'TTFN.HEM.002');
    }

    public toString? = ():string => [
        `Είδος θεραπείας: ${TerminologyService.getValueSetOption('TreatmentTypeForNeoplasm', this.therapyKind!)?.label!}`, 
        `Ημερομηνία Έναρξης Θεραπείας: ${this.therapyDate ? dayjs(this.therapyDate).format(DATE_FORMAT) : '-'}`,
        this.isSurgery!() ? `Κωδικός πράξης χειρουργείου: ${this.surgeryEtipCode ? TerminologyService.getValueSetOption('SurgeryTypeETIPCode', this.surgeryEtipCode!)?.label! ?? '-':'-'}` : undefined,
        this.isSurgery!() ? `Χειρουργικά όρια: ${this.surgeryLimitsType ? TerminologyService.getValueSetOption('SurgeryLimits', this.surgeryLimitsType!)?.label! ?? '-':'-'}` : undefined,
        this.isSurgery!() ? `Συνολικός αριθμός εξαιρεθέντων λεμφαδένων: ${this.surgeryNumberOfExcludedLymphNodes !== undefined ? this.surgeryNumberOfExcludedLymphNodes : '-'}`:undefined,
        this.isSurgery!() ? `Αριθμός διηθημένων λεμφαδένων και η εντόπιση αυτών: ${this.surgeryNumberOfInfiltratedLymphNodes !== undefined ? this.surgeryNumberOfInfiltratedLymphNodes : '-'}`:undefined,
        this.isSurgery!() ? `Παρουσία εξωκαψικής λεμφαδενικής διήθησης: ${this.surgeryLymphInfiltrationExistenceType ? TerminologyService.getValueSetOption('BooleanValueSet', this.surgeryLymphInfiltrationExistenceType!)?.label! ?? '-':'-'}` : undefined,
        this.isSurgery!() ? `Παρουσία περινευριδιακής διήθησης: ${this.surgeryNevreFiltrationExistenceType ? TerminologyService.getValueSetOption('BooleanValueSet', this.surgeryNevreFiltrationExistenceType!)?.label! ?? '-':'-'}` : undefined,
        this.isRadioTherapy!() ? `Radiotherapy: ${this.radioTherapyType ? TerminologyService.getValueSetOption('RadiotherapyType', this.radioTherapyType!)?.label! ?? '-':'-'}` : undefined,
        this.isChemoTherapy!() ? `Είδος ΧΜΘ: ${this.chemoTherapyType ? TerminologyService.getValueSetOption('ChemotherapyType', this.chemoTherapyType!)?.label! ?? '-':'-'}` : undefined,
        this.otherTherapyType ? `Therapy Specification: ${TerminologyService.getValueSetOption('UnspecifiedSystemicTherapyType', this.otherTherapyType!)?.label! ?? '-'}`: undefined
    ].filter(s=>s).join("\r\n");
}
