import dayjs from "dayjs";
import TerminologyService from "../services/terminology-service";
import { DATE_FORMAT } from "../constants";

export class ObservationWireData {
    id?: string;
    patientId?: string;
    conditionId?: string;
    code?: string;

    valueQuantity?: number;
    valueBoolean?: boolean;
    valueInteger?: number;
    valueCode?: string;
    valueCodeSystem?: string;

    effectiveDateTime?: Date;
    effectiveDateTimeEnd?: Date;

    components?: {
        code: string,
        valueSystem: string,
        valueCode: string,
    }[];
    
    issuedDate?: Date;

    public getValue() {
        if(this.valueQuantity !== undefined) return +this.valueQuantity;
        if(this.valueBoolean !== undefined) return this.valueBoolean;
        if(this.valueCode !== undefined) return this.valueCode;
        return undefined;
    }

    public toString? = ():string => {
        const result = [];
        result.push(`Ημερ. καταγραφής: ${this.issuedDate ? dayjs(this.issuedDate).format(DATE_FORMAT): '-'}`); 
        if (this.code === "Weight") result.push( `Βάρος: ${this.getValue() ?? '-'} kg`);
        if (this.code === "Height") result.push( `Ύψος: ${this.getValue() ?? '-'} cm`);
        if (this.code === "BMI") result.push( `BMI: ${this.getValue() ? (this.getValue() as Number).toFixed(2) : '-'} kg/m^2`);
        if (this.code === "SmokingStatus") result.push( [`Καπνιστής: ${this.getValue() ? TerminologyService.getValueSetOption('SmokingStatus',this.getValue() as string)?.label : '-'}`, `Έναρξη καπνίσματος (έτος): ${this.effectiveDateTime ? dayjs(this.effectiveDateTime).year():'-'}`, `Διακοπή καπνίσματος (έτος): ${this.effectiveDateTimeEnd ? dayjs(this.effectiveDateTimeEnd).year():'-'}`].filter(s=>s).join("\r\n"));
        if (this.code === "SmokingPackYears") result.push( `Pack years: ${this.getValue() ?? '-'}`);
        
        if (this.code === "ExtramedularDiseaseOfHematologicalNeoplasm") result.push( `Εξωμυελική νόσος: ${TerminologyService.getValueSetOption('BooleanValueSet',this.getValue() as string)?.label ?? '-'}`);
        if (this.code === "CytogeneticTestOfHematologicalNeoplasm") result.push( `Κυτταρογενετικός έλεγχος: ${TerminologyService.getValueSetOption('CytogeneticTestOfHematologicalNeoplasm',this.getValue() as string)?.label ?? '-'}`);
        if (this.code === "SolidTumorHistologicalGrade") result.push( `Ιστολογικός βαθμός κακοήθειας συμπαγούς όγκου: ${TerminologyService.getValueSetOption('SolidTumorHistologicalGrade',this.getValue() as string)?.label ?? '-'}`);
        if (this.code === "RenalNuclearFuhrmanGrade") result.push( `Πυρηνικός Βαθμός Κακοήθειας (κατά Fuhrman): ${TerminologyService.getValueSetOption('RenalNuclearFuhrmanGrade',this.getValue() as string)?.label ?? '-'}`);
        if (this.code === "RenalNuclearIsupGrade") result.push( `Πυρηνικός Βαθμός Κακοήθειας (κατά ISUP): ${TerminologyService.getValueSetOption('RenalNuclearIsupGrade',this.getValue() as string)?.label ?? '-'}`);
        if (this.code === "ProstateGleasonGrowth") result.push( `Ιστολογικό πρότυπο ανάπτυξης κατά Gleason: ${TerminologyService.getValueSetOption('ProstateGleasonGrowth',this.getValue() as string)?.label ?? '-'}`);
        if (this.code === "ProstateGleasonScore") result.push( `Gleason Score: ${TerminologyService.getValueSetOption('ProstateGleasonScore',this.getValue() as string)?.label ?? '-'}`);
        if (this.code === "ProstateGradeGroup") result.push( `Grade Group: ${TerminologyService.getValueSetOption('ProstateGradeGroup',this.getValue() as string)?.label ?? '-'}`);
        if (this.code === "ProstaticIntraepithelialNeoplasiaGrade") result.push( `Υψηλόβαθμο PIN: ${TerminologyService.getValueSetOption('BooleanValueSet',this.getValue() as string)?.label ?? '-'}`);
        if (this.code === "Comorbidity_HepatitisOrCirrhosis") result.push( `Συνύπαρξη ηπατίδας ή κίρρωσης: ${TerminologyService.getValueSetOption('BooleanValueSet',this.getValue() as string)?.label ?? '-'}`);
        if (this.code === "Comorbidity_HashimotoThyreoiditis") result.push( `Συνύπαρξη θυρεοειδίτιδας Hashimoto: ${TerminologyService.getValueSetOption('BooleanValueSet',this.getValue() as string)?.label ?? '-'}`);
        if (this.code === "Comorbidity_HPV") result.push( `Συνύπαρξη HPV αλλοιώσεων: ${TerminologyService.getValueSetOption('BooleanValueSet',this.getValue() as string)?.label ?? '-'}`);
        if (this.code === "areIntraepithelialLesionsPresent") result.push( `Συνύπαρξη ενδοεπιθηλιακών βλαβών (LGSIL/HGSIL): ${TerminologyService.getValueSetOption('BooleanValueSet',this.getValue() as string)?.label ?? '-'}`);
        if (this.code === "MelanomaGrowthType") result.push( `Τρόπος ανάπτυξης μελανώματος: ${TerminologyService.getValueSetOption('MelanomaGrowthType',this.getValue() as string)?.label ?? '-'}`);
        if (this.code === "MelanomaClarkStage") result.push( `Στάδιο κατά Clark: ${TerminologyService.getValueSetOption('MelanomaClarkStage',this.getValue() as string)?.label ?? '-'}`);
        if (this.code === "MelanomaBreslowGrowth") result.push( `Ανάπτυξη κατά Breslow: ${this.getValue() ?? '-'}`);
        if (this.code === "MelanomaHadPreexistingMole") result.push( `Παρουσία προϋπάρχοντος σπίλου: ${TerminologyService.getValueSetOption('BooleanValueSet',this.getValue() as string)?.label ?? '-'}`);

        if (this.code === "pNeoplasmAjccStaging") result.push( ``);
        if (this.code === "cNeoplasmAjccStaging") result.push( ``);
        
        return result.join("\r\n");
    }
    
}