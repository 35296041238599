import { Alert, Button, Layout, Modal, Skeleton, Space, Spin, Statistic, Typography, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import DiagnosisComponent from '../components/diagnosis-component';
import PersonSecondaryHeaderCompoment from '../components/person-secondary-header-component';
import { Diagnosis } from '../models/diagnosis';
import FhirService from '../services/fhir-service';
import ObjectOperationsService from '../services/object-operations-service';
import { finalize } from 'rxjs';
import { SERVER_DATE_FORMAT } from '../constants';
import FieldMapperService from '../services/field-mapper-service';
import { Patient } from '../models/patient';
import AuthService from '../services/auth-service';
import { QcsToolResponse } from '../models/qcs-tool-response';

function AddCitizenToRegistryPage() {
    const navigate = useNavigate();
    let { amka } = useParams();
    const authService = AuthService.getInstance();
    const fhirService = new FhirService();
    const fieldMapperService = new FieldMapperService();
    const objectOperationsService = new ObjectOperationsService();

    const [isFetchingCitizen, setIsFetchingCitizen] = useState<boolean>(true);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [isDiagnosisValid, setIsDiagnosisValid] = useState<boolean>(false);
    const [condition, setCondition] = useState<Diagnosis>({} as Diagnosis);

    const [birthDate, setBirthDate] = useState<Date|null>();
    const [deceasedDate, setDeceasedDate] = useState<Date|null>(null);
    const [fullName, setFullName] = useState<string>('');
    const [gender, setGender] = useState<string>('');
    const [citizenship, setCitizenship] = useState<string>('');
    const [causeOfDeath, setCauseOfDeath] = useState<string|null|undefined>(undefined);
    const [mothersName, setMothersName] = useState<string>('');
    const [fathersName, setFathersName] = useState<string>('');

    useEffect(()=> {
        if (!authService.getActiveOrganization()) {
            navigate('/active-organization');
            return;
        }
        setIsFetchingCitizen(true);
        const obs$ = fhirService.getPatientByAmka(amka!);
        obs$.subscribe({
            next: async (data) => {
                // if patient already in registry navigate directly to /patients
                navigate(`/patients/${amka}`);
            },
            error: (err) => {
                if(err.statusCode === 404){
                    // Expected.
                    fhirService.getEPrescriptionPatientByAmka(amka!).subscribe({
                        next: (data) => {
                            const patientFhir = data;
                            const patient = fieldMapperService.mapPatientFromFhir(patientFhir);
                            console.log(patient);
                            setFullName(patient.lastName + ' ' + patient.firstName);
                            setBirthDate(patient.dateOfBirth);
                            setDeceasedDate(patient.dateOfDeath);
                            setGender(patient.gender ?? '');
                            setCitizenship(patient.citizenship ?? '');
                            setCauseOfDeath(patient.causeOfDeath);
                            setMothersName(objectOperationsService.getPropertyByPath(patientFhir, 'link[other.identifier.type.coding.0.code=mother].0.other.display')?.propValue ?? '');
                            setFathersName(objectOperationsService.getPropertyByPath(patientFhir, 'link[other.identifier.type.coding.0.code=father].0.other.display')?.propValue ?? '');
                            setIsFetchingCitizen(false);
                        },
                        error: async (err) => {
                            if (err.statusCode === 451) {
                                notification.error({message:`Δεν σας έχει ανατεθεί η ευθύνη καταχώρισης του ασθενούς με ΑΜΚΑ: ${amka}`});
                                navigate("/amka-search");
                            } else {
                                notification.error({message:`Αποτυχία ανάσυρσης ασθενή με ΑΜΚΑ: ${amka}`});
                                setIsFetchingCitizen(false);
                            }
                        }
                    });
                }
                 else {
                    notification.error({message:`Αποτυχία ανάσυρσης ασθενή με ΑΜΚΑ: ${amka}`});
                    setIsFetchingCitizen(false);
                }
            }
        });
    }, [amka]);

    const saveAndGoToPatient = () => {
        // Validate fields, convert to fhir condition, save, wait for async server checks, wait for patient to be created in central repo and go to patient page
        setIsSaving(true);
        const condtionToSend = fieldMapperService.mapConditionToFhir(amka!, condition);
        fhirService.validateCreateCondition(condtionToSend).subscribe({
            next: (validationResponse:QcsToolResponse) => {
                if ((validationResponse.errors && validationResponse.errors.length)) {
                    setIsSaving(false);
                    Modal.error({title:'Σφάλμα υποβολής', content:<div style={{whiteSpace:'pre-line'}}>{validationResponse.errors.map(e=>`${e.error_description_gr} (${e.var1_name_gr}: ${e.var1_value})` + "\r\n")}</div>});
                    return;
                }
                if ((validationResponse.warnings && validationResponse.warnings.length)) {
                    Modal.confirm({
                        onCancel:()=>{
                            setIsSaving(false);
                        },
                        onOk: ()=>{
                            fhirService.addCondition(fieldMapperService.mapConditionToFhir(amka!, condition)).subscribe({
                                next: (data) => {
                                    notification.success({message:`Επιτυχής καταχώριση στο ΕΜΝΝ ασθενή με ΑΜΚΑ: ${amka}`});
                                    navigate(`/patients/${amka}`);
                                    setIsSaving(false);
                                },
                                error: async (err) => {
                                    notification.error({message:`Αποτυχία καταχώρισης στο ΕΜΝΝ ασθενή με ΑΜΚΑ: ${amka}`});
                                    setIsSaving(false);
                                }
                            });
                        },
                        okText:'Επιβεβαίωση',
                        cancelText:'Ακύρωση',
                        title:'Απαιτείται επιβεβαίωση', 
                        content:<div style={{whiteSpace:'pre-line'}}>{validationResponse.warnings.map(e=>`${e.error_description_gr} (${e.var1_name_gr}: ${e.var1_value})` + "\r\n")}</div>},                                            
                    );
                    return;
                }
                fhirService.addCondition(fieldMapperService.mapConditionToFhir(amka!, condition)).subscribe({
                    next: (data) => {
                        notification.success({message:`Επιτυχής καταχώριση στο ΕΜΝΝ ασθενή με ΑΜΚΑ: ${amka}`});
                        navigate(`/patients/${amka}`);
                        setIsSaving(false);
                    },
                    error: async (err) => {
                        notification.error({message:`Αποτυχία καταχώρισης στο ΕΜΝΝ ασθενή με ΑΜΚΑ: ${amka}`});
                        setIsSaving(false);
                    }
                });
                
            }, error: (err) => {
                setIsSaving(false);
            }
        });

        
    }

    const initialDiagnosis : Diagnosis = {incidentType:"IT.001"};

    return (
        <>
            {!amka ? <Alert type='error' description='Παρουσιάστηκε σφάλμα'></Alert> : 
            <>
                {isFetchingCitizen && <Skeleton loading={true}></Skeleton>}
                {(!isFetchingCitizen && fullName) && <Layout className='ant-layout-page'>
                    <Layout.Header className='ant-layout-page-header'>
                        <Space>
                            <h1>{fullName}</h1>
                            <h2><Typography.Text type='secondary'>AMKA: {amka}</Typography.Text></h2>
                        </Space>
                        <div style={{flex:"1 auto"}}></div>
                        <Space>
                            <Button type="primary" loading={isSaving} disabled={isSaving || !isDiagnosisValid} onClick={()=>saveAndGoToPatient()}>Καταχώριση στο ΕΜΜΝ</Button>
                            <Button danger disabled={isSaving} onClick={()=>navigate("/amka-search")}>Άκυρο</Button>
                        </Space>
                    </Layout.Header> 
                    <Layout.Content className='ant-layout-page-content'>
                        <Space direction='vertical' style={{width:'100%'}}>
                            <PersonSecondaryHeaderCompoment
                                dateOfBirth={birthDate!}
                                dateOfDeath={deceasedDate === null ? null : deceasedDate}
                                causeOfDeath={causeOfDeath}
                                citizenship={citizenship}
                                fatherName={fathersName}
                                motherName={mothersName}
                                genderCode={gender} />
                            <Alert type='info' description={`O/Η ${fullName} δεν είναι καταχωρισμένος/η στο Εθνικό Μητρώο Νεοπλασματικών Νοσημάτων. Για την καταχώρισή του/της απαιτείται η διάγνωση του πρωτοπαθούς νεοπλασματικού νοσήματος. Συμπληρώστε τα παρακάτω πεδία και πατήστε το κουμπί "Καταχώριση στο ΕΜΜΝ".`}></Alert>
                            <Spin spinning={isSaving}>
                                <DiagnosisComponent isEditMode={true} isAddingNew={true} initialValue={initialDiagnosis} patient={new Patient(amka!, citizenship, gender, birthDate!, deceasedDate)} onValueChange={(newValue, fhirMessages, isValid)=> {
                                    setCondition(newValue);
                                    setIsDiagnosisValid(isValid);
                                }}></DiagnosisComponent>
                            </Spin>
                        </Space>
                    </Layout.Content>
                </Layout>}
            </>}
        </>
    );
}

export default AddCitizenToRegistryPage;