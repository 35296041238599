import _ from "lodash";
import { ValueSets, ValueSetOptions, ValueSetOption } from "../models/fhir/value-set";
import { NeoplasmType } from "../enums/neoplasm-type-enum";
import { isConstructorDeclaration } from "typescript";
import { Observable, of } from "rxjs";
import axios, { AxiosError, AxiosResponse } from "axios";


export enum RelatedOrgan {
	Unknown,
	Kidney,
	Prostate,
	ThyroidGland,
	Liver,
	BileDuct,
	Utery,
	SkinMelanoma,
}

export type BiomarkerAndTopoDescriptor = {
	biomarkers: string[];
	isProstateRelated: string;
	isMelanomaRelated: string;
	isLiverRelated: string;
	isThyroidRelated: string;
	isUteryRelated	: string;
};

export class ProcessedIcdo3Option {
	code: string;
	equivalentIcd10Codes: string[];

	topographyCode: string;
	topographyDescription: string;

	histology: string;
	
	histologyAndBehaviorCode: string;
	histologyAndBehaviorDescription: string;

	neoplasmType: NeoplasmType;
	relatedOrgan: RelatedOrgan;

	public constructor(
		value: string,
		equivalentIcd10Codes: string[],
		topographyCode: string,
		topographyDescription: string,
		histologyAndBehaviorCode: string,
		histologyAndBehaviorDescription: string,
		histology: string,
		neoplasmType: NeoplasmType,
		relatedOrgan: RelatedOrgan,
	) {
		this.code = value;
		this.equivalentIcd10Codes = equivalentIcd10Codes;
		this.topographyCode = topographyCode;
		this.topographyDescription = topographyDescription;
		this.histologyAndBehaviorCode = histologyAndBehaviorCode;
		this.histologyAndBehaviorDescription = histologyAndBehaviorDescription;
		this.histology = histology;
		this.neoplasmType = neoplasmType;
		this.relatedOrgan = relatedOrgan;
	}
	
	public toDisplayText() {
		return this.topographyDescription + ': ' + this.histologyAndBehaviorDescription;
	}
};

class Icdo3Service {
    private apiUrl : string = window.location.origin;
	private static initilized: boolean = false;
	private static valueSetOptions: ValueSetOptions;
	private static processedOptions: ProcessedIcdo3Option[];
	private static solidTumors: ProcessedIcdo3Option[];
	private static hematologicalNeoplasms: ProcessedIcdo3Option[];
	private static allTumorsDiagnosisTopologyList: ValueSetOption[];
	private static solidTumorsDiagnosisTopologyList: ValueSetOption[];
	private static hematologicalNeoplasmListTopologyList: ValueSetOption[];

	public constructor(icdo3ValueSetOptions: ValueSetOptions) {
		if(!Icdo3Service.initilized) {
			Icdo3Service.initilized = true;
			Icdo3Service.valueSetOptions = icdo3ValueSetOptions;
			Icdo3Service.processedOptions = Icdo3Service.valueSetOptions
				.map(x => {
					const parts = x.label.split(' \u2014 ');
					const code = x.value;
					const topographyCode = parts[0];
					const topographyDescription = `${parts[0]} - ${parts[1]}`;
					const histologyAndBehaviorCode = parts[2];
					const histologyAndBehaviorDescription = `${parts[2]} - ${parts[3]}`;
					const histology = parts[2].split("/")[0];

					return new ProcessedIcdo3Option(
						code,
						[],
						topographyCode,
						topographyDescription,
						histologyAndBehaviorCode,
						histologyAndBehaviorDescription,
						histology,
						Number(histology) < 9590 ?  NeoplasmType.Solid : NeoplasmType.Hematological,
						(() => {
							if(topographyDescription.toLowerCase().includes('prostate')) return RelatedOrgan.Prostate;
							else if(topographyDescription.toLowerCase().includes('kidney') || topographyDescription.toLowerCase().includes('renal')) return RelatedOrgan.Kidney;
							else if(topographyDescription.toLowerCase().includes('melanoma')) return RelatedOrgan.SkinMelanoma;
							else if(topographyDescription.toLowerCase().includes('bile')) return RelatedOrgan.BileDuct;
							else if(topographyDescription.toLowerCase().includes('liver')) return RelatedOrgan.Liver;
							else if(topographyDescription.toLowerCase().includes('thyroid')) return RelatedOrgan.ThyroidGland;
							else if(topographyDescription.toLowerCase().includes('uter')) return RelatedOrgan.Utery;
							else return RelatedOrgan.Unknown;
						})(),
					);
				});

			Icdo3Service.allTumorsDiagnosisTopologyList = _.uniqBy(Icdo3Service.processedOptions, x => x.topographyCode).map(x => ({value: x.topographyCode, label: x.topographyDescription}));

			Icdo3Service.solidTumors = Icdo3Service.processedOptions.filter(x => x.neoplasmType === NeoplasmType.Solid);
			Icdo3Service.hematologicalNeoplasms = Icdo3Service.processedOptions.filter(x => x.neoplasmType === NeoplasmType.Hematological);
			Icdo3Service.solidTumorsDiagnosisTopologyList = _.uniqBy(Icdo3Service.solidTumors, x => x.topographyCode).map(x => ({value: x.topographyCode, label: x.topographyDescription}));
			Icdo3Service.hematologicalNeoplasmListTopologyList = _.uniqBy(Icdo3Service.hematologicalNeoplasms, x => x.topographyCode).map(x => ({value: x.topographyCode, label: x.topographyDescription}));
		}
	}
	
	public getIcd10EquivalentString(icdO3Entry: ProcessedIcdo3Option|null|undefined): Observable<string> {
		if(!icdO3Entry) return of('');
		
		return new Observable(observer => {
            const prom$ = axios.request({
                method: 'GET',
                url: `/api/icd-o3-maps/${encodeURIComponent(icdO3Entry?.code)}/icd-10`,
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            prom$
                .then(async (data: AxiosResponse) => {
					const noMappingLabel = 'Δεν υπάρχει αντιστοίχιση σε ICD-10.';
					if(!data.data) {
						observer.next(noMappingLabel);
						observer.complete();
						return;
					}
					if(data.data.icd10_code === '*') {
						observer.next(noMappingLabel);
						observer.complete();
						return;
					}
                    observer.next(`${data.data.icd10_code} - ${data.data.icd10_label}`);
                    observer.complete();
                }, (error: AxiosError) => {
                    observer.error({
                        message: `There was a network error (Status:${error.status}, Body:${error.response?.data})!`,
                        statusCode: error.status,
                        responseBody: error.response?.data,
                    });
                });
        });
	}
	
	public getApplicableBiomarkersAndOrgan(icdO3Entry: ProcessedIcdo3Option|null|undefined): Observable<BiomarkerAndTopoDescriptor|null> {
		if(!icdO3Entry) return of(null);
		
		return new Observable(observer => {
            const prom$ = axios.request({
                method: 'GET',
                url: `/api/icd-o3-maps/${encodeURIComponent(icdO3Entry?.code)}/biomarkers-and-organs`,
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            prom$
                .then(async (data: AxiosResponse) => {
					const d = data?.data;
                    observer.next({
						biomarkers: d.biomarkers?.split(','),
						isProstateRelated: d.isProstateRelated,
						isMelanomaRelated: d.isMelanomaRelated,
						isLiverRelated: d.isLiverRelated,
						isThyroidRelated: d.isThyroidRelated,
						isUteryRelated: d.isUteryRelated,
					} as BiomarkerAndTopoDescriptor);
                    observer.complete();
                }, (error: AxiosError) => {
                    observer.error({
                        message: `There was a network error (Status:${error.status}, Body:${error.response?.data})!`,
                        statusCode: error.status,
                        responseBody: error.response?.data,
                    });
                });
        });
	}

	public getAllTumorsList() : ProcessedIcdo3Option[] {
		return Icdo3Service.processedOptions;
	}

	public getAllTumorsTopologyList() : ValueSetOption[] {
		return Icdo3Service.allTumorsDiagnosisTopologyList;
	}

	public getSolidTumorsList() : ProcessedIcdo3Option[] {
		return Icdo3Service.solidTumors;
	}

	public getSolidTumorsDiagnosisTopologyList() : ValueSetOption[] {
		return Icdo3Service.solidTumorsDiagnosisTopologyList;
	}

	public getHematologicalNeoplasmList() : ProcessedIcdo3Option[] {
		return Icdo3Service.hematologicalNeoplasms;
	}

	public getHematologicalNeoplasmTopologyList() : ValueSetOption[] {
		return Icdo3Service.hematologicalNeoplasmListTopologyList;
	}

	public getDiagnosisByCodes(topographyCode: string, histologyCode: string) : ProcessedIcdo3Option|null {
		return Icdo3Service.processedOptions.find(x => x.topographyCode === topographyCode && x.histologyAndBehaviorCode === histologyCode) ?? null;
	}

	public getDiagnosisByCode(code: string) : ProcessedIcdo3Option|null {
		return Icdo3Service.processedOptions.find(x => x.code === code) ?? null;
	}
}

export default Icdo3Service;
