import { useEffect, useState } from "react";
import { Avatar, Form, InputNumber, List, Select, Statistic } from "antd";
import { availableSmokingProducts, calculatePackYear } from "../helpers/pack-years-calculator";
import { ConsumedSmokingProduct } from "../models/consumed-smoking-product";
const Option = Select.Option;

const PackYearsCalculatorComponent: React.FC<{ 

    onValueChange?:(packYearsResult?: number) => void }> = ({ onValueChange }) => {
        const [consumedSmokingProducts, setSmokingProducts] = useState<ConsumedSmokingProduct[]>([]);
        const [result, setResult] = useState<number|undefined>(undefined);

        useEffect(()=> {
            let result: number|undefined = undefined;
            consumedSmokingProducts.forEach(cp=> {
                cp.packYearResult = undefined;
                if (!cp.numberPer || !cp.years) return;
                cp.packYearResult = calculatePackYear(cp.numberPer, cp.years, cp.type.dayEquivalent, cp.type.cigarretesEquivalent);
                if (result === undefined) result = 0;
                result += cp.packYearResult!;
            })
            setResult(result);
            
            if(onValueChange) onValueChange(result);
        }, [consumedSmokingProducts])
        return (<>
            <List header={<a onClick={()=> {
                const existingSmokingProducts = consumedSmokingProducts;
                existingSmokingProducts.push({
                    type:availableSmokingProducts[0],
                    numberPer:availableSmokingProducts[0].defaultAmountPerDay,
                    years:availableSmokingProducts[0].defaultYears
                });
                setSmokingProducts([...existingSmokingProducts]);
            }}>Προσθήκη καπνικού προϊόντος</a>}>
                {consumedSmokingProducts.map((p,index)=><List.Item key={index} actions={[
                            <a onClick={() => {
                                const existingSmokingProducts = consumedSmokingProducts;
                                existingSmokingProducts.splice(index, 1);
                                setSmokingProducts([...existingSmokingProducts]);
                            }}>Αφαίρεση</a>
                        ]}>
                            <List.Item.Meta
                                avatar={<Avatar shape="square" size={50}>{index+1}</Avatar>}
                                title={<>
                                <Select defaultValue="Επιλέξτε καπνικό είδος" style={{ width: 220, marginRight:10 }} getPopupContainer={trigger => trigger.parentNode}
                                    onChange={value => {
                                        const selectedSmokingProduct = availableSmokingProducts[Number(value)];
                                        const existingSmokingProducts = consumedSmokingProducts;
                                        existingSmokingProducts[index].type = selectedSmokingProduct;
                                        existingSmokingProducts[index].years = selectedSmokingProduct.defaultYears;
                                        existingSmokingProducts[index].numberPer = selectedSmokingProduct.defaultAmountPerDay;

                                        setSmokingProducts([...existingSmokingProducts]);
                                    }}
                                >
                                    <Option value={0}>{availableSmokingProducts[0].name}</Option>
                                    <Option value={1}>{availableSmokingProducts[1].name}</Option>
                                    <Option value={2}>{availableSmokingProducts[2].name}</Option>
                                    <Option value={3}>{availableSmokingProducts[3].name}</Option>
                                    <Option value={4}>{availableSmokingProducts[4].name}</Option>
                                </Select>
                                <InputNumber style={{width:70, marginRight:10}} value={p.numberPer} onChange={(value)=>{
                                    const existingSmokingProducts = consumedSmokingProducts;
                                    existingSmokingProducts[index].numberPer = value??undefined;
                                    setSmokingProducts([...existingSmokingProducts]);
                                }} min={0} max={500} precision={0}/>
                                 ανά {p.type.per} για
                                <InputNumber style={{width:60, marginRight:10}} value={p.years} onChange={(value)=> {
                                    const existingSmokingProducts = consumedSmokingProducts;
                                    existingSmokingProducts[index].years = value??undefined;
                                    setSmokingProducts([...existingSmokingProducts]);
                                }} min={0} max={100} precision={0}/>
                                έτος/η
                                </>}
                                description={<span>Ισοδύναμο pack year {p.packYearResult ? p.packYearResult.toFixed(2):"-"}</span>}
                            />
                        </List.Item>)}
            </List>
            
            
            <Statistic title='Συνολικά Pack years' suffix='pack/year' value={result ? result.toFixed(2):undefined} style={{marginTop:20}}/>
        </>);
}

export default PackYearsCalculatorComponent;
