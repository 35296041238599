import { BaseEditableModel } from "./base-editable-model";
import { Diagnosis } from "./diagnosis";

export class Histology implements BaseEditableModel {
    readonly conditionId: string;

    cytogeneticTestType?: string;
    histologicalGradeType?: string;
    fuhrmanGradeType?: string;
    isupGradeType?: string;
    gleasonGrowthType?: string;
    gleasonScoreType?: string;
    gradeGroupType?: string;
    pinGradeType?: string;
    melanomaGrowthType?: string;
    clarkStageType?: string;
    breslowGrowth?: number;

    isHepatitisOrCirrhosisPresent?: string;
    isHashimotoThyreoiditisPresent?: string;
    isHpvPresent?: string;
    areIntraepithelialLesionsPresent?: string;
    isMolePresent?: string;
    isExtramedularDisease?: string;

    constructor(conditionId: string) {
        this.conditionId = conditionId;
    }
}