import ObjectOperationsService from "./object-operations-service";
import dayjs from "dayjs";
import { Diagnosis } from "../models/diagnosis";
import { Patient } from "../models/patient";
import { Attendant } from "../models/attendant";
import { PersonalInfo } from "../models/personal-info";
import { DATE_FORMAT, SERVER_DATE_FORMAT } from "../constants";
import { ObservationWireData } from "../models/observation-wire-data";
import _ from "lodash";
import { Histology } from "../models/histology";
import { Staging } from "../models/staging";
import { Therapy, ProcedureWireData } from "../models/fhir/therapy";
import { Therapy as TherapyModel } from "../models/therapy";
import { Progress } from "../models/progress";
import { BiomarkerContainerModel, BiomarkerModel } from "../models/biomarker-container-model";
import { debug } from "console";
import { PathologoReport } from "../models/pathologoReport";
import { DiagnosticReportWireData } from "../models/fhir/diagnostic-report";
import { Doctor } from "../models/doctor";
import { Meta } from "../models/fhir/meta-model";

class FieldMapperService {
    private static apiUrl : string = window.location.origin;
    private objectOperationsService : ObjectOperationsService = new ObjectOperationsService();

    public static setApiUrl(apiUrl: string) {
        FieldMapperService.apiUrl = apiUrl;
    }

	public mapPatientToFhir(patient: Patient, meta?: Meta) : object {
        const telecom = [];
        if (patient.personalInfo?.phone1) telecom.push({system: 'phone',value: patient.personalInfo?.phone1});
        if (patient.personalInfo?.phone2) telecom.push({system: 'phone',value: patient.personalInfo?.phone2});
        if (patient.personalInfo?.email1) telecom.push({system: 'email',value: patient.personalInfo?.email1});
        if (patient.personalInfo?.email2) telecom.push({system: 'email',value: patient.personalInfo?.email2});

        let contactName = null;
        if (patient.attendant?.firstName || patient.attendant?.lastName) contactName = { given: [patient.attendant.firstName ?? ''], family: patient.attendant.lastName ?? '',};
        
        const contactTelecom = [];
        if (patient.attendant?.phone1) contactTelecom.push({system: 'phone',value: patient.attendant?.phone1});
        if (patient.attendant?.phone2) contactTelecom.push({system: 'phone',value: patient.attendant?.phone2});
        if (patient.attendant?.email1) contactTelecom.push({system: 'email',value: patient.attendant?.email1});
        if (patient.attendant?.email2) contactTelecom.push({system: 'email',value: patient.attendant?.email2});
        let contactAddress = null;
        if (patient.attendant?.address) contactAddress = {line: [patient.attendant.address]};

        let contact = [];
        if (contactName || contactTelecom.length || contactAddress) {
            const thisContact:any = {};
            if (contactName) thisContact.name = contactName;
            if (contactTelecom.length) thisContact.telecom = contactTelecom;
            if (contactAddress) thisContact.address = contactAddress;
            contact.push(thisContact);
        }

        let resource: any = {
            id : patient.id!,
            active : true,
            resourceType : 'Patient',
			birthDate: dayjs(patient.dateOfBirth).format(SERVER_DATE_FORMAT),
			gender: patient.gender,
			identifier: [
				{
					system: 'urn:patient-identifier:amka',
					value: patient.amka,
				},
				{
					system: 'urn:patient-identifier:internal',
					value: patient.id!,
				},
			],
			name: [{
				given: [patient.firstName],
				family: patient.lastName,
			}],
			telecom,
			// link: []
			contact,
            extension: [
                {
                    url: 'http://hl7.org/fhir/StructureDefinition/patient-nationality',
                    valueCodeableConcept: {
                        coding: [{
                            code: patient.citizenship,
                            system: 'urn:nationality:internal',
                        }]
                    }
                }
            ],
		};

        if(patient.dateOfDeath) resource.deceasedDateTime = dayjs(patient.dateOfDeath).format(SERVER_DATE_FORMAT);
        else resource.deceasedBoolean = !!patient.causeOfDeath;

        resource.address = [];
        if(patient.personalInfo?.city || patient.personalInfo?.district || patient.personalInfo?.address) {
            const address:any = {};
            if (patient.personalInfo?.city) address.city = patient.personalInfo?.city;
            if (patient.personalInfo?.district) address.district = patient.personalInfo?.district;
            if (patient.personalInfo?.address) address.line =[ patient.personalInfo?.address];
            resource.address.push(address);
        }

        if (meta) resource.meta = meta;

		return resource;
	}

	public mapConditionToFhir(patientAmka: string, condition: Diagnosis) : object {
        const id = condition.id;
        const conditionStatus = condition.status;
        const disgnosisIcdo3 = condition.icdO3DiagnosisCode!;
        const incidentType = condition.incidentType!;
        const bodySiteLocation = condition.bodySiteLocationCode!;
        const icdO3Grade = condition.icdO3GradeCode!;
        const assertedDate = condition.diagnosisDate!;
        const metastaticSite = condition.metastasisSiteCode;
        const basisOfDiagnosis = condition.diagnosisMethodCode!;
        const dueToConditionId = condition.dueToDiagnosisId;

        const clinicalStatus: string = this.mapConditionStatusToClinicalStatus(conditionStatus);
        let resource: any = {
            resourceType : "Condition",
            clinicalStatus : {
                "coding" : [{
                    "system" : "http://terminology.hl7.org/CodeSystem/condition-clinical",
                    "code" : clinicalStatus
                }]
            },
            verificationStatus : {
                coding : [{
                    system : "http://terminology.hl7.org/CodeSystem/condition-ver-status",
                    code : "confirmed"
                }]
            },
            category : [
                {
                    coding : [{
                        system : `${FieldMapperService.apiUrl}/ValueSet/IncidentType`,
                        code : incidentType
                    }]
                }
            ],
            code : {
                coding : [{
                    system : `${FieldMapperService.apiUrl}/ValueSet/DiagnosisICDO3`,
                    code : disgnosisIcdo3
                }]
            },
            subject : {
                reference : `Patient/urn:patient-identifier:amka|${patientAmka}`
            },
            extension: [
                {
                    url: "http://hl7.org/fhir/StructureDefinition/condition-assertedDate",
                    valueDateTime: dayjs(assertedDate).format(SERVER_DATE_FORMAT),
                }
            ]
        };

        let extensions: any = resource.extension;
        let category: any = resource.category;

        if(id)
            resource.id = id;

        if(conditionStatus) extensions.push({
            url: `${FieldMapperService.apiUrl}/FieldTypes/ConditionStatus`,
            valueCodeableConcept: {
                coding : [{
                    system: `${FieldMapperService.apiUrl}/ValueSet/ConditionStatus`,
                    code: conditionStatus
                }]
            }
        });

        if(basisOfDiagnosis) extensions.push({
            url: `${FieldMapperService.apiUrl}/FieldTypes/BasisOfDiagnosis`,
            valueCodeableConcept: {
                coding : [{
                    system: `${FieldMapperService.apiUrl}/ValueSet/BasisOfDiagnosis`,
                    code: basisOfDiagnosis
                }]
            }
        });

        if(dueToConditionId) extensions.push({
            url: "http://hl7.org/fhir/StructureDefinition/condition-dueTo",
            valueReference: {
                reference: dueToConditionId ? `Condition/${dueToConditionId}` : null,
            }
        });

        if(metastaticSite) extensions.push({
            url: `${FieldMapperService.apiUrl}/FieldTypes/MetastaticSite`,
            valueCodeableConcept: {
                coding : [{
                    system: `${FieldMapperService.apiUrl}/ValueSet/MetastaticSite`,
                    code: metastaticSite
                }]
            }
        });

        if(bodySiteLocation) category.push({
            coding : [{
                system: `${FieldMapperService.apiUrl}/ValueSet/BodySiteLocation`,
                code: bodySiteLocation
            }]
        });

        if(icdO3Grade) category.push({
            coding : [{
                system: `${FieldMapperService.apiUrl}/ValueSet/IcdO3Grade`,
                code: icdO3Grade
            }]
        });

		return resource;
	}

	public mapObservationToFhir(observation: ObservationWireData, meta?: Meta) : object {
        const id = observation.id;
        let resource: any = {
            resourceType : "Observation",
            status : "final",
            code : {
                coding : [{
                    system : `${FieldMapperService.apiUrl}/ValueSet/FieldTypes`,
                    code : observation.code
                }]
            },
            subject : {
                reference: `Patient/${observation.patientId}`
            },
            issued: dayjs(observation.issuedDate).format(),
        };

        if(id)
            resource.id = id;

        if(observation.conditionId) {
            resource.focus = [{
                reference: `Condition/${observation.conditionId}`,
            }];
        }

        if(observation.effectiveDateTimeEnd) {
            if(!observation.effectiveDateTime)
                throw new Error(`Fhir Observation resource cannot have effective date end and no effective date start!`);

            resource.effectivePeriod = {
                start: dayjs(observation.effectiveDateTime).format(SERVER_DATE_FORMAT),
                end: dayjs(observation.effectiveDateTimeEnd).format(SERVER_DATE_FORMAT),
            };
        }
        else if(observation.effectiveDateTime) {
            resource.effectiveDateTime = dayjs(observation.effectiveDateTime).format(SERVER_DATE_FORMAT);
        }
        if(observation.valueQuantity) {
            resource.valueQuantity = {
                value: observation.valueQuantity,
                //system: "http://unitsofmeasure.org",
            };
        }
        else if(observation.valueBoolean) {
            resource.valueBoolean = {
                value: observation.valueBoolean,
                //system: "http://unitsofmeasure.org",
            };
        }
        else if(observation.valueInteger) {
            resource.valueInteger = {
                value: observation.valueInteger,
                //system: "http://unitsofmeasure.org",
            };
        }
        else if(observation.valueCode) {
            resource.valueCodeableConcept = {
                coding: [{
                    system: `${FieldMapperService.apiUrl}/ValueSet/${observation.valueCodeSystem}`,
                    code: observation.valueCode,
                }],
            };
        }

        if(observation.components && observation.components.length > 0) {
            resource.component = observation.components.map(x => {
                return {
                    code: {
                        coding: [{
                            system: `${FieldMapperService.apiUrl}/ValueSet/FieldTypes`,
                            code: x.code,
                        }],
                    },
                    valueCodeableConcept:  {
                        coding: [{
                            system: `${FieldMapperService.apiUrl}/ValueSet/${x.valueSystem}`,
                            code: x.valueCode,
                        }],
                    },
                };
            });
        }

        if (meta) resource.meta = meta;
        return resource;
    }

	public mapProcedureToFhir(treatment: ProcedureWireData) : object {
        let resource: any = {
            id: treatment.id,
            resourceType : "Procedure",
            status : "completed",
            category : [],
            code : {
                coding : [{
                    system : `${FieldMapperService.apiUrl}/ValueSet/TreatmentTypeForNeoplasm`,
                    code : treatment.code
                }]
            },
            subject: {
                reference: `Patient/${treatment.patientId}`
            },
            reason: [{
                reference: {
                    reference: `Condition/${treatment.conditionId}`
                }
            }],
            recorded: dayjs(new Date()).format(),
            occurrenceDateTime: dayjs(treatment.occurrenceDateTime).format(),
            extension: [],
        };

        // if(treatment.stagingObservationId) {
        //     resource.supportingInfo = [{
        //         reference: `Observation/${treatment.stagingObservationId}`
        //     }];
        // }

        if(treatment.surgeryLimitsType) {
            resource.category.push({
                coding : [{
                    system : `${FieldMapperService.apiUrl}/ValueSet/SurgeryLimits`,
                    code : treatment.surgeryLimitsType
                }]
            });
        }

        if(treatment.radioTherapyType) {
            resource.category.push({
                coding : [{
                    system : `${FieldMapperService.apiUrl}/ValueSet/RadiotherapyType`,
                    code : treatment.radioTherapyType
                }]
            });
        }

        if(treatment.chemoTherapyType) {
            resource.category.push({
                coding : [{
                    system : `${FieldMapperService.apiUrl}/ValueSet/ChemotherapyType`,
                    code : treatment.chemoTherapyType
                }]
            });
        }

        if(treatment.otherTherapyType) {
            resource.category.push({
                coding : [{
                    system : `${FieldMapperService.apiUrl}/ValueSet/UnspecifiedSystemicTherapyType`,
                    code : treatment.otherTherapyType
                }]
            });
        }

        if(treatment.surgeryEtipCode) {
            resource.extension.push({
                url: `${FieldMapperService.apiUrl}/FieldTypes/SurgeryTypeETIPCode`,
                valueCodeableConcept: {
                    coding : [{
                        system: `${FieldMapperService.apiUrl}/ValueSet/SurgeryTypeETIPCode`,
                        code: treatment.surgeryEtipCode
                    }]
                }
            });
        }

        if(treatment.presenceOfInfiltratedLymphNodes) {
            resource.extension.push({
                url: `${FieldMapperService.apiUrl}/FieldTypes/PresenceOfInfiltratedLymphNodes`,
                valueCodeableConcept: {
                    coding : [{
                        system: `${FieldMapperService.apiUrl}/ValueSet/BooleanValueSet`,
                        code: treatment.presenceOfInfiltratedLymphNodes
                    }]
                }
            });
        }

        if(treatment.presenceOfInfiltratedNerves) {
            resource.extension.push({
                url: `${FieldMapperService.apiUrl}/FieldTypes/PresenceOfInfiltratedNerves`,
                valueCodeableConcept: {
                    coding : [{
                        system: `${FieldMapperService.apiUrl}/ValueSet/BooleanValueSet`,
                        code: treatment.presenceOfInfiltratedNerves
                    }]
                }
            });
        }

        if(treatment.numberOfExcludedLymphNodes) {
            resource.extension.push({
                url: `${FieldMapperService.apiUrl}/FieldTypes/NumberOfExcludedLymphNodes`,
                valueQuantity: {
                    value: treatment.numberOfExcludedLymphNodes,
                }
            });
        }

        if(treatment.numberOfInfiltratedLymphNodes) {
            resource.extension.push({
                url: `${FieldMapperService.apiUrl}/FieldTypes/NumberOfInfiltratedLymphNodes`,
                valueQuantity: {
                    value: treatment.numberOfInfiltratedLymphNodes,
                }
            });
        }

        return resource;
    }

	public mapDiagnosticReportToFhir(diagnosticReport: DiagnosticReportWireData) : object {
        let resource: any = {
            resourceType : "DiagnosticReport",
            status : "final",
            category : [],
            extension: [],
            code : {
                coding : [{
                    system: 'http://loinc.org',
                    code: '11526-1',
                }]
            },
			note: [{
                text: diagnosticReport.text,
                authorString: diagnosticReport.labName,
            }],
            subject: {
                reference: `Patient/${diagnosticReport.patientId}`
            },
            issued: dayjs(new Date()).format(),
            effectiveDateTime: dayjs(diagnosticReport.effectiveDateTime).format(),
        };

        
        if(diagnosticReport.id)
            resource.id = diagnosticReport.id;

        return resource;
    }

	public mapAllPatientDataFromFhir(allPatientDataBundle: object) : Patient {
		const patientFhir = this.objectOperationsService.getPropertyByPath(allPatientDataBundle, 'entry[resource.resourceType=Patient].0.resource')?.propValue;
		const conditionsFhir = (this.objectOperationsService.getPropertyByPath(allPatientDataBundle, 'entry[resource.resourceType=Condition]')?.propValue as any[]).map(x => x.resource);
		const observationsFhir = (this.objectOperationsService.getPropertyByPath(allPatientDataBundle, 'entry[resource.resourceType=Observation]')?.propValue as any[]).map(x => x.resource);
		const proceduresFhir = (this.objectOperationsService.getPropertyByPath(allPatientDataBundle, 'entry[resource.resourceType=Procedure]')?.propValue as any[]).map(x => x.resource);
		const diagnosticReportsFhir = (this.objectOperationsService.getPropertyByPath(allPatientDataBundle, 'entry[resource.resourceType=DiagnosticReport]')?.propValue as any[]).map(x => x.resource);

		let patient = this.mapPatientFromFhir(patientFhir);
		patient.diagnoses = conditionsFhir.map(x => this.mapDiagnosisFromFhir(x));

        // Patient Observations.
        const getLatestObservation = (key: string) => {
            return _.last(_.sortBy(observationsFhir.filter(x => x.code?.coding[0]?.code === key), x => new Date(x.issued)));
        };
        const weightObservation = getLatestObservation('Weight');
        if(weightObservation) {
            patient.personalInfo!.weight = +weightObservation.valueQuantity.value;
            patient.personalInfo!.weightDate = weightObservation.effectiveDateTime ? new Date(weightObservation.effectiveDateTime) : undefined;
        }

        const heightObservation = getLatestObservation('Height');
        if(heightObservation) {
            patient.personalInfo!.height = +heightObservation.valueQuantity.value;
            patient.personalInfo!.heightDate = heightObservation.effectiveDateTime ? new Date(heightObservation.effectiveDateTime) : undefined;
        }

        const bmiObservation = getLatestObservation('BMI');
        if(bmiObservation) {
            patient.personalInfo!.bmi = +bmiObservation.valueQuantity.value;
            patient.personalInfo!.bmiDate = bmiObservation.effectiveDateTime ? new Date(bmiObservation.effectiveDateTime) : undefined;
        }

        const smokingStatusObservation = getLatestObservation('SmokingStatus');
        if(smokingStatusObservation) {
            patient.personalInfo!.smokingStatus = smokingStatusObservation.valueCodeableConcept.coding[0].code;
            patient.personalInfo!.smokingStartYear = smokingStatusObservation.effectivePeriod?.start ? new Date(smokingStatusObservation.effectivePeriod.start).getFullYear() : undefined;
            patient.personalInfo!.smokingEndYear = smokingStatusObservation.effectivePeriod?.end ? new Date(smokingStatusObservation.effectivePeriod.end).getFullYear() : undefined;
        }

        const smokingPackYearsObservation = getLatestObservation('SmokingPackYears');
        if(smokingPackYearsObservation) {
            patient.personalInfo!.packYears = smokingPackYearsObservation.valueQuantity.value;
        }

        // Histology Observations.
        patient.histologies = this.mapHistologyObservations(observationsFhir);

        // Staging Observations.
        patient.stagings = this.mapStagingObservations(observationsFhir);

        // Therapies.
        patient.therapies = this.mapProcedures(proceduresFhir);

        // Progresses.
        patient.progresses = this.mapProgresses(observationsFhir);

        // Biomarkers.
        patient.biomarkers = this.mapBiomarkers(observationsFhir, patient.diagnoses);

        // DiagnosticReports.
        patient.pathologoReports = this.mapDiagnosticReport(diagnosticReportsFhir);

		return patient;
	}

    public mapDiagnosticReport(diagnosticReportsFhir: any[]): PathologoReport[] {
        return diagnosticReportsFhir.map(report => {
            const id = this.objectOperationsService.getPropertyByPath(report, `id`)?.propValue;

            const ret = new PathologoReport(id);
            ret.rawText = this.objectOperationsService.getPropertyByPath(report, `note.0.text`)?.propValue;
            ret.laboratory = this.objectOperationsService.getPropertyByPath(report, `note.0.authorString`)?.propValue;
            ret.reportDate = this.objectOperationsService.getPropertyByPath(report, `effectiveDateTime`)?.propValue;
            return ret;
        });
    }


    public mapBiomarkers(observationsFhir: any[], diagnoses: Diagnosis[]): BiomarkerContainerModel[] {
        const applicableObservations = observationsFhir.filter(o => {
            var v = this.objectOperationsService.getPropertyByPath(o, `code.coding.0.code`)?.propValue;
            return v?.startsWith('Biomarker_') ?? false;
        });
        const applicableObservationCodes = _.uniq(applicableObservations.map(o => this.objectOperationsService.getPropertyByPath(o, `code.coding.0.code`)?.propValue));
        const getLatestObservation = (conditionId: string, biomarkerCode: string) => {
            return _.last(_.sortBy(applicableObservations.filter(x => x.focus[0].reference === `Condition/${conditionId}` && x.code?.coding?.[0]?.code === biomarkerCode), x => new Date(x.issued)));
        };

        return diagnoses.map(condition => {
            const ret = new BiomarkerContainerModel(condition.id!);
            ret.biomarkers = [];
            applicableObservationCodes.forEach(biomarkerCode => {
                const o = getLatestObservation(condition.id!, biomarkerCode);
                ret.biomarkers.push({
                    biomarkerCode: this.objectOperationsService.getPropertyByPath(o, `code.coding.0.code`)?.propValue,
                    biomarkerValue: this.objectOperationsService.getPropertyByPath(o, `valueCodeableConcept.coding.0.code`)?.propValue ??
                                    this.objectOperationsService.getPropertyByPath(o, `valueQuantity.value`)?.propValue,
                    properties: {},
                } as BiomarkerModel);
            })
            return ret;
        });
    }

    public mapProgresses(observationsFhir: any[]): Progress[] {
        const getSortedObservations = (key: string) => {
            return _.sortBy(observationsFhir.filter(x => x.code?.coding[0]?.code === key), x => new Date(x.issued));
        };

        return getSortedObservations('DiseaseState').map(progr => {
            const obs = this.mapObservationFromFhir(progr);
            const ret = new Progress(obs.id!, obs.conditionId!);
            ret.progressDate = obs.effectiveDateTime;
            ret.statusType = obs.valueCode;
            return ret;
        });
    }


    public mapProcedures(proceduresFhir: any[]): TherapyModel[] {
        return proceduresFhir.map(thera => {
            const id = this.objectOperationsService.getPropertyByPath(thera, `id`)?.propValue;
            const conditionId = this.getReferenceId(this.objectOperationsService.getPropertyByPath(thera, `reason.0.reference.reference`)?.propValue)!;
            const patientId = this.getReferenceId(this.objectOperationsService.getPropertyByPath(thera, `subject.reference`)?.propValue)!;

            const therapy = new TherapyModel(id, conditionId);

            // therapy.surgeryStagingId = this.getReferenceId(this.objectOperationsService.getPropertyByPath(thera, `supportingInfo.0.reference`)?.propValue);
            therapy.therapyDate = this.objectOperationsService.getPropertyByPath(thera, `occurrenceDateTime`)?.propValue;
            therapy.therapyKind = this.objectOperationsService.getPropertyByPath(thera, `code.coding.0.code`)?.propValue;

            therapy.radioTherapyType = this.objectOperationsService.getPropertyByPath(thera, `category[coding.0.system=${FieldMapperService.apiUrl}/ValueSet/RadiotherapyType].0.coding.0.code`)?.propValue;
            therapy.chemoTherapyType = this.objectOperationsService.getPropertyByPath(thera, `category[coding.0.system=${FieldMapperService.apiUrl}/ValueSet/ChemotherapyType].0.coding.0.code`)?.propValue;
            therapy.otherTherapyType = this.objectOperationsService.getPropertyByPath(thera, `category[coding.0.system=${FieldMapperService.apiUrl}/ValueSet/UnspecifiedSystemicTherapyType].0.coding.0.code`)?.propValue;
            therapy.surgeryLimitsType = this.objectOperationsService.getPropertyByPath(thera, `category[coding.0.system=${FieldMapperService.apiUrl}/ValueSet/SurgeryLimits].0.coding.0.code`)?.propValue;

            therapy.surgeryEtipCode = this.objectOperationsService.getPropertyByPath(thera, `extension[url=${FieldMapperService.apiUrl}/FieldTypes/SurgeryTypeETIPCode].0.valueCodeableConcept.coding.0.code`)?.propValue;
            therapy.surgeryNumberOfExcludedLymphNodes = this.objectOperationsService.getPropertyByPath(thera, `extension[url=${FieldMapperService.apiUrl}/FieldTypes/NumberOfExcludedLymphNodes].0.valueQuantity.value`)?.propValue;
            therapy.surgeryNumberOfInfiltratedLymphNodes = this.objectOperationsService.getPropertyByPath(thera, `extension[url=${FieldMapperService.apiUrl}/FieldTypes/NumberOfInfiltratedLymphNodes].0.valueQuantity.value`)?.propValue;
            therapy.surgeryLymphInfiltrationExistenceType = this.objectOperationsService.getPropertyByPath(thera, `extension[url=${FieldMapperService.apiUrl}/FieldTypes/PresenceOfInfiltratedLymphNodes].0.valueCodeableConcept.coding.0.code`)?.propValue;
            therapy.surgeryNevreFiltrationExistenceType = this.objectOperationsService.getPropertyByPath(thera, `extension[url=${FieldMapperService.apiUrl}/FieldTypes/PresenceOfInfiltratedNerves].0.valueCodeableConcept.coding.0.code`)?.propValue;

            return therapy;
        });
    }

	public mapStagingObservations(observationsFhir: any[]) : Staging[] {
        const getLatestObservation = (key: string, conditionId: string) => {
            return _.last(_.sortBy(observationsFhir.filter(x => x.code?.coding[0]?.code === key && x.focus[0].reference === `Condition/${conditionId}`), x => new Date(x.issued)));
        };

        const conditionIds = _.uniq(observationsFhir
            .filter(x => x.focus && !!x.focus[0]?.reference)
            .map(x => this.getReferenceId(x.focus[0]?.reference))
        );

        const ret = conditionIds.map(conditionId => {
            if(!conditionId) throw new Error(`Encountered empty Condition id in reference!`);
            const ret = new Staging(conditionId);
            const processStagingValue = (value: string) => {
                return !value || value?.includes('.None')
                    ? undefined
                    : value;
            };
            
            const observationC = getLatestObservation('cNeoplasmAjccStaging', conditionId);
            ret.idC = processStagingValue(this.objectOperationsService.getPropertyByPath(observationC, `id`)?.propValue);
            ret.clinicalTType = processStagingValue(this.objectOperationsService.getPropertyByPath(observationC, `component[code.coding.0.code=cTNM_T].0.valueCodeableConcept.coding[system=${FieldMapperService.apiUrl}/ValueSet/cTNM_T].0.code`)?.propValue);
            ret.clinicalNType = processStagingValue(this.objectOperationsService.getPropertyByPath(observationC, `component[code.coding.0.code=cTNM_N].0.valueCodeableConcept.coding[system=${FieldMapperService.apiUrl}/ValueSet/cTNM_N].0.code`)?.propValue);
            ret.clinicalMType = processStagingValue(this.objectOperationsService.getPropertyByPath(observationC, `component[code.coding.0.code=cTNM_M].0.valueCodeableConcept.coding[system=${FieldMapperService.apiUrl}/ValueSet/cTNM_M].0.code`)?.propValue);
            
            const observationP = getLatestObservation('pNeoplasmAjccStaging', conditionId);
            ret.idP = processStagingValue(this.objectOperationsService.getPropertyByPath(observationP, `id`)?.propValue);
            ret.pathologicalTType = processStagingValue(this.objectOperationsService.getPropertyByPath(observationP, `component[code.coding.0.code=pTNM_T].0.valueCodeableConcept.coding[system=${FieldMapperService.apiUrl}/ValueSet/pTNM_T].0.code`)?.propValue);
            ret.pathologicalNType = processStagingValue(this.objectOperationsService.getPropertyByPath(observationP, `component[code.coding.0.code=pTNM_N].0.valueCodeableConcept.coding[system=${FieldMapperService.apiUrl}/ValueSet/pTNM_N].0.code`)?.propValue);
            ret.pathologicalMType = processStagingValue(this.objectOperationsService.getPropertyByPath(observationP, `component[code.coding.0.code=pTNM_M].0.valueCodeableConcept.coding[system=${FieldMapperService.apiUrl}/ValueSet/pTNM_M].0.code`)?.propValue);

            const stagingP = processStagingValue(this.objectOperationsService.getPropertyByPath(observationP, `valueCodeableConcept.coding.0.code`)?.propValue);
            const stagingC = processStagingValue(this.objectOperationsService.getPropertyByPath(observationC, `valueCodeableConcept.coding.0.code`)?.propValue);
            ret.stageResultType = stagingP ?? stagingC ?? undefined;
            ret.conditionId = conditionId;

            return ret;
        });

        return ret;
    }

	public mapHistologyObservations(observationsFhir: any[]) : Histology[] {
        const getLatestObservation = (key: string, conditionId: string) => {
            return _.last(_.sortBy(observationsFhir.filter(x => x.code?.coding[0]?.code === key && x.focus[0].reference === `Condition/${conditionId}`), x => new Date(x.issued)));
        };
        const getCode = (observation: any): string|undefined => {
            if(!observation) return undefined;
            return observation?.valueCodeableConcept?.coding[0]?.code ?? null;
        };
        const getValue = (observation: any): number|undefined => {
            if(!observation) return undefined;
            const val = observation?.valueQuantity?.value;
            return val ? +val : undefined;
        };
        const getBooleanFromSet = (observation: any): string|undefined => {
            if(!observation) return undefined;
            const system = observation?.valueCodeableConcept?.coding[0]?.system ?? null;
            const code = observation?.valueCodeableConcept?.coding[0]?.code ?? null;
            const boolValueSetName = `${FieldMapperService.apiUrl}/ValueSet/BooleanValueSet`

            if(system !== boolValueSetName) throw new Error(`Unexpected system type while parsing bool value! (${system})`);

            switch(code) {
                case 'Bool.001': return code;
                case 'Bool.002': return code;
                default: throw new Error(`Unexpected code type while parsing bool value! (${code})`);
            }
        };

        const conditionIds = _.uniq(observationsFhir
            .filter(x => x.focus && !!x.focus[0]?.reference)
            .map(x => this.getReferenceId(x.focus[0]?.reference))
        );

        const ret = conditionIds.map(conditionId => {
            if(!conditionId) throw new Error(`Encountered empty Condition id in reference!`);
            let ret = new Histology(conditionId);
            
            ret.breslowGrowth = getValue(getLatestObservation('MelanomaBreslowGrowth', conditionId));
            ret.clarkStageType = getCode(getLatestObservation('MelanomaClarkStage', conditionId));
            ret.melanomaGrowthType = getCode(getLatestObservation('MelanomaGrowthType', conditionId));
            ret.isMolePresent = getBooleanFromSet(getLatestObservation('MelanomaHadPreexistingMole', conditionId));
            
            ret.gleasonGrowthType = getCode(getLatestObservation('ProstateGleasonGrowth', conditionId));
            ret.gleasonScoreType = getCode(getLatestObservation('ProstateGleasonScore', conditionId));
            ret.gradeGroupType = getCode(getLatestObservation('ProstateGradeGroup', conditionId));
            ret.pinGradeType = getCode(getLatestObservation('ProstaticIntraepithelialNeoplasiaGrade', conditionId));
            
            ret.isupGradeType = getCode(getLatestObservation('RenalNuclearIsupGrade', conditionId));
            ret.fuhrmanGradeType = getCode(getLatestObservation('RenalNuclearFuhrmanGrade', conditionId));
            
            ret.isHashimotoThyreoiditisPresent = getBooleanFromSet(getLatestObservation('Comorbidity_HashimotoThyreoiditis', conditionId));
            ret.areIntraepithelialLesionsPresent = getBooleanFromSet(getLatestObservation('Comorbidity_IntraepithelialLesions', conditionId));
            ret.isHepatitisOrCirrhosisPresent = getBooleanFromSet(getLatestObservation('Comorbidity_HepatitisOrCirrhosis', conditionId));
            ret.isHpvPresent = getBooleanFromSet(getLatestObservation('Comorbidity_HPV', conditionId));
            
            ret.histologicalGradeType = getCode(getLatestObservation('SolidTumorHistologicalGrade', conditionId));
            ret.isExtramedularDisease = getBooleanFromSet(getLatestObservation('ExtramedularDiseaseOfHematologicalNeoplasm', conditionId));

            ret.cytogeneticTestType = getCode(getLatestObservation('CytogeneticTestOfHematologicalNeoplasm', conditionId));

            return ret;
        });

        return ret;
    }


	public mapPatientFromFhir(patientResource: object) : Patient {
		const amka = this.objectOperationsService.getPropertyByPath(patientResource, 'identifier[system=urn:patient-identifier:amka].0.value')?.propValue;
		const gender = this.objectOperationsService.getPropertyByPath(patientResource, 'gender')?.propValue;
		const dateOfBirth = this.objectOperationsService.getPropertyByPath(patientResource, 'birthDate')?.propValue;
		const deceasedDateTime = this.objectOperationsService.getPropertyByPath(patientResource, 'deceasedDateTime')?.propValue;
		const citizenship = this.objectOperationsService.getPropertyByPath(patientResource, 'extension[url=http://hl7.org/fhir/StructureDefinition/patient-nationality].0.valueCodeableConcept.coding.0.code')?.propValue;

		let patient = new Patient(
			amka,
			citizenship,
			gender,
			dayjs(dateOfBirth, SERVER_DATE_FORMAT, true).toDate(),
			deceasedDateTime ? dayjs(deceasedDateTime, SERVER_DATE_FORMAT, true).toDate() : null,
		);

		patient.id = this.objectOperationsService.getPropertyByPath(patientResource, 'id')?.propValue;
		patient.lastName = this.objectOperationsService.getPropertyByPath(patientResource, 'name.0.family')?.propValue;
		patient.firstName = this.objectOperationsService.getPropertyByPath(patientResource, 'name.0.given.0')?.propValue;
		patient.fatherName = this.objectOperationsService.getPropertyByPath(patientResource, 'link[other.type=RelatedPerson].[other.identifier.type.coding.0.code=father].0.other.display')?.propValue;
		patient.motherName = this.objectOperationsService.getPropertyByPath(patientResource, 'link[other.type=RelatedPerson].[other.identifier.type.coding.0.code=mother].0.other.display')?.propValue;

		patient.attendant = this.mapAttendantFromFhir(patientResource);
        patient.personalInfo = this.mapPersonalInfoFromFhir(patientResource);
        patient.dateOfDeath = deceasedDateTime ? dayjs(deceasedDateTime, SERVER_DATE_FORMAT, true).toDate() : null;
        patient.causeOfDeath = this.objectOperationsService.getPropertyByPath(patientResource, `extension[url=${FieldMapperService.apiUrl}/FieldTypes/CauseOfDeath].0.valueCodeableConcept.coding.0.code`)?.propValue;

		return patient;
	}

    public mapPersonalInfoFromFhir(patientResource: object): PersonalInfo {
        let ret = new PersonalInfo();

        ret.district = this.objectOperationsService.getPropertyByPath(patientResource, 'address.0.district')?.propValue;
        ret.city = this.objectOperationsService.getPropertyByPath(patientResource, 'address.0.city')?.propValue;
        ret.address = this.objectOperationsService.getPropertyByPath(patientResource, 'address.0.line.0')?.propValue;
        ret.email1 = this.objectOperationsService.getPropertyByPath(patientResource, 'telecom[system=email].0.value')?.propValue;
        ret.email2 = this.objectOperationsService.getPropertyByPath(patientResource, 'telecom[system=email].1.value')?.propValue;
        ret.phone1 = this.objectOperationsService.getPropertyByPath(patientResource, 'telecom[system=phone].0.value')?.propValue;
        ret.phone2 = this.objectOperationsService.getPropertyByPath(patientResource, 'telecom[system=phone].1.value')?.propValue;

        return ret;
    }

	
	public mapAttendantFromFhir(patientResource: object) : Attendant {
		let attendant = new Attendant();

		attendant.firstName = this.objectOperationsService.getPropertyByPath(patientResource, 'contact.0.name.given.0')?.propValue;
		attendant.lastName = this.objectOperationsService.getPropertyByPath(patientResource, 'contact.0.name.family')?.propValue;
		attendant.email1 = this.objectOperationsService.getPropertyByPath(patientResource, 'contact.0.telecom[system=email].0.value')?.propValue;
		attendant.email2 = this.objectOperationsService.getPropertyByPath(patientResource, 'contact.0.telecom[system=email].1.value')?.propValue;
		attendant.phone1 = this.objectOperationsService.getPropertyByPath(patientResource, 'contact.0.telecom[system=phone].0.value')?.propValue;
		attendant.phone2 = this.objectOperationsService.getPropertyByPath(patientResource, 'contact.0.telecom[system=phone].1.value')?.propValue;
		attendant.address = this.objectOperationsService.getPropertyByPath(patientResource, 'contact.0.address.line.0')?.propValue;

		return attendant;
	}

	
	public mapDiagnosisFromFhir(conditionResource: object) : Diagnosis {
		let diagnosis = new Diagnosis();

		diagnosis.id = this.objectOperationsService.getPropertyByPath(conditionResource, `id`)?.propValue;
		diagnosis.icdO3DiagnosisCode = this.objectOperationsService.getPropertyByPath(conditionResource, `code.coding.0.code`)?.propValue;

		diagnosis.incidentType = this.objectOperationsService.getPropertyByPath(conditionResource, `category[coding.0.system=${FieldMapperService.apiUrl}/ValueSet/IncidentType].0.coding.0.code`)?.propValue;
		diagnosis.bodySiteLocationCode = this.objectOperationsService.getPropertyByPath(conditionResource, `category[coding.0.system=${FieldMapperService.apiUrl}/ValueSet/BodySiteLocation].0.coding.0.code`)?.propValue;
		diagnosis.icdO3GradeCode = this.objectOperationsService.getPropertyByPath(conditionResource, `category[coding.0.system=${FieldMapperService.apiUrl}/ValueSet/IcdO3Grade].0.coding.0.code`)?.propValue;

		diagnosis.status = this.objectOperationsService.getPropertyByPath(conditionResource, `extension[url=${FieldMapperService.apiUrl}/FieldTypes/ConditionStatus].0.valueCodeableConcept.coding.0.code`)?.propValue;
		diagnosis.diagnosisMethodCode = this.objectOperationsService.getPropertyByPath(conditionResource, `extension[url=${FieldMapperService.apiUrl}/FieldTypes/BasisOfDiagnosis].0.valueCodeableConcept.coding.0.code`)?.propValue;
		diagnosis.metastasisSiteCode = this.objectOperationsService.getPropertyByPath(conditionResource, `extension[url=${FieldMapperService.apiUrl}/FieldTypes/MetastaticSite].0.valueCodeableConcept.coding.0.code`)?.propValue;
		diagnosis.dueToDiagnosisId = this.getReferenceId(this.objectOperationsService.getPropertyByPath(conditionResource, `extension[url=http://hl7.org/fhir/StructureDefinition/condition-dueTo].0.valueReference.reference`)?.propValue);
		diagnosis.diagnosisDate = this.objectOperationsService.getPropertyByPath(conditionResource, `extension[url=http://hl7.org/fhir/StructureDefinition/condition-assertedDate].0.valueDateTime`)?.propValue;

		return diagnosis;
	}

	
	public mapObservationFromFhir(observationResource: object) : ObservationWireData {
		let observation = new ObservationWireData();

		observation.id = this.objectOperationsService.getPropertyByPath(observationResource, `id`)?.propValue;
		observation.code = this.objectOperationsService.getPropertyByPath(observationResource, `code.coding.0.code`)?.propValue;
		observation.issuedDate = this.objectOperationsService.getPropertyByPath(observationResource, `issued`)?.propValue;
		observation.patientId = this.getReferenceId(this.objectOperationsService.getPropertyByPath(observationResource, `subject.reference`)?.propValue);
		observation.conditionId = this.getReferenceId(this.objectOperationsService.getPropertyByPath(observationResource, `focus.0.reference`)?.propValue);
		observation.effectiveDateTime = this.objectOperationsService.getPropertyByPath(observationResource, `effectiveDateTime`)?.propValue ??
                                        this.objectOperationsService.getPropertyByPath(observationResource, `effectivePeriod.start`)?.propValue;
        observation.effectiveDateTimeEnd = this.objectOperationsService.getPropertyByPath(observationResource, `effectivePeriod.end`)?.propValue;
        
        observation.valueQuantity = this.objectOperationsService.getPropertyByPath(observationResource, `valueQuantity.value`)?.propValue;
        observation.valueBoolean = this.objectOperationsService.getPropertyByPath(observationResource, `valueBoolean`)?.propValue;
        observation.valueInteger = this.objectOperationsService.getPropertyByPath(observationResource, `valueInteger`)?.propValue;
        observation.valueCode = this.objectOperationsService.getPropertyByPath(observationResource, `valueCodeableConcept.coding.0.code`)?.propValue;
        observation.valueCodeSystem = this.objectOperationsService.getPropertyByPath(observationResource, `valueCodeableConcept.coding.0.system`)?.propValue;

		return observation;
	}

	public mapPatientDoctorBundle(allPatientDataBundle: object) : Doctor[] {
		const doctorFhir = this.objectOperationsService.getPropertyByPath(allPatientDataBundle, 'entry[resource.resourceType=Practitioner]')?.propValue;
        return doctorFhir.map((d: any) => {
            const id = this.objectOperationsService.getPropertyByPath(d, 'resource.identifier[system=urn:practitioner-identifier:amka].0.value')?.propValue;
            const fullName = this.objectOperationsService.getPropertyByPath(d, 'resource.name.0.text')?.propValue;
            const org = this.objectOperationsService.getPropertyByPath(d, 'resource.qualification[code.text=Member].0.issuer.display')?.propValue;
            return new Doctor(id, fullName, '', org);
        });
    }

    protected getReferenceId(reference: string|null|undefined) : string|undefined {
        if(!reference) return undefined;

        if(reference.startsWith('Condition/')) return reference.substring('Condition/'.length);
        if(reference.startsWith('Patient/')) return reference.substring('Patient/'.length);
        if(reference.startsWith('Observation/')) return reference.substring('Observation/'.length);
        throw new Error(`Unhandled reference type ${reference}!`);
    }

    protected mapConditionStatusToClinicalStatus(conditionStatus: string|null|undefined) {
        if(!conditionStatus) return 'unknown';

        switch(conditionStatus) {
            case 'ConditionStatus.000': return 'unknown';
            case 'ConditionStatus.001': return 'remission';
            case 'ConditionStatus.002': return 'active';
            case 'ConditionStatus.003': return 'active';
            case 'ConditionStatus.004': return 'active';
            case 'ConditionStatus.005': return 'relapse';
            default: throw new Error(`Unhandled condition status found! (${conditionStatus})`);
        }
    }

}

export default FieldMapperService;
