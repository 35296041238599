import React from 'react';
import { Statistic, Tooltip } from "antd";
import dayjs from 'dayjs';
import { DATE_FORMAT } from "../constants";
import { InfoCircleOutlined } from '@ant-design/icons';
import TerminologyService from '../services/terminology-service';

const PersonSecondaryHeaderCompoment: React.FC<{ 
    dateOfBirth:Date, 
    dateOfDeath?:Date|null,
    causeOfDeath?:string|null,
    genderCode:string,
    fatherName:string|undefined,
    motherName:string|undefined,
    citizenship:string}> = ({
        dateOfBirth, 
        dateOfDeath,
        causeOfDeath,
        genderCode,
        fatherName,
        motherName,
        citizenship}) => {
            
    const nationality = TerminologyService.getValueSetOptions('Countries').find(x => x.value.toLowerCase() === citizenship.toLowerCase())?.label ?? citizenship;

    return (
        <>
            <div className='header_information_row'>
                <Statistic style={{flex:1}} title="Φύλο" value={genderCode === 'male' ? 'Άνδρας' : genderCode === 'female' ? 'Γυναίκα' : '-'} />
                <Statistic style={{flex:1}} title="Ηλικία" value={dayjs(dateOfDeath ? dateOfDeath! : undefined).diff(dayjs(dateOfBirth), 'years')} />
                <Statistic style={{flex:2}} title="Ημερομηνία γέννησης" value={dayjs(dateOfBirth).format(DATE_FORMAT)} />
                {dateOfDeath && <Statistic style={{flex:2}} title={<span>Ημερομηνία θανάτου <Tooltip title={causeOfDeath ?? 'Αδιευκρίνιστη αιτία θανάτου'}><InfoCircleOutlined /></Tooltip></span>} value={dayjs(dateOfDeath!).format(DATE_FORMAT)} />}
                
                <Statistic style={{flex:2}} title="Όνομα πατέρα" value={fatherName || '-'} />
                <Statistic style={{flex:2}} title="Όνομα μητέρας" value={motherName || '-'} />
                <Statistic style={{flex:2}} title="Υπηκοότητα" value={nationality || '-'} />
            </div>
        </>
    );
}

export default PersonSecondaryHeaderCompoment;