import { useEffect, useState } from "react";
import { MultiTabContentComponentParams } from "./multi-tab-fields-container";
import { Checkbox, DatePicker, Divider, Empty, Form, Input, InputNumber, Select } from "antd";
import { Therapy } from "../models/therapy";
import { Therapy as FhirTherapy, ProcedureWireData } from "../models/fhir/therapy";
import { some } from "lodash";
import { BaseFhirModel } from "../models/fhir/base-fhir-model";
import dayjs from "dayjs";
import { DATE_FORMAT } from "../constants";
import { FhirResourceFriendlyName, FhirResourceName } from "../helpers/fhir-message-helper";
import Icdo3Service from "../services/icdo3-service";
import TerminologyService from "../services/terminology-service";
import { NeoplasmType } from "../enums/neoplasm-type-enum";
import { Patient } from "../models/patient";
import { normalizeString } from "../helpers/string-helpers";


export class TherapyComponentParams implements MultiTabContentComponentParams  {
    panelTitle:string = "Θεραπεία";
    initialValuesOfTabs:{tabTitle: string, initialValues:Therapy[]}[]=[];
    constructor(public patient:Patient) {}
    getTabComponents(key:string, isEditMode:boolean, initialValues?:Therapy[], onTabValueItemChange?: (itemIndex:number, newValue:Therapy, fhirMessages:BaseFhirModel[], isValid:boolean) => void) : React.ReactNode {
        const availableTherapyTypes = TerminologyService.getValueSetOptions('TreatmentTypeForNeoplasm');

        if (!initialValues || initialValues.length === 0) return <Empty/>

        return initialValues.map((initialValue,index) =>
        this.patient && <div key={`${key}-${index}`}>
            <Divider key={`divider-${key}`} orientation="left">{availableTherapyTypes.find(it=>it.value ===  initialValue.therapyKind)?.label ?? '-'}</Divider>
            <TherapyComponent isEditMode={isEditMode} isAddingNew={false} initialValue={initialValue} patient={this.patient} onValueChange={(newValue, fhirMessages, isValid) => {
                if (onTabValueItemChange) onTabValueItemChange(index, newValue, fhirMessages, isValid);
            }}/>
        </div>);
    };
};



const TherapyComponent: React.FC<{ 
    isEditMode:boolean, 
    isAddingNew:boolean,
    initialValue: Therapy, 
    patient: Patient,
    onValueChange?:(newValue: Therapy, fhirMessages:BaseFhirModel[], isValid:boolean) => void }> = ({ isEditMode, isAddingNew, initialValue, patient, onValueChange }) => {
    
    const KeySolidNeoplasmSurgery = 'TTFN.SOL.001';
    const KeyHematologicalNeoplasmSurgery = 'TTFN.HEM.001';
    
    const availableTherapyTypes = TerminologyService.getValueSetOptions('TreatmentTypeForNeoplasm');
    const availableYesNoTypes = TerminologyService.getValueSetOptions('BooleanValueSet');
    const availableRadioTherapyTypes = TerminologyService.getValueSetOptions('RadiotherapyType');
    const availableChemotherapyTypes = TerminologyService.getValueSetOptions('ChemotherapyType');
    const availableSurgeryEtiupCodes = TerminologyService.getValueSetOptions('SurgeryTypeETIPCode');
    const availableOtherTherapyTypes = TerminologyService.getValueSetOptions('UnspecifiedSystemicTherapyType');
    const availableSurgeryLimits = TerminologyService.getValueSetOptions('SurgeryLimits');
    
    
    const icdo3DiagnosisValueSetOptions = TerminologyService.getValueSetOptions('DiagnosisICDO3');
    const icdo3Service = new Icdo3Service(icdo3DiagnosisValueSetOptions);
    const relatedDiagnoses = patient.diagnoses.filter(d => d.id === initialValue.conditionId || d.dueToDiagnosisId === initialValue.conditionId);
    const icdO3Diagnoses = relatedDiagnoses.map(x => icdo3Service.getDiagnosisByCode(x.icdO3DiagnosisCode!));

    const availableStages = patient.stagings
        .filter(x => x.conditionId === initialValue.conditionId)
        .map(s => ({
            value: s.idP,
            label: s.toDisplayText(),
        }));

    const [therapyKind, setTherapyKind] = useState<string|undefined>(initialValue.therapyKind);
    const [therapyDate, setTherapyDate] = useState<dayjs.Dayjs|undefined>(initialValue.therapyDate ? dayjs(initialValue.therapyDate!) : undefined);
    const [surgeryEtipCode, setSurgerEtipCodes] = useState<string|undefined>(initialValue.surgeryEtipCode);
    // const [surgeryStagingId, setSurgeryStagingId] = useState<string|undefined>(initialValue.surgeryStagingId);
    const [surgeryLimitsType, setSurgerFreeLimitsType] = useState<string|undefined>(initialValue.surgeryLimitsType);
    const [surgeryExcludedLymphNodes, setSurgeryExcludedLymphNodes] = useState<number|undefined>(initialValue.surgeryNumberOfExcludedLymphNodes);
    const [surgeryInfiltratedLymphNodes, setSurgeryInfiltratedLymphNodes] = useState<number|undefined>(initialValue.surgeryNumberOfInfiltratedLymphNodes);
    const [surgeryLymphInfiltrationExistenceType, setSurgeryLymphInfiltrationExistenceType] = useState<string|undefined>(initialValue.surgeryLymphInfiltrationExistenceType);
    const [surgeryNevreInfiltrationExistenceType, setSurgeryNevreInfiltrationExistenceType] = useState<string|undefined>(initialValue.surgeryNevreFiltrationExistenceType);
    const [radioTherapyType, setRadioTherapyType] = useState<string|undefined>(initialValue.radioTherapyType);
    const [chemoTherapyType, setChemoTherapyType] = useState<string|undefined>(initialValue.chemoTherapyType);
    const [otherTherapyType, setOtherTherapyType] = useState<string|undefined>(initialValue.otherTherapyType);
    
    useEffect(()=> {
        if ((isEditMode || isAddingNew) && !therapyDate) {
            setTherapyDate(patient.dateOfDeath ? dayjs(patient.dateOfDeath) : dayjs());
        }
    }, [isEditMode, isAddingNew])

    useEffect(()=> {
        if (onValueChange) {
            const fhirMessages: BaseFhirModel[] = [];
            fhirMessages.push({
                fhirResourceName:FhirResourceName.therapy,
                friendlyResourceName:FhirResourceFriendlyName.therapy,
                occuredDate:therapyDate ? therapyDate.toDate() : undefined,
                therapyKind,
                wireData: {
                    id: initialValue.id,
                    patientId: patient?.id,
                    conditionId: initialValue.conditionId,
                    // stagingObservationId: surgeryStagingId,
                    code: therapyKind,
                    surgeryEtipCode: surgeryEtipCode,
                    radioTherapyType: radioTherapyType,
                    chemoTherapyType: chemoTherapyType,
                    otherTherapyType: otherTherapyType,
                    occurrenceDateTime: therapyDate ? therapyDate.toDate() : undefined,
                    surgeryLimitsType: surgeryLimitsType,
                    numberOfInfiltratedLymphNodes: surgeryInfiltratedLymphNodes,
                    numberOfExcludedLymphNodes: surgeryExcludedLymphNodes,
                    presenceOfInfiltratedLymphNodes: surgeryLymphInfiltrationExistenceType,
                    presenceOfInfiltratedNerves: surgeryNevreInfiltrationExistenceType,
                } as ProcedureWireData
            } as FhirTherapy);

            const newTherapy = new Therapy(initialValue.id, initialValue.conditionId);
            newTherapy.therapyKind = therapyKind;
            newTherapy.therapyDate = therapyDate ? therapyDate.toDate() : undefined;
            newTherapy.surgeryEtipCode = surgeryEtipCode;
            // newTherapy.surgeryStagingId = surgeryStagingId;
            newTherapy.surgeryLimitsType = surgeryLimitsType;
            newTherapy.surgeryNumberOfExcludedLymphNodes = surgeryExcludedLymphNodes;
            newTherapy.surgeryNumberOfInfiltratedLymphNodes = surgeryInfiltratedLymphNodes;
            newTherapy.surgeryLymphInfiltrationExistenceType = surgeryLymphInfiltrationExistenceType;
            newTherapy.surgeryNevreFiltrationExistenceType = surgeryNevreInfiltrationExistenceType;
            newTherapy.radioTherapyType = radioTherapyType;
            newTherapy.chemoTherapyType = chemoTherapyType;
            newTherapy.otherTherapyType = otherTherapyType;
            onValueChange(newTherapy, fhirMessages, (therapyKind !== undefined && therapyDate !== undefined));
        }
    }, [therapyKind, therapyDate, surgeryEtipCode, surgeryLimitsType, surgeryExcludedLymphNodes, surgeryInfiltratedLymphNodes, surgeryLymphInfiltrationExistenceType, surgeryNevreInfiltrationExistenceType, radioTherapyType, chemoTherapyType, otherTherapyType]);

    return (
        <>
            <Form
                layout='vertical'
                style={{ display: 'flex', flexDirection:'row' }}
                autoComplete="off"
            >
                <div className='patient-fields-column'>
                    <Form.Item
                        label="Είδος Θεραπείας"
                        validateStatus={!therapyKind ? 'error' : 'success'}
                        help={!therapyKind ? 'Το πεδίο απαιτείται': ''}
                    >
                        {isEditMode && isAddingNew && <Select showSearch options={availableTherapyTypes.filter(att=> {
                            if (some(icdO3Diagnoses, d => d?.neoplasmType === NeoplasmType.Solid) && !some(icdO3Diagnoses, d => d?.neoplasmType === NeoplasmType.Hematological)) return att.value.includes("SOL");
                            if (!some(icdO3Diagnoses, d => d?.neoplasmType === NeoplasmType.Solid) && some(icdO3Diagnoses, d => d?.neoplasmType === NeoplasmType.Hematological)) return att.value.includes("HEM");
                            return true;
                        })} allowClear={true} value={therapyKind} onChange={(value) => {
                            setTherapyKind(value);
                            if (value !== KeySolidNeoplasmSurgery && value !== KeyHematologicalNeoplasmSurgery) {
                                setSurgerEtipCodes(undefined);
                                setSurgerFreeLimitsType(undefined);
                                // setSurgeryStagingId(undefined);
                            }
                            if (value !== KeySolidNeoplasmSurgery) {
                                setSurgeryExcludedLymphNodes(undefined);
                                setSurgeryInfiltratedLymphNodes(undefined);
                                setSurgeryLymphInfiltrationExistenceType(undefined);
                                setSurgeryNevreInfiltrationExistenceType(undefined);
                            }
                            if (value !== 'TTFN.SOL.002' && value !== 'TTFN.SOL.012' && value !== 'TTFN.HEM.002')
                                setChemoTherapyType(undefined);
                            }
                        } filterOption={(input, option) =>
                            normalizeString(option?.label ?? '').toLowerCase().includes(normalizeString(input??'').toLowerCase())
                        }/>}
                        {(!isEditMode || !isAddingNew) && <div className='field-value-text'>{therapyKind ? availableTherapyTypes.find(it=>it.value === therapyKind)?.label ?? '-' : '-'}</div>}
                    </Form.Item>
                    <Form.Item
                            label="Ημερομηνία Έναρξης Θεραπείας"
                            validateStatus={!therapyDate ? 'error' : therapyDate.isBefore(dayjs(patient.dateOfBirth!)) ? 'error' :  patient.dateOfDeath && therapyDate.isAfter(dayjs(patient.dateOfDeath!)) ? 'error' : 'success'}
                            help={!therapyDate ? 'Το πεδίο απαιτείται': therapyDate.isBefore(dayjs(patient.dateOfBirth!)) ? 'Η ημερομηνία θεραπείας πρέπει να έπεται της ημερομηνία γέννησης.' : patient.dateOfDeath && therapyDate.isAfter(dayjs(patient.dateOfDeath)) ? 'Η ημερομηνία θεραπείας πρέπει να προηγείται της ημερομηνία θανάτου.' :''}
                        >
                            {isEditMode && <DatePicker disabledDate={(current)=>patient.dateOfDeath ? current.isAfter(dayjs(patient.dateOfDeath)): current.isAfter(dayjs())} format={DATE_FORMAT} value={therapyDate} allowClear={true} onChange={(newDate)=>setTherapyDate(newDate)}/>}
                            {!isEditMode && <div className='field-value-text'>{therapyDate ? therapyDate.format(DATE_FORMAT):"-"}</div>}
                        </Form.Item>
                </div>
                <div className='patient-fields-column'>
                    {(therapyKind === 'TTFN.SOL.002' || therapyKind === 'TTFN.SOL.012' || therapyKind === 'TTFN.HEM.002') && <Form.Item
                        label="Είδος ΧΜΘ"
                    >
                        {isEditMode && <Select options={availableChemotherapyTypes} allowClear={true} value={chemoTherapyType} onChange={(value) => {
                            setChemoTherapyType(value);
                        }}/>}
                        {!isEditMode && <div className='field-value-text'>{chemoTherapyType ? availableChemotherapyTypes.find(st=>st.value === chemoTherapyType)?.label ?? '-': '-'}</div>}
                    </Form.Item>}
                    {therapyKind === 'TTFN.SOL.006' && <Form.Item
                        label="Radiotherapy"
                    >
                        {isEditMode && <Select options={availableRadioTherapyTypes} allowClear={true} value={radioTherapyType} onChange={(value) => {
                            setRadioTherapyType(value);
                        }}/>}
                        {!isEditMode && <div className='field-value-text'>{radioTherapyType ? availableRadioTherapyTypes.find(st=>st.value === radioTherapyType)?.label ?? '-': '-'}</div>}
                    </Form.Item>}
                    {(therapyKind && therapyKind !== KeySolidNeoplasmSurgery && therapyKind !== KeyHematologicalNeoplasmSurgery && therapyKind !== 'TTFN.SOL.002' && therapyKind !== 'TTFN.SOL.012' && therapyKind !== 'TTFN.HEM.002' && therapyKind !== 'TTFN.SOL.006') && <Form.Item
                        label="Therapy Specification"
                    >
                        {isEditMode && <Select options={availableOtherTherapyTypes} allowClear={true} value={otherTherapyType} onChange={(value) => {
                            setOtherTherapyType(value);
                        }}/>}
                        {!isEditMode && <div className='field-value-text'>{otherTherapyType ? availableOtherTherapyTypes.find(st=>st.value === otherTherapyType)?.label ?? '-': '-'}</div>}
                    </Form.Item>}
                    {(therapyKind === KeySolidNeoplasmSurgery || therapyKind === KeyHematologicalNeoplasmSurgery) && <>
                    <Form.Item
                        label="Κωδικός πράξης χειρουργείου"
                    >
                        {isEditMode && <Select options={availableSurgeryEtiupCodes} allowClear={true} 
                            style={{ inlineSize: 250, height: "auto", wordWrap: "break-word" }}
                            showSearch filterOption={(input, option) => normalizeString(option?.label ?? '').toLowerCase().includes(normalizeString(input ??'').toLowerCase())}
                            value={surgeryEtipCode} onChange={(value) => {
                                setSurgerEtipCodes(value);
                        }}/>}
                        {!isEditMode && <div className='field-value-text'>{surgeryEtipCode ? availableSurgeryEtiupCodes.find(st=>st.value === surgeryEtipCode)?.label ?? '-': '-'}</div>}
                    </Form.Item>
                    </>}
                </div>
                <div className='patient-fields-column'>
                    {(therapyKind === KeySolidNeoplasmSurgery || therapyKind === KeyHematologicalNeoplasmSurgery) && <Form.Item
                        label="Χειρουργικά όρια"
                    >
                        {isEditMode && <Select options={availableSurgeryLimits} allowClear={true} value={surgeryLimitsType} onChange={(value) => {
                            setSurgerFreeLimitsType(value);
                        }}/>}
                        {!isEditMode && <div className='field-value-text'>{surgeryLimitsType ? availableSurgeryLimits.find(x => x.value === surgeryLimitsType)?.label ?? '-': '-'}</div>}
                    </Form.Item>}
                    {(therapyKind === KeySolidNeoplasmSurgery) && <>
                    <Form.Item
                        label="Συνολικός αριθμός εξαιρεθέντων λεμφαδένων"
                    >
                        {isEditMode && <InputNumber min={0} precision={0} value={surgeryExcludedLymphNodes} onChange={(value) => {
                            setSurgeryExcludedLymphNodes(value !== null ? value: undefined);
                        }}/>}
                        {!isEditMode && <div className='field-value-text'>{surgeryExcludedLymphNodes !== undefined ? surgeryExcludedLymphNodes : "-"}</div>}
                    </Form.Item>
                    <Form.Item
                        label="Αριθμός διηθημένων λεμφαδένων και η εντόπιση αυτών"
                    >
                        {isEditMode && <InputNumber min={0} precision={0} value={surgeryInfiltratedLymphNodes} onChange={(value) => {
                            setSurgeryInfiltratedLymphNodes(value !== null ? value: undefined);
                        }}/>}
                        {!isEditMode && <div className='field-value-text'>{surgeryInfiltratedLymphNodes !== undefined ? surgeryInfiltratedLymphNodes : "-"}</div>}
                    </Form.Item>
                    <Form.Item
                        label="Παρουσία εξωκαψικής λεμφαδενικής διήθησης"
                    >
                        {isEditMode && <Select options={availableYesNoTypes} allowClear={true} value={surgeryLymphInfiltrationExistenceType} onChange={(value) => {
                            setSurgeryLymphInfiltrationExistenceType(value);
                        }}/>}
                        {!isEditMode && <div className='field-value-text'>{surgeryLymphInfiltrationExistenceType ? availableYesNoTypes.find(yn=>yn.value === surgeryLymphInfiltrationExistenceType)?.label ?? '-': '-'}</div>}
                    </Form.Item>
                    <Form.Item
                        label="Παρουσία περινευριδιακής διήθησης"
                    >
                        {isEditMode && <Select options={availableYesNoTypes} allowClear={true} value={surgeryNevreInfiltrationExistenceType} onChange={(value) => {
                            setSurgeryNevreInfiltrationExistenceType(value);
                        }}/>}
                        {!isEditMode && <div className='field-value-text'>{surgeryNevreInfiltrationExistenceType ? availableYesNoTypes.find(yn=>yn.value === surgeryNevreInfiltrationExistenceType)?.label ?? '-': '-'}</div>}
                    </Form.Item>
                    </>}
                </div>
            </Form>
        </>
    );
    
}
export default TherapyComponent;