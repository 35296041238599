import { Alert, Avatar, Button, Card, Collapse, DatePicker, List, Modal, Progress, Spin, Typography } from 'antd';
import { MutableRefObject, ReactNode, useEffect, useRef, useState} from 'react';
import { EditOutlined, PlusOutlined, SaveOutlined, UndoOutlined } from '@ant-design/icons';
import { BaseEditableModel } from '../models/base-editable-model';
import { BaseFhirModel } from '../models/fhir/base-fhir-model';
import { generateFhirMessageSubmissionDescription, generateFhirMessageSubmissionTitle } from '../helpers/fhir-message-helper';
import { flatten, some } from 'lodash';
import dayjs from 'dayjs';
import { DATE_FORMAT } from '../constants';
import { Patient } from '../models/patient';


export interface ContentComponentParams {
    panelTitle:string;
    patient:Patient;
    initialValues:BaseEditableModel[];
    getComponents(key:string, isEditMode:boolean, initialValues?:BaseEditableModel[], onValueChange?: (itemIndex:number, newValue:BaseEditableModel, fhirMessages:BaseFhirModel[], isValid:boolean) => void) :ReactNode;
}

interface FieldsContainerParams<T extends ContentComponentParams> {
    initialIsPanelExpanded?:boolean;
    contentParams:T;
    isEditMode:boolean;
    isMessageSending: boolean;
    archivingProgress?: number;
    onSaveClick:(newValues?: BaseEditableModel[], messages?: any, completeCallback?:(isSuccessSave: boolean) => any) => void;
    onRevertClick:()=>void;
    onEditClick:()=>void;
    onAddClick?:()=>void;
    extra?: ReactNode;

    mode?: string;
}

export function FieldsContainerComponent<T extends ContentComponentParams >(params : FieldsContainerParams<T>) {
    const editingValues : MutableRefObject<BaseEditableModel[]|undefined> = useRef([...params.contentParams.initialValues?.map(v=>{ return {...v}})]);
    const fhirMessagesOfEditingValue : MutableRefObject<BaseFhirModel[][]> = useRef([[]]);

    const [contentKey, setContentKey] = useState<number>(1);
    const [isPanelExpanded, setIsPanelExpanded] = useState<boolean>(false);
    const [isContentValid, setIsContentValid] = useState<boolean>(false);
    const [isMessagesSummaryVerificationModalOpen, setIsMessageSummaryVerificationModalOpen] = useState<boolean>(false);
    const [commonOccuranceDate, setCommonOccuranceDate]=useState<dayjs.Dayjs>(params.contentParams.patient.dateOfDeath ? dayjs(params.contentParams.patient.dateOfDeath) : dayjs());

    useEffect(()=> {
        if (params.initialIsPanelExpanded) setIsPanelExpanded(true);
    }, []);
    const child = params.contentParams.getComponents(contentKey.toString(), params.isEditMode, params.contentParams.initialValues, (itemIndex, newValue, fhirMessages, isValid)=>{
        editingValues.current![itemIndex] = newValue;
        fhirMessagesOfEditingValue.current[itemIndex] = fhirMessages;
        setIsContentValid(isValid);
    });
    const title = params.contentParams.panelTitle;
    const isLoading = params.isMessageSending || params.archivingProgress !== undefined;
    const flatListOfFhirMessages = flatten(fhirMessagesOfEditingValue.current);
    flatListOfFhirMessages.forEach(m=> {
        m.occuredDate =  commonOccuranceDate.toDate();
    });
    const panelContent = <div key="1">
        <Spin spinning={isLoading} key="0">
            {child}
        </Spin>
        <Modal open={isMessagesSummaryVerificationModalOpen} key="1" title="Επιβεβαίωση νέων υποβολών" 
            destroyOnClose={true}
            closable={false} 
            width={800}
            onOk={()=>{
                setIsMessageSummaryVerificationModalOpen(false);
                params.onSaveClick(editingValues.current, flatListOfFhirMessages, (isSuccessSave:boolean)=> {
                    if (isSuccessSave) {
                        fhirMessagesOfEditingValue.current = [[]];
                        setCommonOccuranceDate(params.contentParams.patient.dateOfDeath ? dayjs(params.contentParams.patient.dateOfDeath) : dayjs());
                    }
                });
            }} 
            okText="Υποβολή και έλεγχος"
            cancelText="Άκυρο"
            onCancel={()=>{
                setCommonOccuranceDate(params.contentParams.patient.dateOfDeath ? dayjs(params.contentParams.patient.dateOfDeath) : dayjs());
                setIsMessageSummaryVerificationModalOpen(false);
            }}
            okButtonProps={{disabled:flatListOfFhirMessages.length===0}}
            >
            {flatListOfFhirMessages.length === 0 && <Alert type='error' description="Δεν έχετε προβεί σε αλλαγές, δεν υπάρχει κάτι να υποβάλλετε."/>}
            {flatListOfFhirMessages.length >= 1 && <Typography.Title level={5} style={{marginTop:0}}>Πρόκειται να αποστείλετε {flatListOfFhirMessages.length > 1 ? 'τις ακόλουθες υποβολές': 'την ακόλουθη υποβολή'} στο μητρώο</Typography.Title>}

            {(flatListOfFhirMessages.length >= 1 && some(flatListOfFhirMessages, fhirMessage=> fhirMessage.isOccuredDateVisible)) && <Alert type='info' description={<div>
                <div>Μπορείτε προαιρετικά να ορίσετε μια κοινή ημερομηνία καταγραφής για τις παρακάτω υποβολές</div>
                <div><DatePicker disabledDate={(current)=>params.contentParams.patient.dateOfDeath ? current.isAfter(dayjs(params.contentParams.patient.dateOfDeath)): current.isAfter(dayjs())} value={commonOccuranceDate} onChange={(newDate)=>setCommonOccuranceDate(newDate)} format={DATE_FORMAT} allowClear={false}/></div>

            </div>} />}
            
            <List style={{maxHeight:400,overflowY:'auto'}}>
                {flatListOfFhirMessages.map((message, index)=> <List.Item key={index}>
                <List.Item.Meta
                    title={generateFhirMessageSubmissionTitle(message)}
                    description={<div style={{whiteSpace:'pre-line'}}>{generateFhirMessageSubmissionDescription(message)}</div>}
                    avatar={<Avatar shape="square" size={50}>{index+1}</Avatar>}
                ></List.Item.Meta>
            </List.Item>)}
            </List>
            
        </Modal>
    </div>;

    const panelTitle = <div style={{display:'flex',flexDirection:'row'}}>
        <div key='title'>{title}</div> 
        <div key='progres' style={{flex:1, marginLeft:20, marginRight:40}}>{params.archivingProgress !== undefined && <Progress percent={params.archivingProgress!}/>}</div>
        {params.isEditMode && <div key='edit-mode' className='edit_mode_txt'>ΚΑΤΑΣΤΑΣΗ ΔΗΜΙΟΥΡΓΙΑΣ ΥΠΟΒΟΛΩΝ</div>}
    </div>;

    const panelExtra = [
        params.extra,
        params.isEditMode ? <Button disabled={isLoading} key='revert-button' size='small' icon={<UndoOutlined />} onClick={(e) => {
            e.stopPropagation();
            setContentKey(contentKey+1);
            setIsPanelExpanded(true);
            params.onRevertClick();
        }} /> : params.onAddClick ? <Button key='add-button' disabled={isLoading} size='small' icon={<PlusOutlined />} onClick={(e) =>{
            e.stopPropagation();
            params.onAddClick!();
        }}></Button> : undefined,
        params.isEditMode ? <Button disabled={isLoading || !isContentValid} key='save-button' size='small' icon={<SaveOutlined />} type='primary' onClick={(e)=>{
            e.stopPropagation();
            setIsPanelExpanded(true);
            setIsMessageSummaryVerificationModalOpen(true);
            
        }} /> :<Button disabled={isLoading || !params.contentParams.initialValues || !params.contentParams.initialValues.length} key='edit-button' size='small' icon={<EditOutlined />} type='primary' onClick={(e)=> {
            e.stopPropagation();
            setIsPanelExpanded(true);
            params.onEditClick();
        }} />
    ];

    const mode = params.mode === 'card' ? 'card' : 'collapse';

    if (mode === 'card')
        return <Card key='1' className={`hipaCollapse${params.isEditMode ? ' edit_mode' : ''}`} title={panelTitle} extra={panelExtra} children={panelContent}></Card>;

    return <Collapse className={`hipaCollapse${params.isEditMode ? ' edit_mode' : ''}`} activeKey={isPanelExpanded ? '1' : undefined} onChange={(key=>setIsPanelExpanded(key.length > 0))} items={[{ 
        key: '1', 
        label: panelTitle, 
        extra: panelExtra, 
        children: [panelContent]
    }]}/>;
}

export default FieldsContainerComponent;