import { useEffect, useState } from "react";
import { MultiTabContentComponentParams } from "./multi-tab-fields-container";
import { Empty, Form, Input, InputNumber, Select, Spin } from "antd";
import { BiomarkerContainerModel, BiomarkerModel } from "../models/biomarker-container-model";
import { BiomarkerObservation } from "../models/fhir/biomarker-observation";
import { Dictionary, groupBy } from "lodash";
import { BaseFhirModel } from "../models/fhir/base-fhir-model";
import { FhirResourceFriendlyName, FhirResourceName, isFieldValueDifferent } from "../helpers/fhir-message-helper";
import TerminologyService from "../services/terminology-service";
import Icdo3Service from "../services/icdo3-service";
import { Patient } from "../models/patient";
import { ValueSetOption, ValueSetOptions } from "../models/fhir/value-set";
import { forkJoin } from "rxjs";
import { Observation } from "../models/fhir/observation";
import { ObservationWireData } from "../models/observation-wire-data";

export class BiomarkerComponentParams implements MultiTabContentComponentParams {
    panelTitle: string = "Βιοδείκτες";
    initialValuesOfTabs: { tabTitle: string, initialValues: BiomarkerContainerModel[] }[] = [];
    constructor(public patient: Patient) { }
    getTabComponents(key: string, isEditMode: boolean, initialValues?: BiomarkerContainerModel[], onTabValueItemChange?: (itemIndex: number, newValue: BiomarkerContainerModel, fhirMessages: BaseFhirModel[], isValid: boolean) => void): React.ReactNode {
        if (!initialValues || initialValues.length === 0) return <Empty />
        return initialValues.map((initialValue, index) => <div key={`${key}-${index}`}><BiomarkerComponent isEditMode={isEditMode} initialValue={initialValue} patient={this.patient} onValueChange={(newValue, fhirMessages, isValid) => {
            if (onTabValueItemChange) onTabValueItemChange(index, newValue, fhirMessages, isValid);
        }} />
            {initialValues.length - 1 > index && <hr />}</div>);
    }
};


const BiomarkerComponent: React.FC<{
    isEditMode: boolean,
    initialValue: BiomarkerContainerModel,
    patient: Patient,
    onValueChange?: (newValue: BiomarkerContainerModel, fhirMessages: BaseFhirModel[], isValid: boolean) => void
}> = ({ isEditMode, initialValue, patient, onValueChange }) => {
    const icdo3DiagnosisValueSetOptions = TerminologyService.getValueSetOptions('DiagnosisICDO3');
    const availableBiomarkers = TerminologyService.getValueSetOptions('Biomarker');
    const icdo3Service = new Icdo3Service(icdo3DiagnosisValueSetOptions);
    const relatedDiagnoses = patient.diagnoses.filter(d => d.id === initialValue.conditionId || d.dueToDiagnosisId === initialValue.conditionId);
    const [isApplicableBiomarkersLoading, setIsApplicableBiomarkersLoading] = useState<boolean>(false);
    const [biomarkers, setBiomarkers] = useState<BiomarkerModel[]>(initialValue.biomarkers.map(b => ({biomarkerCode: b.biomarkerCode, biomarkerValue: b.biomarkerValue, properties: b.properties} as BiomarkerModel)));
    const [applicableBiomarkers, setApplicableBiomarkers] = useState<ValueSetOptions>([]);
    const [chunks, setChunks] = useState<any>({});


    useEffect(() => {
        const obsList$ = relatedDiagnoses.map(x => icdo3Service.getApplicableBiomarkersAndOrgan(icdo3Service.getDiagnosisByCode(x.icdO3DiagnosisCode!)));
        setIsApplicableBiomarkersLoading(true);
        forkJoin(obsList$)
            .subscribe({
                next(value) {
                    setIsApplicableBiomarkersLoading(false);
                    if(!value) return;
                    const biomarkers = value.flatMap(c => {
                        return c?.biomarkers?.map(a => 'Biomarker_' + a) ?? [];
                    });
                    const applicableBiomarkers = availableBiomarkers.filter(b => biomarkers.includes(b.value));
                    setApplicableBiomarkers(applicableBiomarkers);
                    
                    let ix = 0;
                    const chunks = groupBy(applicableBiomarkers, function (_: ValueSetOption) {
                        return Math.floor(ix++ % 3);
                    });
                    setChunks(chunks);
                },
                error(err) {
                    setIsApplicableBiomarkersLoading(false);
                },
            });
    }, []);


    useEffect(() => {
        if (onValueChange) {
            const fhirMessages: BaseFhirModel[] = [];
            applicableBiomarkers.forEach(biomarkerSpec => {
                const initialBiomarker = initialValue.biomarkers.find(b => b.biomarkerCode === biomarkerSpec?.value);
                const updatedBiomarker = biomarkers.find(b => b.biomarkerCode === biomarkerSpec?.value);
                if (!initialBiomarker && updatedBiomarker && updatedBiomarker.biomarkerValue) {
                    fhirMessages.push({
                        fhirResourceName: FhirResourceName.observation,
                        friendlyResourceName: FhirResourceFriendlyName.biomarker,
                        isOccuredDateVisible: true,
                        fieldName: biomarkerSpec?.label,
                        value: biomarkerSpec?.properties?.valueSet ? TerminologyService.getValueSetOption(biomarkerSpec?.properties?.valueSet!, updatedBiomarker.biomarkerValue as string)?.label : updatedBiomarker.biomarkerValue,
                        fieldId: biomarkerSpec?.value,
                        wireData: {
                            patientId: patient.id,
                            conditionId: initialValue.conditionId,
                            code: biomarkerSpec?.value,
                            valueCodeSystem: biomarkerSpec?.properties?.valueSet,
                            valueCode: biomarkerSpec?.properties?.valueSet ? updatedBiomarker.biomarkerValue : null,
                            valueQuantity: biomarkerSpec?.properties?.valueSet ? null : updatedBiomarker.biomarkerValue,
                            effectiveDateTime: new Date(),
                            effectiveDateTimeEnd: undefined,
                            issuedDate: new Date(),
                        } as ObservationWireData
                    } as BiomarkerObservation);
                }
                else if (initialBiomarker && updatedBiomarker &&  isFieldValueDifferent(initialBiomarker.biomarkerValue?.toString(), updatedBiomarker.biomarkerValue?.toString())) {
                    fhirMessages.push({
                        fhirResourceName: FhirResourceName.observation,
                        friendlyResourceName: FhirResourceFriendlyName.biomarker,
                        isOccuredDateVisible: true,
                        fieldName: biomarkerSpec?.label,
                        value: biomarkerSpec?.properties?.valueSet ? TerminologyService.getValueSetOption(biomarkerSpec?.properties?.valueSet!, updatedBiomarker.biomarkerValue as string)?.label : updatedBiomarker.biomarkerValue,
                        fieldId: biomarkerSpec?.value,
                        wireData: {
                            patientId: patient.id,
                            conditionId: initialValue.conditionId,
                            code: biomarkerSpec?.value,
                            valueCodeSystem: biomarkerSpec?.properties?.valueSet,
                            valueCode: biomarkerSpec?.properties?.valueSet ? updatedBiomarker.biomarkerValue : null,
                            valueQuantity: biomarkerSpec?.properties?.valueSet ? null : updatedBiomarker.biomarkerValue,
                            effectiveDateTime: new Date(),
                            effectiveDateTimeEnd: undefined,
                            issuedDate: new Date(),
                        } as ObservationWireData
                    } as BiomarkerObservation);
                }
            });

            const newBiomarkerViewModel = new BiomarkerContainerModel(initialValue.conditionId);
            newBiomarkerViewModel.biomarkers = biomarkers;
            onValueChange(newBiomarkerViewModel, fhirMessages, true);
        }
    }, [biomarkers, applicableBiomarkers]);

    return (
        <Spin spinning={isApplicableBiomarkersLoading}>
            <Form
                layout='vertical'
                style={{ display: 'flex', flexDirection: 'row' }}
                autoComplete="off"
            >
                {chunks && Object.keys(chunks).map(column => {
                    return <div key={column} className='patient-fields-column'>
                        {chunks && chunks[column] && chunks[column].map((biomarkerSpec: ValueSetOption) =>
                            <Form.Item key={biomarkerSpec?.value} label={biomarkerSpec?.label}>
                                {isEditMode && biomarkerSpec?.properties?.inputType === 'select' &&
                                    <Select
                                        options={TerminologyService.getValueSetOptions(biomarkerSpec?.properties.valueSet)}
                                        value={biomarkers.find(b => b.biomarkerCode === biomarkerSpec?.value)?.biomarkerValue}
                                        onChange={(newValue) => {
                                            const newBioMarkers = [...biomarkers];
                                            let target = newBioMarkers.find(b => b.biomarkerCode === biomarkerSpec?.value);
                                            if(!target) {
                                                target = {
                                                    biomarkerCode: biomarkerSpec?.value,
                                                } as BiomarkerModel;
                                                newBioMarkers.push(target);
                                            }
                                            target.biomarkerValue = newValue;
                                            setBiomarkers(newBioMarkers);
                                        }} />}
                                {isEditMode && biomarkerSpec?.properties?.inputType === 'integer' &&
                                    <InputNumber min={biomarkerSpec?.properties.range[0]} value={biomarkers.find(b => b.biomarkerCode === biomarkerSpec?.value)?.biomarkerValue} max={biomarkerSpec?.properties.range[1]} precision={0} onChange={(newValue) => {
                                        const newBioMarkers = [...biomarkers];
                                        let target = newBioMarkers.find(b => b.biomarkerCode === biomarkerSpec?.value);
                                        if(!target) {
                                            target = {
                                                biomarkerCode: biomarkerSpec?.value,
                                            } as BiomarkerModel;
                                            newBioMarkers.push(target);
                                        }
                                        target.biomarkerValue = newValue !== null ? newValue : undefined;
                                        setBiomarkers(newBioMarkers);
                                }} />}
                                {!isEditMode && biomarkerSpec?.properties?.inputType === 'select' &&
                                    <div className='field-value-text'>
                                        {biomarkerSpec?.properties?.valueSet ? TerminologyService.getValueSetOption(biomarkerSpec?.properties?.valueSet!, biomarkers.find(b => b.biomarkerCode === biomarkerSpec?.value)?.biomarkerValue as string)?.label ?? '-' : '-'}
                                    </div>
                                }
                                {!isEditMode && biomarkerSpec?.properties?.inputType === 'integer' &&
                                    <div className='field-value-text'>
                                        {biomarkers.find(b => b.biomarkerCode === biomarkerSpec?.value)?.biomarkerValue !== undefined ? biomarkers.find(b => b.biomarkerCode === biomarkerSpec?.value)?.biomarkerValue : '-'}
                                    </div>
                                }
                            </Form.Item>)}
                    </div>;
                })}
            </Form>
            {(!Object.keys(chunks).length && !isApplicableBiomarkersLoading) && <div><Empty description='Δεν μπορούν να καθοριστούν βιοδείκτες για αυτό το είδος νεοπλασίας' /></div>}
        </Spin>
    );

}
export default BiomarkerComponent;
