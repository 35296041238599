import { Button } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography } from 'antd';

const { Title, Paragraph } = Typography;


function ContactPage() {
    const navigate = useNavigate();
    
    return (
        <div className='intro_page'>
            <Title>Επικοινωνία</Title>
            <Paragraph>
                Περιεχόμενα σελίδας επικοινωνίας... helpdesk
            </Paragraph>

            
            <div>
                <Button type='primary' onClick={()=>navigate('/')}>Αρχική</Button>
            </div>
        </div>
    );
}

export default ContactPage;