import { Observable, of, delay, Subscription, throwError } from "rxjs";
import { map } from 'rxjs/operators';

class ObjectPropertyInfo {
	public readonly propValue: any;
	public readonly parentValue: any;

	constructor(propValue: any, parentValue: any) {
		this.propValue = propValue;
		this.parentValue = parentValue;
	}
}

class ObjectOperationsService {
    public getPropertyByPath(obj: object, path: string) : ObjectPropertyInfo|null {
        const split = this.dissectVariablePath(path);
        let current : any = obj;
        let parent : any = null;
        const len = split.length;
        for(let i=0; i<len; i++) {
            let prop: string = split[i];
			let regex = /^([\w\d]*)\[([^\]]+)\=([^\]]+)\]$/i;
			let match : RegExpExecArray|null;
            if(prop.indexOf('[') >= 0 && (match = regex.exec(prop))) {
                prop = match[1];
                const filterProp = match[2];
                const filterVal = match[3];
                
                if(prop) {
                    if(current && typeof current === 'object' && current[prop]) {
                        parent = current;
                        current = current[prop];
                    }
                    else {
                        return null;
                    }
                }
                else {
                    // Reuse current object.
                }

                if(Array.isArray(current) && current.length > 0) {
                    current = current
                        .filter((v) => {
                            const pv = this.getPropertyByPath(v, filterProp);
                            return pv?.propValue == filterVal;
                        });
                }
                else {
                    return null;
                }
            }
            else {
                if(current && typeof current === 'object' && current[prop]) {
                    parent = current;
                    current = current[prop];
                }
                else {
                    return null;
                }
            }
        }
        return new ObjectPropertyInfo(current, parent);
    }
	
    protected dissectVariablePath(path: string) : Array<string> {
        const len = path.length;
        let insideFilter = false;
        let ret = [];
        let buffer = [];
        for (let i = 0; i < len; i++) {
            let ch = path[i];
            if(insideFilter) {
                buffer.push(ch);
                if(ch === ']') insideFilter = false;
            } else {
                if(ch === '.') {
                    ret.push(buffer.join(''));
                    buffer = [];
                } else {
                    buffer.push(ch);
                }

                if(ch === '[') insideFilter = true;
            }
            if(i === len - 1 && buffer.length > 0) {
				ret.push(buffer.join(''));
            }
        }

        return ret;
    }
}

export default ObjectOperationsService;
