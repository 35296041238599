export interface SmokingProductType {
    name:string;
    cigarretesEquivalent:number;
    per:string;
    dayEquivalent:number;
    defaultAmountPerDay:number;
    defaultYears:number;
}

const availableSmokingProducts : SmokingProductType[] = [
    { name: 'Τσιγάρο', cigarretesEquivalent: 1, per: 'ημέρα', dayEquivalent: 1, defaultAmountPerDay: 20, defaultYears: 1},
    { name: 'Πούρο', cigarretesEquivalent: 4, per: 'ημέρα', dayEquivalent: 1, defaultAmountPerDay: 20, defaultYears: 1},
    { name: 'Σιγαρέτο', cigarretesEquivalent: 2, per: 'ημέρα', dayEquivalent: 1, defaultAmountPerDay: 20, defaultYears: 1},
    { name: 'Πίπα (αριθμός "δοχείων")', cigarretesEquivalent: 2.5, per: 'ημέρα', dayEquivalent: 1, defaultAmountPerDay: 20, defaultYears: 1},
    { name: 'Καπνός (γραμμάρια)', cigarretesEquivalent: 2, per: 'εβδομάδα', dayEquivalent: 7, defaultAmountPerDay: 140, defaultYears: 1}
];

function calculatePackYear(amountPerDay:number, years:number, dayEquivalent:number, cigarretesEquivalent:number): number {
return (amountPerDay / dayEquivalent) * years / (20.0 / cigarretesEquivalent);
}

export {availableSmokingProducts, calculatePackYear};