import { BaseEditableModel } from "./base-editable-model";

export class Attendant implements BaseEditableModel {
    firstName?:string;
    lastName?:string;
    address?:string;
    phone1?:string;
    phone2?:string;
    email1?:string;
    email2?:string;

    public infoToString? = ():string=> [
        `Όνομα: ${this.firstName ?? '-'}`, 
        `Επώνυμο: ${this.lastName ?? '-'}`, 
        `Διεύθυνση: ${this.address ?? '-'}`, 
        `Τηλέφωνο 1: ${this.phone1 ?? '-'}`,
        `Τηλέφωνο 2: ${this.phone2 ?? '-'}`,
        `Email 1: ${this.email1 ?? '-'}`,
        `Email 2: ${this.email2 ?? '-'}`,
    ].join("\r\n");
}