import _ from 'lodash';
import dayjs from 'dayjs';
import { Button, Checkbox, DatePicker, Divider, Empty, Form, Input, Select, Space, Spin, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { DATE_FORMAT } from '../constants';
import { MultiTabContentComponentParams } from './multi-tab-fields-container';
import { Diagnosis } from '../models/diagnosis';
import { Diagnosis as FhirDiagnosis } from '../models/fhir/diagnosis';
import { BaseFhirModel } from '../models/fhir/base-fhir-model';
import { FhirResourceFriendlyName, FhirResourceName, isFieldValueDifferent } from '../helpers/fhir-message-helper';
import TerminologyService from '../services/terminology-service';
import Icdo3Service from '../services/icdo3-service';
import { ValueSetOption, ValueSetOptions } from '../models/fhir/value-set';
import { Patient } from '../models/patient';
import MetastaticSiteService from '../services/metastatic-site-service';
import { NeoplasmType } from '../enums/neoplasm-type-enum';
import gradeImage from '../icd-o-3 grade.png';
import topohgraphyImage from '../icd-o-3 topography.png';
import morpholygImage from '../icd-o-3 morphology.png';
import { asyncScheduler } from 'rxjs';
import { normalizeString } from '../helpers/string-helpers';

export class DiagnosisComponentParams implements MultiTabContentComponentParams  {
    panelTitle: string = "Διάγνωση νόσου";
    initialValuesOfTabs:{tabTitle:string, initialValues:Diagnosis[]}[]=[];
    constructor(public patient:Patient) {}
    getTabComponents(key:string, isEditMode:boolean, initialValues?:Diagnosis[],  onTabValueItemChange?: (itemIndex:number, newValue:Diagnosis, fhirMessages:BaseFhirModel[], isValid:boolean) => void) : React.ReactNode {
        const icdo3DiagnosisValueSetOptions = TerminologyService.getValueSetOptions('DiagnosisICDO3');
        const icdo3Service = new Icdo3Service(icdo3DiagnosisValueSetOptions);

        if (!initialValues || initialValues.length === 0) return <Empty/>
        const metastaticSites = TerminologyService.getValueSetOptions('MetastaticSite');
        return initialValues.map((initialValue,index)=> <div key={`${key}-${index}`}>
        <Divider key={`divider-${key}`} orientation="left">{initialValue.icdO3DiagnosisCode ? icdo3Service.getDiagnosisByCode(initialValue.icdO3DiagnosisCode)?.toDisplayText() : (metastaticSites.find((item: ValueSetOption) => item.value === initialValue.metastasisSiteCode)?.label + " (μετάσταση)")}</Divider>
        <DiagnosisComponent key={`component-${key}`} isAddingNew={false} isEditMode={isEditMode} initialValue={initialValue} patient={this.patient} onValueChange={(newValue, fhirMessages, isValid) => {
            if (onTabValueItemChange) onTabValueItemChange(index, newValue, fhirMessages, isValid);
        }} onAddNeoplasmClick={(newDiagnosis)=> {
            if (this.onAddNeoplasmClick) this.onAddNeoplasmClick(newDiagnosis);
        }}/></div>);
    }
    onAddNeoplasmClick?:(diagnosis:Diagnosis)=>void;
};


const DiagnosisComponent: React.FC<{
    isEditMode:boolean,
    initialValue: Diagnosis,
    isAddingNew:boolean,
    patient: Patient,
    onValueChange?:(newValue: Diagnosis, fhirMessages:BaseFhirModel[], isValid:boolean) => void,
    onAddNeoplasmClick?:(newDiagnosis:Diagnosis)=>void }> = ({ isEditMode, isAddingNew, initialValue, patient, onValueChange, onAddNeoplasmClick }) => {
        if (!patient.dateOfBirth || !patient.gender) throw Error("Date of birth and Gender are required for the component to validate");
    
        const availableBodySiteLocations = TerminologyService.getValueSetOptions('BodySiteLocation');
        const availableMetastaticSites = TerminologyService.getValueSetOptions('MetastaticSite');
            
        const availableMethodsOfDiagnosis = TerminologyService.getValueSetOptions('BasisOfDiagnosis');
        const availableMethodsOfDiagnosisForSolidNeoplasm = availableMethodsOfDiagnosis.filter(it => (it.value as string).indexOf('SDM') !== -1);
        const availableMethodsOfDiagnosisForHematologicalNeoplasm = availableMethodsOfDiagnosis.filter(it => (it.value as string).indexOf('HDM') !== -1);

        const icdo3DiagnosisValueSetOptions = TerminologyService.getValueSetOptions('DiagnosisICDO3');
        const icdo3Service = new Icdo3Service(icdo3DiagnosisValueSetOptions);
        const allTopologies = icdo3Service.getAllTumorsTopologyList();
        
        const [neoplasmType, setNeoplasmType] = useState<NeoplasmType|undefined>(undefined);
        const [isSimpleMetastasisReference, setIsSimpleMetastasisReference] = useState<boolean|undefined>(initialValue.incidentType === 'IT.002' && !initialValue.icdO3DiagnosisCode);
        const [metastasisSiteType, setMetastasisSiteType] = useState<string|undefined>(initialValue.metastasisSiteCode);
        const [activeTopography, setActiveTopography] = useState<string|undefined>(initialValue.icdO3DiagnosisCode ? icdo3Service.getDiagnosisByCode(initialValue.icdO3DiagnosisCode)?.topographyCode : undefined);
        const [activeHistologyBehavior, setActiveHistologyBehavior] = useState<string|undefined>(initialValue.icdO3DiagnosisCode ? icdo3Service.getDiagnosisByCode(initialValue.icdO3DiagnosisCode)?.histologyAndBehaviorCode : undefined);
        const [gradeType, setGradeType] = useState<string|undefined>(initialValue.icdO3GradeCode);
        const [availableGradeTypes, setAvailableGradeTypes] = useState<ValueSetOptions|undefined>();
        const [diagnosisDate, setDiagnosisDate] = useState<dayjs.Dayjs|undefined>(initialValue.diagnosisDate ? dayjs(initialValue.diagnosisDate!) : undefined);
        const [bodySiteLocation, setBodySiteLocationType] = useState<string|undefined>(initialValue.bodySiteLocationCode);
        const [availableNeoplasmDiagnosisMethodTypes, setAvailableNeoplasmDiagnosisMethodTypes] = useState<ValueSetOptions|undefined>();
        const [diagnosisMethodType, setDiagnosisMethodType] = useState<string|undefined>(initialValue.diagnosisMethodCode);
        const [icdo10Map, setIcdo10Map] = useState<string|undefined>(undefined);
        const [isIcdo10MapLoading, setIsIcdo10MapLoading] = useState<boolean>(false);

        const [activeHistologyOptions, setActiveHistologyOptions] = useState<ValueSetOptions|undefined>();
        const allTumorGradeOptions = TerminologyService.getValueSetOptions('IcdO3Grade');
        const solidTumorGradeOptions : ValueSetOptions = allTumorGradeOptions.filter(x => x.value.includes('SOL'));
        const hematologicalTumorGradeOptions: ValueSetOptions =  allTumorGradeOptions.filter(x => x.value.includes('HEM'));
        
        const updateActiveHistologyBehaviorOptions = (newTopography: string) => {
            let options = icdo3Service.getAllTumorsList().filter(x => x.topographyCode === newTopography);
            options = _.uniqBy(options, x => x.histologyAndBehaviorCode);
            const selectOptions = options.map(x => ({label: x.histologyAndBehaviorDescription, value: x.histologyAndBehaviorCode}));
            setActiveHistologyOptions(selectOptions);
        };

        useEffect(()=> {
            if ((isEditMode || isAddingNew) && !diagnosisDate) {
                setDiagnosisDate(patient.dateOfDeath ? dayjs(patient.dateOfDeath) : dayjs());
            }
        }, [isEditMode, isAddingNew]);

        useEffect(()=> {
            if(activeTopography && activeHistologyBehavior) {
                setNeoplasmType(icdo3Service.getDiagnosisByCodes(activeTopography, activeHistologyBehavior)?.neoplasmType);
            }
            else if(metastasisSiteType) {
                setNeoplasmType(MetastaticSiteService.getNeoplasmType(metastasisSiteType));
            }
            else {
                setNeoplasmType(undefined);
            }
        }, [activeTopography, activeHistologyBehavior, metastasisSiteType]);

        useEffect(()=> {
            switch(neoplasmType) {
                case NeoplasmType.Solid:
                    setAvailableNeoplasmDiagnosisMethodTypes(availableMethodsOfDiagnosisForSolidNeoplasm);
                    setAvailableGradeTypes(solidTumorGradeOptions);
                    break;
                case NeoplasmType.Hematological:
                    setAvailableNeoplasmDiagnosisMethodTypes(availableMethodsOfDiagnosisForHematologicalNeoplasm);
                    setAvailableGradeTypes(hematologicalTumorGradeOptions);
                    break;
                case NeoplasmType.Both:
                    setAvailableNeoplasmDiagnosisMethodTypes(availableMethodsOfDiagnosis);
                    setAvailableGradeTypes(allTumorGradeOptions);
                    break;
            }
        }, [neoplasmType]);

        useEffect(()=> {
            const value$ = icdo3Service.getIcd10EquivalentString(icdo3Service.getDiagnosisByCodes(activeTopography!, activeHistologyBehavior!))
            setIsIcdo10MapLoading(true);
            value$.subscribe({
                next:(value) =>{
                    setIsIcdo10MapLoading(false);
                    setIcdo10Map(value);
                },
                error:()=>{
                    setIsIcdo10MapLoading(false);
                    setIcdo10Map(undefined);
                }
            });
        }, [activeTopography, activeHistologyBehavior]);

        useEffect(()=> {
            if (onValueChange){ 
                const fhirMessages: BaseFhirModel[] = [];
                if (
                    isFieldValueDifferent(initialValue?.metastasisSiteCode, metastasisSiteType) ||
                    isFieldValueDifferent(initialValue?.icdO3DiagnosisCode, activeTopography && activeHistologyBehavior ? icdo3Service.getDiagnosisByCodes(activeTopography, activeHistologyBehavior)?.code : undefined) ||
                    isFieldValueDifferent(initialValue?.icdO3GradeCode, gradeType) ||
                    !dayjs(initialValue?.diagnosisDate).isSame(diagnosisDate, 'day') ||
                    isFieldValueDifferent(initialValue?.bodySiteLocationCode, bodySiteLocation) ||
                    isFieldValueDifferent(initialValue?.diagnosisMethodCode, diagnosisMethodType)
                ) {

                    fhirMessages.push({
                        id: initialValue?.id,
                        fhirResourceName:FhirResourceName.diagnosis,
                        friendlyResourceName:FhirResourceFriendlyName.diagnosis, 
                        incidentType: initialValue.incidentType,
                        metastasisSiteType,
                        icdO3DiagnosisType:activeTopography && activeHistologyBehavior ? icdo3Service.getDiagnosisByCodes(activeTopography, activeHistologyBehavior)?.code : undefined,
                        gradeType,
                        diagnosisDate:diagnosisDate?diagnosisDate.toDate():undefined,
                        bodySiteLocationType: bodySiteLocation,
                        diagnosisMethodType,
                        isOccuredDateVisible:false,
                        occuredDate: diagnosisDate ? diagnosisDate.toDate(): undefined
                    } as FhirDiagnosis);
                }
                onValueChange({
                    id: initialValue.id,
                    incidentType: initialValue.incidentType,
                    metastasisSiteCode: metastasisSiteType,
                    icdO3DiagnosisCode : activeTopography && activeHistologyBehavior ? icdo3Service.getDiagnosisByCodes(activeTopography, activeHistologyBehavior)?.code : undefined,
                    icdO3GradeCode: gradeType,
                    diagnosisDate:diagnosisDate?diagnosisDate.toDate():undefined,
                    bodySiteLocationCode: bodySiteLocation,
                    diagnosisMethodCode: diagnosisMethodType,
                    dueToDiagnosisId: initialValue.dueToDiagnosisId,
                },fhirMessages, validateRequiredField(isSimpleMetastasisReference, activeTopography, activeHistologyBehavior, metastasisSiteType, diagnosisDate) && validateDiagnosisDate(diagnosisDate));
            }
        }, [
            metastasisSiteType,
            activeTopography,
            activeHistologyBehavior,
            gradeType,
            diagnosisDate,
            bodySiteLocation,
            diagnosisMethodType,
        ]);

        const validateRequiredField = (isSimpleMetastasisReference?:boolean, topologyType?:string, histologyBehaviourType?:string, metastasisSiteType?:string, diagnosisDate?:dayjs.Dayjs) : boolean=>{
            if (isSimpleMetastasisReference) {
                return (metastasisSiteType !== undefined && diagnosisDate !== undefined);
            }
            if (!topologyType || !histologyBehaviourType || !diagnosisDate) return false;
            return true;
        }

        const validateDiagnosisDate = (diagnosisDate:dayjs.Dayjs|undefined) : boolean => {
            if (!diagnosisDate) return false;
            if (diagnosisDate.isBefore(dayjs(patient.dateOfBirth!))) return false;
            if (patient.dateOfDeath && diagnosisDate.isAfter(dayjs(patient.dateOfDeath!))) return false;
            if (initialValue.incidentType !== 'IT.001' && dayjs(patient.diagnoses.find(d=>d.id === initialValue.dueToDiagnosisId)?.diagnosisDate).isAfter(diagnosisDate)) return false;
            return true;
        }

        const selectStyle = {minWidth:350, maxWidth:350, whiteSpace:'pre-wrap', width:'100%'} as React.CSSProperties;

        return (
            <>
                <Form
                    layout='vertical'
                    style={{ display: 'flex', flexDirection:'row' }}
                    autoComplete="off"
                >
                        <div className='patient-fields-column'>
                            <Form.Item
                                required={isEditMode || isAddingNew}
                                label={<span style={{fontWeight:!diagnosisDate?'bolder':'normal'}}>Ημερομηνία διάγνωσης</span>}
                                validateStatus={!diagnosisDate ? 'error' : diagnosisDate.isBefore(dayjs(patient.dateOfBirth!)) ? 'error' :  patient.dateOfDeath && diagnosisDate.isAfter(dayjs(patient.dateOfDeath!)) ? 'error' : (initialValue.incidentType !== 'IT.001' && dayjs(patient.diagnoses.find(d=>d.id === initialValue.dueToDiagnosisId)?.diagnosisDate).isAfter(diagnosisDate)) ? 'error' : 'success'}
                                help={!diagnosisDate ? 'Το πεδίο απαιτείται': diagnosisDate.isBefore(dayjs(patient.dateOfBirth!)) ? 'Η ημερομηνία διάγνωσης πρέπει να έπεται της ημερομηνία γέννησης.' : patient.dateOfDeath && diagnosisDate.isAfter(dayjs(patient.dateOfDeath)) ? 'Η ημερομηνία διάγνωσης πρέπει να προηγείται της ημερομηνία θανάτου.': (initialValue.incidentType !== 'IT.001' && dayjs(patient.diagnoses.find(d=>d.id === initialValue.dueToDiagnosisId)?.diagnosisDate).isAfter(diagnosisDate)) ? 'Η ημερομηνία διάγνωσης δεν μπορεί να έπεται της ημερομηνίας διάγνωσης του πρωτοπαθούς νεοπλάσματος':''}
                            >
                                {isEditMode && <DatePicker disabledDate={(current)=>patient.dateOfDeath ? current.isAfter(dayjs(patient.dateOfDeath)): current.isAfter(dayjs())} format={DATE_FORMAT} value={diagnosisDate} allowClear={true} onChange={(newDate)=>setDiagnosisDate(newDate)}/>}
                                {!isEditMode && <div className='field-value-text'>{diagnosisDate ? (diagnosisDate.format(DATE_FORMAT) + ' (ηλικία:' + (dayjs(diagnosisDate).diff(patient.dateOfBirth, 'years')+1)  + ')'):"-"}</div>}
                            </Form.Item>
                            {initialValue.dueToDiagnosisId && <Form.Item
                                label="Πρωτοπαθές νεόπλασμα">
                                    <div className='field-value-text'>{patient.diagnoses.find(d=>d.id === initialValue.dueToDiagnosisId)?.icdO3DiagnosisCode}</div>
                            </Form.Item>}

                            
                            <Form.Item>
                                {(!isEditMode && initialValue.incidentType === 'IT.001') && <><Space direction='vertical'>
                                    {(neoplasmType === NeoplasmType.Solid || neoplasmType === NeoplasmType.Both) && <a className='card_links_hipa' onClick={() => {
                                        if (onAddNeoplasmClick) onAddNeoplasmClick({
                                            incidentType:'IT.002',
                                            dueToDiagnosisId: initialValue.id
                                        });
                                    }}>Προσθήκη μεταστατικού νεοπλάσματος</a>}
                                    {(neoplasmType === NeoplasmType.Hematological || neoplasmType === NeoplasmType.Both) && <a className='card_links_hipa' onClick={() => {
                                        if (onAddNeoplasmClick) onAddNeoplasmClick({
                                            incidentType:'IT.004',
                                            dueToDiagnosisId: initialValue.id
                                        });
                                    }}>Προσθήκη μετασχηματισμού νεοπλάσματος</a>}
                                </Space></>}
                            </Form.Item>
                        </div>
                        <div className='patient-fields-column'>
                            {!isSimpleMetastasisReference && <>
                                <Form.Item
                                    required={isEditMode || isAddingNew}
                                    label={<Tooltip overlayInnerStyle={{width:415}} placement='right' title={<img src={topohgraphyImage}></img>}><span style={{fontWeight:!activeTopography?'bolder':'normal'}}>Τοπογραφία (ICD-O-3 topography)</span></Tooltip>}
                                    validateStatus={!activeTopography ? 'error' : 'success'}
                                    help={!activeTopography ? 'Το πεδίο απαιτείται': ''}
                                >
                                    {isAddingNew && <Select placeholder={'Αναζητήστε και επιλέξτε'} showSearch style={selectStyle}
                                        options={allTopologies}
                                        allowClear={true} value={activeTopography}
                                        onChange={(value) => {
                                            setActiveTopography(value);
                                            setActiveHistologyBehavior(undefined);
                                            setGradeType(undefined);
                                            setDiagnosisMethodType(undefined);
                                            setBodySiteLocationType(undefined);
                                            updateActiveHistologyBehaviorOptions(value);
                                        }}
                                        filterOption={(input, option) => normalizeString(option?.label?.toString() ?? '').toLowerCase().includes(normalizeString(input ?? '').toLowerCase())}
                                    />}
                                    {(!isAddingNew) && <div className='field-value-text'>{initialValue.icdO3DiagnosisCode ? icdo3Service.getDiagnosisByCode(initialValue.icdO3DiagnosisCode)?.topographyDescription  : '-'}</div>}
                                </Form.Item>

                                
                                <Form.Item
                                    required={isEditMode || isAddingNew}
                                    label={<Tooltip overlayInnerStyle={{width:878}} placement='right' title={<img src={morpholygImage}></img>}><span style={{fontWeight:(activeTopography && !activeHistologyBehavior)?'bolder':'normal'}}>Μορφολογία (ICD-O-3 morphology)</span></Tooltip>}
                                    validateStatus={!activeHistologyBehavior ? 'error' : 'success'}
                                    help={!activeHistologyBehavior ? 'Το πεδίο απαιτείται': ''}
                                >
                                    {isAddingNew && <Select placeholder={!activeTopography ? 'Επιλέξτε πρώτα την τοπογραφία - εστία' : 'Επιλέξτε'} style={selectStyle}
                                        allowClear showSearch filterOption={(input, option) => normalizeString(option?.label?.toString() ?? '').toLowerCase().includes(normalizeString(input ?? '').toLowerCase())}
                                        options={activeHistologyOptions} disabled={!activeTopography} value={activeHistologyBehavior} onChange={(entry)=>{
                                        setActiveHistologyBehavior(entry);
                                        setGradeType(undefined);
                                        setBodySiteLocationType(undefined);
                                        setDiagnosisMethodType(undefined);
                                    }}/>}
                                    {!isAddingNew && <div className='field-value-text'>{initialValue.icdO3DiagnosisCode ? icdo3Service.getDiagnosisByCode(initialValue.icdO3DiagnosisCode)?.histologyAndBehaviorDescription  : '-'}</div>}
                                </Form.Item>

                                <Form.Item
                                    label={<Tooltip overlayInnerStyle={{width:370}} placement='right' title={<img src={gradeImage}></img>}>{neoplasmType === NeoplasmType.Solid ? '6o ψηφίο για την ιστολογική ταξινόμηση και διαφοροποίηση (grade)' : '6ο ψηφίο κωδικοποίησης για τον προσδιορισμό του ανοσοφαινοτύπου για λεμφώματα και λευχαιμίες'}</Tooltip>}
                                >
                                    {isEditMode && <Select placeholder={!activeHistologyBehavior ? 'Επιλέξτε πρώτα τη μορφολογία' : 'Επιλέξτε'} style={selectStyle} disabled={!activeHistologyBehavior} options={availableGradeTypes} value={gradeType} onChange={(e)=>{
                                        setGradeType(e);
                                    }}/>}
                                    {!isEditMode && <div className='field-value-text'>{TerminologyService.getValueSetOption('IcdO3Grade', gradeType)?.label ?? '-'}</div>}
                                </Form.Item>
                                {/* {(initialValue.incidentType === 'IT.002' && isEditMode) && <>
                                <a onClick={()=>{
                                        setIsSimpleMetastasisReference(true);
                                        setActiveTopography(undefined);
                                        setActiveHistologyBehavior(undefined);
                                        setGradeType(undefined);
                                        setMetastasisSiteType(undefined);
                                        setDiagnosisMethodType(undefined);
                                    }}> Εναλλαγή σε καταγραφή οργάνου</a>
                                </>} */}
                                {activeTopography && activeHistologyBehavior && <Spin spinning={isIcdo10MapLoading}><Form.Item
                                    label="ICD-10 Χαρακτηρισμός"
                                >
                                    <div className='field-value-text'>{icdo10Map}</div>
                                </Form.Item></Spin>}
                            </>}
                            {isSimpleMetastasisReference && <>
                                <Form.Item
                                    label="Τοποθεσία μετάστασης"
                                    validateStatus={!metastasisSiteType ? 'error' : 'success'}
                                    help={!metastasisSiteType ? 'Το πεδίο απαιτείται': ''}
                                >
                                    {isAddingNew && <Select options={availableMetastaticSites} allowClear={true} value={metastasisSiteType} style={selectStyle} onChange={(value) => {
                                        setMetastasisSiteType(value);
                                        setDiagnosisMethodType(undefined);
                                    }}/>}
                                    {!isAddingNew && <div className='field-value-text'>{initialValue.metastasisSiteCode ? availableMetastaticSites.find(entry=>entry.value === initialValue.metastasisSiteCode)?.label  : '-'}</div>}
                                </Form.Item>
                                {/* {isAddingNew && <div><a onClick={()=>{
                                    setIsSimpleMetastasisReference(false);
                                    setActiveTopography(undefined);
                                    setActiveHistologyBehavior(undefined);
                                    setGradeType(undefined);
                                    setMetastasisSiteType(undefined);
                                    setDiagnosisMethodType(undefined);
                                }}> Εναλλαγή σε ICD-O-3 καταγραφή</a></div>} */}
                            </>}
                        </div>
                        <div className='patient-fields-column'>
                            {(initialValue.incidentType !== 'IT.002' && neoplasmType === NeoplasmType.Solid && topographisWithLaterality.find(t=>activeTopography?.startsWith(t))!==undefined) && <Form.Item
                                label="Θέση συμπαγούς όγκου"
                            >
                                {isEditMode && <Select placeholder="Επιλέξτε θέση συμπαγούς όγκου" style={selectStyle} options={availableBodySiteLocations} allowClear={true} value={bodySiteLocation} onChange={(value) => {
                                    setBodySiteLocationType(value);
                                }}/>}
                                {!isEditMode && <div className='field-value-text'>{bodySiteLocation ?  availableBodySiteLocations.find(l=>l.value === bodySiteLocation)?.label : "-"}</div>}
                            </Form.Item>}

                            <Form.Item
                                label="Μέθοδος διάγνωσης"
                            >
                                {isEditMode && <Select placeholder={!neoplasmType ? 'Επιλέξτε πρώτα είδος νεοπλάσματος' : 'Επιλέξτε μέθοδο διάγνωσης'} style={selectStyle} options={availableNeoplasmDiagnosisMethodTypes} allowClear={true} value={diagnosisMethodType} onChange={(value) => {
                                    setDiagnosisMethodType(value);
                                }}/>}
                                {!isEditMode && <div className='field-value-text'>{diagnosisMethodType ? availableMethodsOfDiagnosis.find(m => m.value === diagnosisMethodType)?.label : '-'}</div>}
                            </Form.Item>
                        </div>

                </Form>
            </>
        );

}
export default DiagnosisComponent;

const topographisWithLaterality = [
    "C07",
    "C09",
    "C30",
    "C34",
    "C384",
    "C40",
    "C401",
    "C402",
    "C403",
    "C413",
    "C414",
    "C441",
    "C442",
    "C446",
    "C447",
    "C50",
    "C56",
    "C570",
    "C62",
    "C630",
    "C64",
    "C65",
    "C66",
    "C69",
    "C74",
    "C700",
    "C701",
    "C709",
    "C710",
    "C711",
    "C712",
    "C713",
    "C714",
    "C715",
    "C716",
    "C717",
    "C718",
    "C719",
    "C720",
    "C721",
    "C722",
    "C723",
    "C724",
    "C725",
    "C728",
    "C729"
]