import { Alert, Button, Card, Form, FormProps, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthService, { user$ } from '../services/auth-service';

type FieldType = {
    organizationUnitId?: number;
};

function SelectActiveOrganizationPage() {
    const navigate = useNavigate();
    const authService = AuthService.getInstance();
    
    const onFormSubmit: FormProps<FieldType>['onFinish'] = (values) => {
        if (!values.organizationUnitId) return;
        authService.setActiveOrganization(values.organizationUnitId!)
        navigate(`/amka-search`);
    };

    return (
        <>
            <div style={{display:'flex',flexDirection:'column',alignItems: "center",justifyContent: "center",height:"100%"}}>
                <Card className='hipa_card_shadow' title="Επιλογή τρέχουσας μονάδας παροχής υπηρεσιών υγείας" style={{ width: 800 }}>
                    {(authService.getCurrentUser().organizations && authService.getCurrentUser().organizations.length) ? <Form
                        layout='vertical'
                        initialValues={{
                            ["organizationUnitId"]: authService.getActiveOrganization() ? authService.getActiveOrganization()?.id : null
                          }}
                        onFinish={onFormSubmit}
                        autoComplete="off"
                    >
                        <Form.Item<FieldType>
                            label="Μονάδα υπηρεσίας υγείας"
                            name="organizationUnitId"
                            rules={[
                                { required: true, message: 'Επιλέξτε ιατρείο, κλινική, εργαστήριο' }
                            ]}
                        >
                            <Select placeholder="Επιλέξτε μονάδα παροχής υπηρεσιών υγείας" options={authService.getCurrentUser().organizations.map(o => {
                                return {value:o.id, label:`${o.name} (${o.sector.name})`}
                            })}  allowClear={true}/>
                        </Form.Item>

                        

                        <div style={{textAlign:'center'}}>
                            <Button type="primary" htmlType="submit">
                                Επιλογή
                            </Button>
                        </div>
                    </Form>: <Alert type='error' description='Δεν έχετε συσχετιστεί με κάποια μονάδα παροχής υγείας.'/>}
                </Card>
            </div>
        </>
    );
}

export default SelectActiveOrganizationPage;