import dayjs from "dayjs";
import { BaseEditableModel } from "./base-editable-model";
import { DATE_FORMAT } from "../constants";
export class PathologoReport implements BaseEditableModel {
    id: string|null;

    rawText?: string;
    reportDate?: Date;
    laboratory?: string;


    constructor(id: string|null) {
        this.id = id;
    }

    public toString? = ():string => [
        `Ημερομηνία: ${this.reportDate ? dayjs(this.reportDate).format(DATE_FORMAT) : '-'}`,
        `Εργαστήριο: ${this.laboratory ?? '-'}`,
        `Κείμενο: ${this.rawText ?? '-'}`,
    ].filter(s=>s).join("\r\n");
}