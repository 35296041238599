import { useEffect, useState } from "react";
import { MultiTabContentComponentParams } from "./multi-tab-fields-container";
import { DatePicker, Empty, Form, Select } from "antd";
import { BaseFhirModel } from "../models/fhir/base-fhir-model";
import { FhirResourceFriendlyName, FhirResourceName, isFieldValueDifferent } from "../helpers/fhir-message-helper";
import { Progress } from "../models/progress";
import dayjs from "dayjs";
import { Observation } from "../models/fhir/observation";
import { ObservationWireData as ObservationModel } from "../models/observation-wire-data";
import { DATE_FORMAT } from "../constants";
import TerminologyService from "../services/terminology-service";
import { Patient } from "../models/patient";

export class ProgressComponentParams implements MultiTabContentComponentParams {
    constructor(public readonly patient:Patient) {}
    panelTitle: string = "Καταγραφή Κατάστασης Νόσου";
    initialValuesOfTabs: { tabTitle: string, initialValues: Progress[] }[] = [];
    getTabComponents(key: string, isEditMode: boolean, initialValues?: Progress[], onTabValueItemChange?: (itemIndex: number, newValue: Progress, fhirMessages: BaseFhirModel[], isValid: boolean) => void): React.ReactNode {
        if (!initialValues || initialValues.length === 0) return <Empty />
        return initialValues.map((initialValue, index) => {
            return <div key={`${key}-${index}`}><ProgressComponent isEditMode={isEditMode} isAddingNew={false} initialValue={initialValue} patient={this.patient} onValueChange={(newValue, fhirMessages, isValid) => {
                if (onTabValueItemChange) onTabValueItemChange(index, newValue, fhirMessages, isValid);
            }} />
                {initialValues.length - 1 > index && <hr />}</div>
        });
    }
};


const ProgressComponent: React.FC<{
    isEditMode: boolean,
    isAddingNew: boolean,
    initialValue: Progress,
    patient: Patient,
    onValueChange?: (newValue: Progress, fhirMessages: BaseFhirModel[], isValid: boolean) => void
}> = ({ isEditMode, isAddingNew, initialValue, patient, onValueChange }) => {

    const availableDiseaseStates = TerminologyService.getValueSetOptions('DiseaseState');
    
    const [progressDate, setProgressDate] = useState<dayjs.Dayjs | undefined>(initialValue.progressDate ? dayjs(initialValue.progressDate) : undefined);
    const [statusType, setStatusType] = useState<string | undefined>(initialValue.statusType);

    useEffect(() => {
        if ((isEditMode || isAddingNew) && !progressDate) {
            setProgressDate(patient.dateOfDeath ? dayjs(patient.dateOfDeath) : dayjs());
        }

    }, [isEditMode, isAddingNew]);

    useEffect(() => {
        if (onValueChange) {
            const fhirMessages: BaseFhirModel[] = [];

            if (!dayjs(initialValue?.progressDate).isSame(progressDate, 'day') ||
                isFieldValueDifferent(initialValue?.statusType, statusType)) {
                fhirMessages.push({
                    fhirResourceName: FhirResourceName.observation,
                    friendlyResourceName: FhirResourceFriendlyName.progress,
                    fieldName: 'Κατάσταση νόσου',
                    value: statusType,
                    friendlyValue: statusType ? availableDiseaseStates.find(s => s.value === statusType)?.label : undefined,
                    effectiveStartDate: progressDate ? progressDate.toDate() : undefined,
                    isOccuredDateVisible: false,
                    wireData: {
                        id: initialValue.id,
                        patientId: patient.id,
                        conditionId: initialValue.conditionId,
                        code: 'DiseaseState',
                        valueCodeSystem: 'DiseaseState',
                        valueCode: statusType,
                        effectiveDateTime: progressDate ? progressDate.toDate() : undefined,
                        effectiveDateTimeEnd: undefined,
                        issuedDate: progressDate ? progressDate.toDate() : undefined,
                    } as ObservationModel
                } as Observation);
            }

            const newProgress = new Progress(initialValue.id, initialValue.conditionId);
            newProgress.progressDate = progressDate ? progressDate.toDate() : undefined;
            newProgress.statusType = statusType;

            onValueChange(newProgress, fhirMessages, (progressDate !== undefined && progressDate !== null && statusType !== undefined));
        }
    }, [progressDate, statusType]);

    return (
        <>
            <Form
                layout='vertical'
                style={{ display: 'flex', flexDirection: 'row' }}
                autoComplete="off"
            >
                <div className='patient-fields-column'>
                    <Form.Item
                        label="Ημερομηνία καταγραφής"
                        validateStatus={progressDate ? 'success' : 'error'}
                        help={progressDate ? '' : 'Το πεδίο απαιτείται'}
                    >
                        {isEditMode && <DatePicker disabledDate={(current)=>patient.dateOfDeath ? current.isAfter(dayjs(patient.dateOfDeath)): current.isAfter(dayjs())} format={DATE_FORMAT} value={progressDate} allowClear={true} onChange={(newDate) => setProgressDate(newDate)} />}
                        {!isEditMode && <div className='field-value-text'>{progressDate ? progressDate.format(DATE_FORMAT) : "-"}</div>}
                    </Form.Item>
                </div>
                <div className='patient-fields-column'>
                    <Form.Item
                        label="Κατάσταση νόσου"
                        validateStatus={statusType ? 'success' : 'error'}
                        help={statusType ? '' : 'Το πεδίο απαιτείται'}
                    >
                        {isEditMode && <Select options={availableDiseaseStates} allowClear={true} value={statusType} onChange={(value) => {
                            setStatusType(value);
                        }} />}
                        {!isEditMode && <div className='field-value-text'>{statusType ? availableDiseaseStates.find(s => s.value === statusType)?.label : '-'}</div>}
                    </Form.Item>
                </div>
                <div className='patient-fields-column'>
                </div>
            </Form>
        </>
    );

}
export default ProgressComponent;