import { useEffect, useState } from "react";
import { calculateBmiInKgPerSquareMeters } from "../helpers/bmi-calculator";
import { Form, InputNumber, Statistic } from "antd";

const BmiCalculatorComponent: React.FC<{ 
    initialWeightInKg?:number;
    initialHeightInCm?:number;

    onValueChange?:(bmiResult?: number) => void }> = ({ initialWeightInKg, initialHeightInCm, onValueChange }) => {
        const [weightInKg, setWeightInKg] = useState<number|undefined>(undefined);
        const [heightInCm, setHeightInCm] = useState<number|undefined>(undefined);
        const [result, setResult] = useState<number|undefined>(undefined);

        useEffect(()=> {
            setWeightInKg(initialWeightInKg);
            setHeightInCm(initialHeightInCm);
            let result: number|undefined = undefined;
            if (initialHeightInCm && initialWeightInKg) result = calculateBmiInKgPerSquareMeters(initialWeightInKg, initialHeightInCm);
            setResult(result);
            if(onValueChange) onValueChange(result);
        },[]);

        useEffect(()=> {
            let result: number|undefined = undefined;
            if (heightInCm && weightInKg) result = calculateBmiInKgPerSquareMeters(weightInKg, heightInCm);
            setResult(result);
            
            if(onValueChange) onValueChange(result);
        }, [heightInCm, weightInKg])
        return (<>
            <Form
                layout='vertical'
                style={{ display: 'flex', flexDirection:'row' }}
                autoComplete="off"
            >
                <div className='patient-fields-column'>
                    <Form.Item
                        label="Ύψος (cm)"
                    >
                        <InputNumber min={0} max={250} precision={0} value={heightInCm} onChange={newValue=>setHeightInCm(newValue!)} suffix='cm'/>
                    </Form.Item>
                </div>

                <div className='patient-fields-column'>
                    <Form.Item
                        label="Βάρος (kg)"
                    >
                        <InputNumber min={0} max={300} precision={0} value={weightInKg} onChange={newValue=>setWeightInKg(newValue!)} suffix='kg'/>
                    </Form.Item>
                </div>
            </Form>
            <Statistic title='BMI' suffix={<span>kg/m<sup>2</sup></span>} value={result ? result.toFixed(2):undefined}/>

        </>);
}

export default BmiCalculatorComponent;
