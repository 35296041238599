import { Observable, of, delay, Subscription, throwError, from, defer } from "rxjs";
import { finalize, map } from 'rxjs/operators';
import FhirService from "./fhir-service";
import ObjectOperationsService from "./object-operations-service";
import { ValueSets, ValueSetOptions, ValueSetOption } from "../models/fhir/value-set";

class TerminologyService {
	private static initialized : boolean = false;
	private static valueSets : ValueSets = {};
	private static valueSets$ : Observable<ValueSets>;

	public static getValueSetOptions(valueSetName: string) : ValueSetOptions {
		return TerminologyService.valueSets[valueSetName];
	}

	public static getValueSetOption(valueSetName: string, optionValue: string|null|undefined) : ValueSetOption|undefined {
		if(!optionValue)
			return undefined;

		return TerminologyService.valueSets[valueSetName]?.find(x => x.value === optionValue);
	}

	public static initialize(fhirService: FhirService, objectOperationsService: ObjectOperationsService) : Observable<ValueSets> {
		if(this.initialized)
			return this.valueSets$;

		const getValueSetOptions = (data: Array<any>, valueSetName: string) : ValueSetOptions => {
			data = objectOperationsService.getPropertyByPath(data, `entry[resource.id=${valueSetName}].0.resource.expansion.contains`)?.propValue;
			return data.map(it => ({
				value: it.code,
				label: it.display,
				properties: it.properties,
			}));
		};
		const initializeValueSet = (data: Array<any>, key: string) : void => {
			const options = getValueSetOptions(data, key);
			TerminologyService.valueSets[key] = options;
		};

		const obs$ = fhirService.getValueSets();
		TerminologyService.valueSets$ = obs$;
		TerminologyService.initialized = true;

		obs$
		.pipe(
			finalize(() => {
			})
		)
		.subscribe({
			next: async (data) => {
				const entries = objectOperationsService.getPropertyByPath(data, `entry`)?.propValue;
				entries.forEach((entry: any) => {
					const valueSetName = objectOperationsService.getPropertyByPath(entry, `resource.id`)?.propValue;
					initializeValueSet(data, valueSetName);
				});
			},
			error: (err) => {
			}
		});

		return obs$;
	}
}

export default TerminologyService;
