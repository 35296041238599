import { Observable, of, delay, BehaviorSubject, defer, map } from "rxjs";
import { GetAccessTokenResponse } from "../requests-responses/get-access-token-response";
import { GetCurrentUserResponse } from "../requests-responses/get-current-user-response";
import { User } from "../models/user";
import { Organization } from "../models/organization";
import dayjs from 'dayjs';
import { Specialty } from "../models/specialty";
import axios, { AxiosError, AxiosResponse } from "axios";
import { useNavigate } from "react-router-dom";
import { result, uniq, uniqBy } from "lodash";
import { notification } from "antd";


const userChanged: BehaviorSubject<User|null> = new BehaviorSubject<User|null>(null);
export const user$ = (): Observable<User|null> => userChanged.asObservable();

const activeOrgChanged: BehaviorSubject<Organization|null> = new BehaviorSubject<Organization|null>(null);
export const org$ = (): Observable<Organization|null> => activeOrgChanged.asObservable();


class AuthService {
    private loginUrl?:string;
    private logoutUrl?:string;
    private user?:User;
    private organization?:Organization;

    private static instance: AuthService | null = null;

    private constructor() {
    }

    public static getInstance(): AuthService {
        if (this.instance === null) {
            this.instance = new AuthService();            
        }
        return this.instance;
    }

    setAuthUrls(loginUrl:string, logoutUrl: string):void {
        this.loginUrl = loginUrl;
        this.logoutUrl = logoutUrl;
    }

    getLoginUrl():string {
        if (!this.loginUrl) throw Error("Auth service not ready");
        return this.loginUrl!;
    }

    getLogoutUrl():string {
        if (!this.logoutUrl) throw Error("Auth service not ready");
        return this.logoutUrl!;
    }

    clearAuthInfoFromLocalStorage(): void {
        localStorage.removeItem("auth-info");
        localStorage.removeItem("active-org-id")
    }

    isValidAuthInfoInLocalStorage():boolean {
        const authInfoString = localStorage.getItem("auth-info");
        if (!authInfoString) return false;
        const authInfo: GetAccessTokenResponse = JSON.parse(authInfoString);
        if (!authInfo) return false;

        return true;
    }

    isAcessTokenNotExpired():boolean {
        const authInfoString = localStorage.getItem("auth-info");
        if (!authInfoString) return false;
        const authInfo: GetAccessTokenResponse = JSON.parse(authInfoString);
        if (!authInfo) return false;
        return true;
    }

    isRefreshTokenNotExpired():boolean {
        const authInfoString = localStorage.getItem("auth-info");
        if (!authInfoString) return false;
        const authInfo: GetAccessTokenResponse = JSON.parse(authInfoString);
        if (!authInfo) return false;
        return true;
    }

    getAccessToken(oAuthReturnCode: string, sessionState: string, iss: string) : Observable<GetAccessTokenResponse>  {
        localStorage.removeItem("auth-info");
        return defer(()=> axios.get(`/api/oauth/access-token?code=${oAuthReturnCode}&session_state=${sessionState}&iss=${iss}`).then((result:AxiosResponse) => {
            const authInfo : GetAccessTokenResponse = result.data;
            // authInfo.expires_on = dayjs().unix() + (authInfo.expires_in*60);
            // authInfo.refresh_expires_on = dayjs().unix() + (authInfo.refresh_expires_in*60);
            localStorage.setItem("auth-info", JSON.stringify(authInfo));
            return result.data;
        })).pipe(
            map(result => result)
        );
    };

    getCurrentUserFromServer(): Observable<GetCurrentUserResponse> {
        return defer(()=> axios.get(`/api/users/current`)).pipe(
            map(result => {
                const user = result.data.data as User;
                if (!user.organizations || !user.organizations.length) {
                    throw new AxiosError("Δεν έχετε συσχετιστεί με μονάδες παροχής υπηρεσιών υγείας", "organizations");
                }
                if (!user.is_trainee && !user.specialty) {
                    throw new AxiosError("Δεν σας έχει εποδοθεί ειδικότητα","specialty");
                }

                this.user = user;
                userChanged.next(this.user);
                const activeOrgIdInLocalStorage = localStorage.getItem("active-org-id");
                if (activeOrgIdInLocalStorage) {
                    this.setActiveOrganization(Number(activeOrgIdInLocalStorage));
                } else if (this.user.organizations && this.user.organizations.length) {
                    this.setActiveOrganization(this.user.organizations[0].id);
                }
                return {user: result.data.data};
            })
        );
    };

    public initializeAxiosInterceptor() {
        const access_token = this.getBearerAccessTokenFromLocalStorage();

        // Add a request interceptor to add Authorization header
        axios.interceptors.request.use(function (config) {
            config.headers.Authorization = `Bearer ${access_token}`;
            const activeOrgIdInLocalStorage = localStorage.getItem("active-org-id");
            if (activeOrgIdInLocalStorage) {
                config.headers['NCR_ACTIVE_ORG'] = activeOrgIdInLocalStorage;
                
            }
            return config;
        });

        // Add a response interceptor to catch errors
        axios.interceptors.response.use(function (response) {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            return response;
        }, function (error:AxiosError) {
            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // Do something with response error
            console.log(error);
            if (error.status === 401 || error.status === 403) {
                AuthService.getInstance().clearAuthInfoFromLocalStorage();
                window.location.href = '/general-error?error_code=auth';
                return;
            }
            else if (error.status === 422) {
                let errorMessages = [];
                if (error.response && error.response.data && (error.response.data as any).errors && (error.response.data as any).errors.length > 0) {
                    (error.response.data as any).errors.forEach((e:any) => {
                        if (Array.isArray(e.message)) {
                            ((e.message as [any])).forEach(m => {
                                if (Array.isArray(m)) {
                                    (m as [string]).forEach(im=>errorMessages.push(im));
                                } else errorMessages.push(m);
                            });
                        } else {
                            errorMessages.push(e.message);
                        }
                    });
                } else if (error.response && error.response.data && (error.response.data as any).message) {
                   errorMessages.push((error.response.data as any).message);
                } else if (error.response && (error.response.data as string)) {
                    errorMessages.push((error.response.data as string));
                 }
                console.log(errorMessages);
                if (errorMessages.length) {
                    notification.error({message: uniq(errorMessages).join(', ')});
                }

                // catch other errors and do something
            }
            else if (error.status === 500) {
                if(error.response && (error.response.data as string)) {
                    notification.error({message: (error.response.data as string)});
                } else if (error.message) {
                    notification.error({message: error.message});
                } else if (error.code)
                    notification.error({message: error.code});
            }
            return Promise.reject(error);
        });
    }


    getBearerAccessTokenFromLocalStorage():string {
        const authInfoString = localStorage.getItem("auth-info");
        if (!authInfoString) return '';
        const authInfo: GetAccessTokenResponse = JSON.parse(authInfoString!);
        return authInfo.access_token;
    }

    getCurrentUser():User {
        if (!this.user) throw Error("User is null. Call getCurrentUserFromServer");
        return this.user;
    }

    getActiveOrganization(): Organization|undefined {
        return this.organization;
    }

    

    setActiveOrganization(orgId: number): void {
        const org = this.user!.organizations.find(org => org.id === orgId);
        if (!org) {
            localStorage.removeItem("active-org-id");
            return;
        }
        this.organization = org;
        localStorage.setItem("active-org-id", orgId.toFixed(0));
        activeOrgChanged.next(org);
    };

    

    setSpecialty(specialtyId?:number): Observable<GetCurrentUserResponse> {
        return defer(()=> axios.put(`/api/users/current/specialties`, { id:specialtyId})).pipe(
            map(result => {
                this.user = result.data.data as User;
                userChanged.next(this.user);
                return {user: result.data.data};
            })
        );
    }

    


}


export enum Roles {
    TreatingDoctor = "treating-doctor",
    LabDoctor = "lab-doctor",
    Both = "treating-doctor,lab-doctor"
}

export enum LoginErrorCodes {
    InvalidCredentials = "101",
    NotASpecialty = "102",
    Other = "999"
}

export default AuthService;
