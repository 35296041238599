import TerminologyService from "../services/terminology-service";
import { BaseEditableModel } from "./base-editable-model";

export class Staging implements BaseEditableModel {
    idP?: string; // id field is messed up...
    idC?: string; // id field is messed up...
    conditionId: string;

    stageResultType?:string;
    clinicalTType?:string;
    clinicalNType?:string;
    clinicalMType?:string;
    pathologicalTType?:string;
    pathologicalNType?:string;
    pathologicalMType?:string;
    
    constructor(conditionId: string) {
        this.conditionId = conditionId;
    }

    public toDisplayText() {
        const cNeoplasmAjccStaging = TerminologyService.getValueSetOptions('cNeoplasmAjccStaging'),
            cTNM_T = TerminologyService.getValueSetOptions('cTNM_T'),
            cTNM_N = TerminologyService.getValueSetOptions('cTNM_N'),
            cTNM_M = TerminologyService.getValueSetOptions('cTNM_M'),
            pNeoplasmAjccStaging = TerminologyService.getValueSetOptions('pNeoplasmAjccStaging'),
            pTNM_T = TerminologyService.getValueSetOptions('pTNM_T'),
            pTNM_N = TerminologyService.getValueSetOptions('pTNM_N'),
            pTNM_M = TerminologyService.getValueSetOptions('pTNM_M'),
            emptyPlaceholder = '-';

        let stage = `Stage: ${this.stageResultType?.startsWith("p") ? (pNeoplasmAjccStaging.find(x => x.value === this.stageResultType)?.label ?? emptyPlaceholder) : (cNeoplasmAjccStaging.find(x => x.value === this.stageResultType)?.label ?? emptyPlaceholder)}` ;
        if(this.pathologicalTType && this.pathologicalNType && this.pathologicalMType)
            return `${stage}, pTNM: ${pTNM_T.find(x => x.value === this.pathologicalTType)?.label ?? emptyPlaceholder}, ${pTNM_N.find(x => x.value === this.pathologicalNType)?.label ?? emptyPlaceholder}, ${pTNM_M.find(x => x.value === this.pathologicalMType)?.label ?? emptyPlaceholder}`;

        if(this.clinicalTType && this.clinicalNType && this.clinicalMType)
            return `${stage}, cTNM: ${cTNM_T.find(x => x.value === this.clinicalTType)?.label ?? emptyPlaceholder}, ${cTNM_N.find(x => x.value === this.clinicalNType)?.label ?? emptyPlaceholder}, ${cTNM_M.find(x => x.value === this.clinicalMType)?.label ?? emptyPlaceholder}`;;

        return stage;
    }
}
