import dayjs from "dayjs";
import { Attendant } from "./attendant";
import { BiomarkerContainerModel } from "./biomarker-container-model";
import { Diagnosis } from "./diagnosis";
import { Histology } from "./histology";
import { PathologoReport } from "./pathologoReport";
import { PersonalInfo } from "./personal-info";
import { Progress } from "./progress";
import { Staging } from "./staging";
import { Therapy } from "./therapy";
import { DATE_FORMAT } from "../constants";
import TerminologyService from "../services/terminology-service";

export class Patient {
    id?: string;
    amka: string;
    firstName?: string;
    lastName?: string;
    fatherName?: string;
    motherName?: string;
    citizenship: string;
    gender: string;
    dateOfBirth: Date;
    dateOfDeath: Date|null;
    causeOfDeath?: string|null;
    personalInfo?: PersonalInfo;
    attendant: Attendant = new Attendant();
    diagnoses: Diagnosis[] = [];
    histologies: Histology[] = [];
    stagings: Staging[] = [];
    biomarkers: BiomarkerContainerModel[] = [];
    therapies: Therapy[] = [];
    progresses: Progress[] = [];
    pathologoReports: PathologoReport[] = [];
    accessedBy: any[] = [];

    constructor(amka: string, citizenship: string, gender: string, dateOfBirth: Date, dateOfDeath: Date|null){
        this.amka = amka;
        this.citizenship = citizenship;
        this.gender = gender;
        this.dateOfBirth = dateOfBirth;
        this.dateOfDeath = dateOfDeath;
    }

    get fullname():string {
        return (this.lastName ?? '') + ' ' + (this.firstName ?? '');
    }

    get age():number {
        return dayjs(this.dateOfDeath ? this.dateOfDeath! : undefined).diff(dayjs(this.dateOfBirth), 'years');
    }

    public mainInfoToString =():string => [
        `Όνομα: ${this.firstName ?? '-'}`, 
        `Επώνυμο: ${this.lastName ?? '-'}`, 
        `ΑΜΚΑ: ${this.amka ?? '-'}`,
        `Φύλο: ${this.gender ? (this.gender === 'male'?'Άνδρας': this.gender === 'female' ? 'Γυναίκα': 'Άλλο') : '-'}`,
        `Ημ. γέννησης: ${dayjs(this.dateOfBirth).format(DATE_FORMAT)}`,
        `Όνομα πατέρα: ${this.fatherName ?? '-'}`,
        `Όνομα μητέρας: ${this.motherName ?? '-'}`,
        `Υπηκοότητα: ${this.citizenship? TerminologyService.getValueSetOption('Countries',this.citizenship)?.label : '-'}`,
        this.dateOfDeath ? `Ημ. θανάτου: ${dayjs(this.dateOfDeath).format(DATE_FORMAT)}` : undefined
     ].filter(s=>s).join("\r\n");
}
