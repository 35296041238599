import { BaseEditableModel } from "./base-editable-model";
import { ValueSetOption } from "./fhir/value-set";

export type BiomarkerModel = {
    biomarkerCode?: string;
    biomarkerValue?: string|number|undefined;
    properties?: any;
}

export class BiomarkerContainerModel implements BaseEditableModel {
    public readonly conditionId: string;

    public biomarkers: BiomarkerModel[] = [];
    
    constructor(conditionId: string) {
        this.conditionId = conditionId;
    }
}
