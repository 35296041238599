import { Alert, Button, Card, Form, FormProps, Select, Spin, Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthService, { org$, user$ } from '../services/auth-service';
import { Specialty } from '../models/specialty';
import { User } from '../models/user';
import axios, { AxiosResponse } from 'axios';
import { orderBy } from 'lodash';
import { normalizeString } from '../helpers/string-helpers';

function AdministerSpecialtiesPage() {
    const navigate = useNavigate();
    const authService = AuthService.getInstance();
    const [isFetchingSpecialties, setIsFetchingSpecialties] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [availableSpecialties, setAvailableSpecialties] = useState<Specialty[]>([]);
    const [selectedSpecialty, setSelectedSpecialty] = useState<Specialty|undefined>(undefined);

    useEffect(()=> {
        setIsFetchingSpecialties(true);
        axios.get("/api/users/specialties").then((response: AxiosResponse)=> {
            setIsFetchingSpecialties(false);
            setAvailableSpecialties(orderBy((response.data as Specialty[]).filter(s=>s.isEligible), s=>s.isEligible));
        });
    }, []);
    
    return (
        <>
            <div style={{display:'flex',flexDirection:'column',alignItems: "center",justifyContent: "center",height:"100%"}}>
                <Card loading={isFetchingSpecialties} className='hipa_card_shadow' title="Ειδικότητα ειδικευόμενου" style={{ width: 800 }}>
                    {(!isFetchingSpecialties && !selectedSpecialty) && <Alert type='error' description='Για να συνεχίσετε στο ΕΜΝΝ είναι απαραίτητη η καταχώριση της ειδικότητας στην οποία εκπαιδεύεστε.'/>}
                    <Form
                        style={{marginTop:10}}
                        layout='vertical'
                        autoComplete="off"
                    >
                        {availableSpecialties.length > 0 && <Form.Item
                            label="Διαθέσιμες ειδικότητες"
                            help={<div>Οι επιλέξιμες ειδικότητες περιγράφονται στο (<a href='/950b-24.pdf' target="_blank">ΦΕΚ Β950/9.2.2024</a>).</div>}
                        >
                            <Select 
                                placeholder='Επιλέξτε ειδικότητα'
                                showSearch
                                filterOption={(input, option) =>
                                    normalizeString(option?.label ?? '').toLowerCase().includes(normalizeString(input??'').toLowerCase())
                                } 
                                disabled={isLoading}
                                options={availableSpecialties}
                                allowClear
                                loading={isLoading}
                                value={selectedSpecialty}
                                onClear={()=> {
                                    setSelectedSpecialty(undefined);
                                    setIsLoading(true);
                                    authService.setSpecialty(undefined).subscribe({
                                        next:()=>setIsLoading(false),
                                        error:()=>setIsLoading(false)
                                    });
                                }}
                                onSelect={(_, option) => {
                                    setSelectedSpecialty(availableSpecialties.find(s => s.value === option.value)!);
                                    setIsLoading(true);
                                    authService.setSpecialty(option.value).subscribe({
                                        next:()=>setIsLoading(false),
                                        error:()=>setIsLoading(false)
                                    });
                                }}
                            />
                        </Form.Item>}
                        
                        <div style={{textAlign:'center'}}>
                            <Button disabled={selectedSpecialty === undefined || isLoading} type="default" onClick={()=>navigate("/active-organization")}>
                                Ολοκλήρωση
                            </Button>
                        </div>
                    </Form>
                </Card>
            </div>
        </>
    );
}

export default AdministerSpecialtiesPage;