import { Alert, Button, Card, Form, FormProps, Input, Modal, Checkbox, Radio, Typography, Upload, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {validateAMKA} from '../helpers/amka-validator';
import AuthService from '../services/auth-service';
import { UploadOutlined } from '@ant-design/icons';


function FileUploadPage() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const authService = AuthService.getInstance();

    useEffect(()=> {
        if (!authService.getActiveOrganization()) {
            navigate('/active-organization');
            return;
        }
    },[]);

    


    return (
        <>
            <div style={{display:'flex',flexDirection:'column',alignItems: "center",justifyContent: "center",height:"100%"}}>
                <Card className='amka_search_card hipa_card_shadow' title="Αρχείο πολλαπλών υποβολών" style={{ width: 600 }}>
                    <Alert type='info' description='Μπορείτε να υποβάλλετε μαζικά δεδομένα στο ΕΜΝΝ επιλέγοντας κατάλληλα γραμμογραφημένο αρχείο json' style={{marginBottom:10}}/>
                    <Upload beforeUpload={(file)=> {
                        const isJson = file.type === 'application/json';
                        if (!isJson) message.error(`Το αρχείο ${file.name} δεν είναι json`);
                        return isJson || Upload.LIST_IGNORE;
                    }} multiple={false} maxCount={1}>
                        <Button icon={<UploadOutlined />}>Επιλογή αρχείου (json)</Button>
                    </Upload>
                </Card>
            </div>

        </>
    );
}

export default FileUploadPage;