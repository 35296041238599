import { Alert, Button, Layout, Modal, Skeleton, Space, Typography, MenuProps, List, Avatar, Empty, Tooltip, Table, Tag, notification, Menu, message } from 'antd';
import  { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import PersonSecondaryHeaderCompoment from '../components/person-secondary-header-component';
import { PersonalInfoComponentParams } from '../components/personal-info-component';
import FieldsContainerComponent from '../components/fields-container';
import DiagnosisComponent, { DiagnosisComponentParams } from '../components/diagnosis-component';
import { AttendantComponentParams } from '../components/attendant-component';
import { MultiTabFieldsContainerComponent } from '../components/multi-tab-fields-container';
import { HistologyComponentParams } from '../components/histology-component';
import { StagingComponentParams } from '../components/staging-component';
import { BiomarkerComponentParams } from '../components/biomarker-component';
import TherapyComponent, { TherapyComponentParams } from '../components/therapy-component';
import { Diagnosis } from '../models/diagnosis';
import { Histology } from '../models/histology';
import { Staging } from '../models/staging';
import { BiomarkerContainerModel } from '../models/biomarker-container-model';
import { Therapy } from '../models/therapy';
import { orderBy } from 'lodash';
import Icdo3Service from '../services/icdo3-service';
import TerminologyService from '../services/terminology-service';
import LoggerService from '../services/logger-service';
import FhirService from '../services/fhir-service';
import { Patient } from '../models/patient';
import FieldMapperService from '../services/field-mapper-service';
import { finalize, forkJoin, Observable } from 'rxjs';
import { BaseFhirModel } from '../models/fhir/base-fhir-model';
import {Diagnosis as FhirDiagnosis} from '../models/fhir/diagnosis';
import { FhirResourceFriendlyName } from '../helpers/fhir-message-helper';
import { ObservationWireData } from '../models/observation-wire-data';
import ProgressComponent, { ProgressComponentParams } from '../components/progress-component';
import PathologoReportComponent, { PathologoReportsComponentParams } from '../components/pathologo-reports-component';
import { Progress } from '../models/progress';
import { PathologoReport } from '../models/pathologoReport';
import AuthService from '../services/auth-service';
import { ProcedureWireData } from '../models/fhir/therapy';
import { DiagnosticReport } from '../models/fhir/diagnostic-report';
import { PatientDoctorsComponent } from '../components/patient-doctors-component';
import { PatientSubmissionsComponent } from '../components/patient-submissions-component';
import { Observation } from '../models/fhir/observation';
import { HistologicalObservation } from '../models/fhir/histological-observation';
import { QcsToolResponse, QcsToolResult } from '../models/qcs-tool-response';


function PatientPage() {
    const navigate = useNavigate();
    const authService = AuthService.getInstance();
    let { amka } = useParams();

    const [fhirMessages, setFhirMessages] = useState<any[]>([]);

    const fieldMapperService = new FieldMapperService();
    const fhirService = new FhirService();
    
    const [patient, setPatient] = useState<Patient|null>(null);

    const [isFetchingPatient, setIsFetchingPatient] = useState<boolean>(true);
    const [isPatientLoading, setIsPatientLoading] = useState<boolean>(false);
    const [currentView, setCurrentView] = useState<string>('main');

    const [isPersonalInfoEditMode, setIsPersonalInfoComponentEditMode] = useState<boolean>(false);
    const [isPersonalInfoMessageSending, setIsPersonalInfoMessageSending] = useState<boolean>(false);
    const [personalInfoArchivingProgress, setPersonalInfoArchivingProgress] = useState<number | undefined>(undefined);
    const [personalInfoParams, setPersonalInfoParams] = useState<PersonalInfoComponentParams|undefined>(undefined);

    const [isAttendantEditMode, setIsAttendantComponentEditMode] = useState<boolean>(false);
    const [isAttendantMessageSending, setIsAttendantMessageSending] = useState<boolean>(false);
    const [attendantArchivingProgress, setAttendantArchivingProgress] = useState<number | undefined>(undefined);
    const [attendantParams, setAttendantParams] = useState<AttendantComponentParams|undefined>(undefined);

    const [isPathologoReportsEditMode, setIsPathologoReportsEditMode] = useState<boolean>(false);
    const [pathologoReportsParams, setPathologoReportsParams] = useState<PathologoReportsComponentParams|undefined>(undefined);
    const [isPathologoReportsMessageSending, setIsPathologoReportsMessageSending] = useState<boolean>(false);
    const [pathologoReportsArchivingProgress, setPathologoReportsArchivingProgress] = useState<number | undefined>(undefined);

    const [selectedDiagnosisTabKey, setSelectedDiagnosisTabKey] = useState<string>('0');
    const [editingComponentKey, setEditingComponentKey] = useState<string | undefined>(undefined);

    const [diagnosisParams, setDiagnosisParams] = useState<DiagnosisComponentParams|undefined>(undefined);
    const [isDiagnosisMessageSending, setIsDiagnosisMessageSending] = useState<boolean>(false);
    const [diagnosisArchivingProgress, setDiagnosisArchivingProgress] = useState<number | undefined>(undefined);
    
    const [progressParams, setProgressParams] = useState<ProgressComponentParams|undefined>(undefined);
    const [isProgressMessageSending, setIsProgressMessageSending] = useState<boolean>(false);
    const [progressArchivingProgress, setProgressArchivingProgress] = useState<number | undefined>(undefined);

    const [histologyParams, setHistologyParams] = useState<HistologyComponentParams|undefined>(undefined);
    const [isHistologyMessageSending, setIsHistologyMessageSending] = useState<boolean>(false);
    const [histologyArchivingProgress, setHistologyArchivingProgress] = useState<number | undefined>(undefined);

    const [stagingParams, setStagingParams] = useState<StagingComponentParams|undefined>(undefined);
    const [isStagingMessageSending, setIsStagingMessageSending] = useState<boolean>(false);
    const [stagingArchivingProgress, setStagingArchivingProgress] = useState<number | undefined>(undefined);

    const [biomarkerParams, setBiomarkerParams] = useState<BiomarkerComponentParams|undefined>(undefined);
    const [isBiomarkerMessageSending, setIsBiomarkerMessageSending] = useState<boolean>(false);
    const [biomarkerArchivingProgress, setBiomarkerArchivingProgress] = useState<number | undefined>(undefined);

    const [therapyParams, setTherapyParams] = useState<TherapyComponentParams|undefined>(undefined);
    const [isTherapyMessageSending, setIsTherapyMessageSending] = useState<boolean>(false);
    const [therapyArchivingProgress, setTherapyArchivingProgress] = useState<number | undefined>(undefined);
    


    const [diagnosisToAdd, setDiagnosisToAdd] = useState<Diagnosis | undefined>(undefined);
    const [isDiagnosisToAddValid, setIsDiagnosisToAddValid] = useState<boolean | undefined>(undefined);

    const [progressToAdd, setProgressToAdd] = useState<Progress | undefined>(undefined);
    const [isProgressToAddValid, setIsProgressToAddValid] = useState<boolean | undefined>(undefined);

    const [pathologoReportToAdd, setPathologoReportToAdd] = useState<PathologoReport | undefined>(undefined);
    const [isPathologoReportToAddValid, setIsPathologoReportToAddValid] = useState<boolean | undefined>(undefined);

    const [therapyToAdd, setTherapryToAdd] = useState<Therapy | undefined>(undefined);
    const [isTherapyToAddValid, setIsTherapyToAddValid] = useState<boolean | undefined>(undefined);

    

    useEffect(() => {
        if (!authService.getActiveOrganization()) {
            navigate('/active-organization');
            return;
        }
    },[]);
    useEffect(()=> {
        var mainArea = document.getElementById("patient-main-content");
        if (mainArea) mainArea.scrollTo(0, 0);
    }, [currentView]);

    useEffect(()=> {
        if (isPatientLoading) return;
        setIsFetchingPatient(true);
        
        fhirService.getAllPatientDataByAmka(amka!).subscribe({
            error(err) {
                notification.error({message:`Αποτυχία ανάσυρσης δεδομένων ασθενούς!`});
                setIsFetchingPatient(false);
            },
            next(fhirBundle) {
                setIsFetchingPatient(false);
                const patient = fieldMapperService.mapAllPatientDataFromFhir(fhirBundle);
                setPatient(patient);
                
                const _personalInfoParams = new PersonalInfoComponentParams(patient);
                _personalInfoParams.initialValues = [{...patient?.personalInfo}]
                setPersonalInfoParams(_personalInfoParams);

                const _attendantParams = new AttendantComponentParams(patient);
                _attendantParams.initialValues = [{...patient?.attendant}]
                setAttendantParams(_attendantParams);

                const _pathologoReportsParams = new PathologoReportsComponentParams(patient);
                _pathologoReportsParams.initialValues = patient.pathologoReports && patient.pathologoReports.length > 0 ? [...patient.pathologoReports] : [];
                setPathologoReportsParams(_pathologoReportsParams);

                const _diagnosisParams = new DiagnosisComponentParams(patient);
                _diagnosisParams.initialValuesOfTabs = Diagnosis.GroupDiagnoses(patient.diagnoses).map(groupOfDiagnoses => {
                    if (!groupOfDiagnoses[0].icdO3DiagnosisCode) throw new Error("First diagnosis cannot have empty icdO3DiagnosisCode");
                    return {
                        tabTitle : groupOfDiagnoses[0].icdO3DiagnosisCode,
                        initialValues: groupOfDiagnoses
                    };
                });
                _diagnosisParams.onAddNeoplasmClick = (newDiagnosis) => {
                    setDiagnosisToAdd(newDiagnosis);
                    setIsDiagnosisToAddValid(false);
                };
                setDiagnosisParams(_diagnosisParams);

                const _progressParams = new ProgressComponentParams(patient);
                _progressParams.initialValuesOfTabs = _diagnosisParams.initialValuesOfTabs.map(dt=> {
                    const patientProgresses = (patient.progresses && patient.progresses.length>0) ? patient.progresses.filter((p: Progress) => p.conditionId === dt.initialValues[0].id): undefined;
                    return {
                        tabTitle: dt.tabTitle,
                        initialValues: (patientProgresses && patientProgresses.length) ? patientProgresses : [],
                    };
                });
                setProgressParams(_progressParams);

                const _histologyParams = new HistologyComponentParams(patient);
                _histologyParams.initialValuesOfTabs = _diagnosisParams.initialValuesOfTabs.map(dt => {
                    const patientHistologies = (patient.histologies && patient.histologies.length>0) ? patient.histologies.filter((h: Histology) => h.conditionId === dt.initialValues[0].id!): undefined;
                    return {
                        tabTitle: dt.tabTitle,
                        initialValues: (patientHistologies && patientHistologies.length) ? patientHistologies : [new Histology(dt.initialValues.map(d => d.id!)[0])],
                    }
                });
                setHistologyParams(_histologyParams);

                const _stagingParams = new StagingComponentParams(patient);
                _stagingParams.initialValuesOfTabs = _diagnosisParams.initialValuesOfTabs.map(dt => {
                    const patientStagings = (patient.stagings && patient.stagings.length>0) ? patient.stagings.filter((s: Staging) => s.conditionId === dt.initialValues[0].id) : undefined;
                    return {
                        tabTitle: dt.tabTitle,
                        initialValues: (patientStagings && patientStagings.length) ? patientStagings  : [new Staging(dt.initialValues.map(d => d.id!)[0])]
                    }
                });
                setStagingParams(_stagingParams);
            
                const _biomarkerParams = new BiomarkerComponentParams(patient);
                _biomarkerParams.initialValuesOfTabs = _diagnosisParams.initialValuesOfTabs.map(dt => {
                    const patientBiomarkers = patient.biomarkers && patient.biomarkers.length > 0 ? patient?.biomarkers.filter(b => b.conditionId === dt.initialValues.map(d => d.id!)[0]) : undefined;
                    return {
                        tabTitle: dt.tabTitle,
                        initialValues: (patientBiomarkers && patientBiomarkers.length) ? patientBiomarkers : [],
                    }
                });
                setBiomarkerParams(_biomarkerParams);
            
                const _therapyParams = new TherapyComponentParams(patient);
                _therapyParams.initialValuesOfTabs = _diagnosisParams.initialValuesOfTabs.map(dt => {
                    const patientTherapies = (patient?.therapies && patient?.therapies.length>0) ? patient?.therapies.filter((t: Therapy) => t.conditionId === dt.initialValues[0].id) : undefined;
                    return {
                        tabTitle: dt.tabTitle,
                        initialValues: (patientTherapies && patientTherapies.length) ? patientTherapies : []
                    }
                });
                setTherapyParams(_therapyParams);
            },
        });
    }, [amka, isPatientLoading]);

    return (
        <>
            {(!amka || !patient)
            ? (isFetchingPatient ? <Skeleton loading={true}></Skeleton> : <Alert type='error' description='Παρουσιάστηκε σφάλμα'></Alert>)
            : <>
                {isFetchingPatient && <Skeleton loading={true}></Skeleton>}
                {!isFetchingPatient && <Layout className='ant-layout-page'>
                    <Layout.Header className='hipaheader2 ant-layout-page-header'>
                        <Space className='personal_details_wrapper'>
                            <h1>{patient?.fullname}</h1>
                            <h2><Typography.Text type='secondary'>AMKA: {amka}</Typography.Text></h2>
                        </Space>
                        <div style={{flex:"1 auto"}}></div>
                        <div style={{width:'fit-content',alignContent:'end',marginBottom:20}}><Menu selectedKeys={[currentView]} mode="horizontal" onClick={(info)=>{setCurrentView(info.key)}} items={[
                                {
                                    label: 'Βασική προβολή',
                                    key:'main',
                                },
                                {
                                    label: 'Πορίσματα',
                                    key: 'findings'
                                },
                                {
                                    label: 'Θεράποντες ιατροί',
                                    key: 'doctors'
                                },
                                {
                                    label: 'Ιστορικό υποβολών',
                                    key: 'history'
                                }
                            ]} />
                        </div>
                    </Layout.Header>
                    <Layout.Content id='patient-main-content' className='ant-layout-page-content'>
                        {currentView === 'main' &&
                            <Space direction='vertical' style={{width:'100%'}}>
                                <PersonSecondaryHeaderCompoment
                                    dateOfBirth={patient?.dateOfBirth}
                                    dateOfDeath={patient?.dateOfDeath}
                                    causeOfDeath={patient?.causeOfDeath}
                                    citizenship={patient?.citizenship}
                                    genderCode={patient.gender}
                                    fatherName={patient.fatherName}
                                    motherName={patient.motherName}
                                />
 
                                <FieldsContainerComponent<PersonalInfoComponentParams> key="0" contentParams={personalInfoParams!} isMessageSending={isPersonalInfoMessageSending} archivingProgress={personalInfoArchivingProgress} isEditMode={isPersonalInfoEditMode} onEditClick={()=>setIsPersonalInfoComponentEditMode(true)} onRevertClick={()=>{
                                    setIsPersonalInfoComponentEditMode(false);
                                    }} onSaveClick={(newValues, messages: BaseFhirModel[],completeCallback?) => {
                                        setIsPersonalInfoMessageSending(true);
                                        setPersonalInfoArchivingProgress(0);

                                        const updatedPatient = {...patient} as Patient;
                                        updatedPatient.personalInfo = newValues![0];
                                        const fhirCommunications$ = messages.map((m): Observable<any> => {
                                            if(m.friendlyResourceName === FhirResourceFriendlyName.patientDemographicsAndContact){
                                                const patientFhir = fieldMapperService.mapPatientToFhir(updatedPatient, {tag:[{code:"personal-info"}]});
                                                return fhirService.updatePatient(patientFhir);
                                            }
                                            else if(m.friendlyResourceName === FhirResourceFriendlyName.weight){
                                                const fhirObservation = fieldMapperService.mapObservationToFhir({
                                                    patientId: patient.id,
                                                    code: 'Weight',
                                                    valueQuantity: updatedPatient.personalInfo?.weight,
                                                    issuedDate: m.occuredDate,
                                                    effectiveDateTime: updatedPatient.personalInfo?.weightDate ?? m.occuredDate,
                                                } as ObservationWireData, {tag:[{code:"personal-info"}]});
                                                return fhirService.addObservation(fhirObservation);
                                            }
                                            else if(m.friendlyResourceName === FhirResourceFriendlyName.height){
                                                const fhirObservation = fieldMapperService.mapObservationToFhir({
                                                    patientId: patient.id,
                                                    code: 'Height',
                                                    valueQuantity: updatedPatient.personalInfo?.height,
                                                    issuedDate: m.occuredDate,
                                                    effectiveDateTime: updatedPatient.personalInfo?.heightDate ?? m.occuredDate,
                                                } as ObservationWireData, {tag:[{code:"personal-info"}]});
                                                return fhirService.addObservation(fhirObservation);
                                            }
                                            else if(m.friendlyResourceName === FhirResourceFriendlyName.bmi){
                                                const fhirObservation = fieldMapperService.mapObservationToFhir({
                                                    patientId: patient.id,
                                                    code: 'BMI',
                                                    valueQuantity: updatedPatient.personalInfo?.bmi,
                                                    issuedDate: m.occuredDate,
                                                    effectiveDateTime: updatedPatient.personalInfo?.bmiDate ?? m.occuredDate,
                                                } as ObservationWireData, {tag:[{code:"personal-info"}]});
                                                return fhirService.addObservation(fhirObservation);
                                            }
                                            else if(m.friendlyResourceName === FhirResourceFriendlyName.smokingStatus){
                                                const fhirObservation = fieldMapperService.mapObservationToFhir({
                                                    patientId: patient.id,
                                                    code: 'SmokingStatus',
                                                    valueCodeSystem: 'SmokingStatus',
                                                    valueCode: updatedPatient.personalInfo?.smokingStatus,
                                                    issuedDate: m.occuredDate,
                                                    effectiveDateTime: updatedPatient.personalInfo?.smokingStartYear ? new Date(updatedPatient.personalInfo.smokingStartYear.toString()) : null,
                                                    effectiveDateTimeEnd: updatedPatient.personalInfo?.smokingEndYear ? new Date(updatedPatient.personalInfo.smokingEndYear.toString()) : null,
                                                } as ObservationWireData, {tag:[{code:"personal-info"}]});
                                                return fhirService.addObservation(fhirObservation);

                                            }
                                            else if(m.friendlyResourceName === FhirResourceFriendlyName.packYears){
                                                const fhirObservation = fieldMapperService.mapObservationToFhir({
                                                    patientId: patient.id,
                                                    code: 'SmokingPackYears',
                                                    valueQuantity: updatedPatient.personalInfo?.packYears,
                                                    issuedDate: m.occuredDate,
                                                } as ObservationWireData, {tag:[{code:"personal-info"}]});
                                                return fhirService.addObservation(fhirObservation);
                                            }
                                            else {
                                                throw new Error(`Unhandled Observation resource name: ${m.friendlyResourceName}!`);
                                            }
                                        });
                                        
                                        forkJoin(fhirCommunications$)
                                            .subscribe({
                                                next(value) {
                                                    setIsPersonalInfoComponentEditMode(false);
                                                    setIsPersonalInfoMessageSending(false);
                                                    setPersonalInfoArchivingProgress(undefined);
                                                    notification.success({message:"Η υποβολή έγινε δεκτή και το μητρώο ενημερώθηκε"});
                                                    patient.personalInfo = {...newValues![0]};
                                                    const _personalInfoParams = new PersonalInfoComponentParams(patient);
                                                    _personalInfoParams.initialValues = [{...newValues![0]}];
                                                    setPersonalInfoParams(_personalInfoParams);
                                                    completeCallback!(true);
                                                },
                                                error(err) {
                                                    completeCallback!(false);
                                                    setIsPersonalInfoMessageSending(false);
                                                    setPersonalInfoArchivingProgress(undefined);
                                                },
                                            });
                                    }}/>
                                 <FieldsContainerComponent<AttendantComponentParams> key="1" contentParams={attendantParams!} isMessageSending={isAttendantMessageSending} archivingProgress={attendantArchivingProgress} isEditMode={isAttendantEditMode} onEditClick={()=>setIsAttendantComponentEditMode(true)} onRevertClick={() => {
                                    setIsAttendantComponentEditMode(false);
                                 }} onSaveClick={(newValues, messages, completeCallback?) => {
                                    setIsAttendantMessageSending(true);
                                    setAttendantArchivingProgress(0);

                                    const updatedPatient = {...patient} as Patient;
                                    updatedPatient.attendant = newValues![0];

                                    const patientFhir = fieldMapperService.mapPatientToFhir(updatedPatient, {tag:[{code:"attendant"}]});
                                    fhirService.updatePatient(patientFhir)
                                        .subscribe({
                                            next(value) {
                                                setIsAttendantComponentEditMode(false);
                                                setIsAttendantMessageSending(false);
                                                setAttendantArchivingProgress(undefined);
                                                notification.success({message:"Η υποβολή έγινε δεκτή και το μητρώο ενημερώθηκε"});
                                                patient.attendant = {...newValues![0]};
                                                const _attendantParams = new AttendantComponentParams(patient);
                                                _attendantParams.initialValues = [{...newValues![0]}];
                                                setAttendantParams(_attendantParams);
                                                completeCallback!(true);
                                            },
                                            error(err) {
                                                completeCallback!(false);
                                                setIsAttendantMessageSending(false);
                                                setAttendantArchivingProgress(undefined);
                                            },
                                        });
                                 }}/>

                                <MultiTabFieldsContainerComponent<DiagnosisComponentParams> key="2" initialIsPanelExpanded={true} onTabChange={(newTab=>{setSelectedDiagnosisTabKey(newTab)})} selectedTab={selectedDiagnosisTabKey} tabsContentParams={diagnosisParams!} isEditingEnable={editingComponentKey===undefined || editingComponentKey===diagnosisParams!.panelTitle} isMessageSending={isDiagnosisMessageSending} archivingProgress={diagnosisArchivingProgress} isEditMode={editingComponentKey===diagnosisParams!.panelTitle } onEditClick={()=>setEditingComponentKey(diagnosisParams!.panelTitle)} onRevertClick={()=>{
                                    setEditingComponentKey(undefined);
                                }} onSaveClick={(newValues?:Diagnosis[], messages?:FhirDiagnosis[], completeCallback?) => {
                                    setIsDiagnosisMessageSending(true);
                                    setDiagnosisArchivingProgress(0);

                                    const conditionsValidate$ = messages!.map((m): Observable<any> => {
                                        const thisCondition = newValues?.find(c => c.id === m.id);
                                        return fhirService.validateUpdateCondition(fieldMapperService.mapConditionToFhir(amka!, thisCondition!));
                                    });
                                    forkJoin(conditionsValidate$).subscribe({
                                        next:(validationResponses: QcsToolResponse[]) => {
                                            const errors : QcsToolResult[] = [];
                                            const warnings: QcsToolResult[] = [];
                                            validationResponses.forEach(validationResponse=> {
                                                errors.push(...validationResponse.errors);
                                                warnings.push(...validationResponse.warnings);
                                            });
                                            if (errors.length) {
                                                setDiagnosisArchivingProgress(undefined);
                                                setIsDiagnosisMessageSending(false);
                                                setIsDiagnosisToAddValid(false);
                                                Modal.error({title:'Σφάλμα υποβολής', content:<div style={{whiteSpace:'pre-line'}}>{(errors).map(e=>`${e.error_description_gr} (${e.var1_name_gr}: ${e.var1_value})` + "\r\n")}</div>});
                                                return;
                                            }
                                            if (warnings.length) {
                                                Modal.confirm({
                                                    onCancel: () => {
                                                        setIsDiagnosisMessageSending(false);
                                                        setDiagnosisArchivingProgress(undefined);
                                                        completeCallback!(false);
                                                    },
                                                    onOk: ()=>{
                                                        const conditionsUpdate$ = messages!.map((m): Observable<any> => {
                                                            const thisCondition = newValues?.find(c => c.id === m.id);
                                                            return fhirService.updateCondition(fieldMapperService.mapConditionToFhir(amka!, thisCondition!));
                                                        });
                                                        forkJoin(conditionsUpdate$)
                                                        .subscribe({
                                                            next: (data) => {
                                                                setIsDiagnosisMessageSending(false);
                                                                setDiagnosisArchivingProgress(undefined);
                                                                setEditingComponentKey(undefined);
                                                                notification.success({message:"Η υποβολή έγινε δεκτή και το μητρώο ενημερώθηκε"});
                                                                patient.diagnoses = patient?.diagnoses?.map((existingD:Diagnosis) => {
                                                                    const updatedDiagnosis = (newValues as Diagnosis[]).find(d=> 
                                                                        (existingD.icdO3DiagnosisCode != undefined && existingD.icdO3DiagnosisCode === d.icdO3DiagnosisCode) ||
                                                                        (existingD.metastasisSiteCode != undefined && existingD.metastasisSiteCode === d.metastasisSiteCode));
                                                                    if (!updatedDiagnosis) return existingD;
                                                                    return updatedDiagnosis;
                                                                });
                                                                setIsPatientLoading(true)
                                                                completeCallback!(true);
                                                                setTimeout(()=> setIsPatientLoading(false),100);
                                                                
                                                            },
                                                            error: async (err) => {
                                                                setIsDiagnosisMessageSending(false);
                                                                setDiagnosisArchivingProgress(undefined);
                                                                completeCallback!(false);
                                                            }
                                                        });
                                                    },
                                                    okText:'Επιβεβαίωση',
                                                    cancelText:'Ακύρωση',
                                                    title:'Απαιτείται επιβεβαίωση', 
                                                    content:<div style={{whiteSpace:'pre-line'}}>{warnings.map(e=>`${e.error_description_gr} (${e.var1_name_gr}: ${e.var1_value})` + "\r\n")}</div>},                                            
                                                );
                                                return;
                                            }
                                            const conditionsUpdate$ = messages!.map((m): Observable<any> => {
                                                const thisCondition = newValues?.find(c => c.id === m.id);
                                                return fhirService.updateCondition(fieldMapperService.mapConditionToFhir(amka!, thisCondition!));
                                            });
                                            forkJoin(conditionsUpdate$)
                                            .subscribe({
                                                next: (data) => {
                                                    setIsDiagnosisMessageSending(false);
                                                    setDiagnosisArchivingProgress(undefined);
                                                    setEditingComponentKey(undefined);
                                                    notification.success({message:"Η υποβολή έγινε δεκτή και το μητρώο ενημερώθηκε"});
                                                    patient.diagnoses = patient?.diagnoses?.map((existingD:Diagnosis) => {
                                                        const updatedDiagnosis = (newValues as Diagnosis[]).find(d=> 
                                                            (existingD.icdO3DiagnosisCode != undefined && existingD.icdO3DiagnosisCode === d.icdO3DiagnosisCode) ||
                                                            (existingD.metastasisSiteCode != undefined && existingD.metastasisSiteCode === d.metastasisSiteCode));
                                                        if (!updatedDiagnosis) return existingD;
                                                        return updatedDiagnosis;
                                                    });
                                                    setIsPatientLoading(true)
                                                    completeCallback!(true);
                                                    setTimeout(()=> setIsPatientLoading(false),100);
                                                    
                                                },
                                                error: async (err) => {
                                                    setIsDiagnosisMessageSending(false);
                                                    setDiagnosisArchivingProgress(undefined);
                                                    completeCallback!(false);
                                                }
                                            });
                                            
                                        },
                                        error: async (err) => {
                                            setIsDiagnosisMessageSending(false);
                                            setDiagnosisArchivingProgress(undefined);
                                            completeCallback!(false);
                                        }
                                    });
                                }} onAddClick={()=> {
                                    setDiagnosisToAdd({
                                        incidentType: 'IT.001',
                                    })
                                }}/>

                                

                                <MultiTabFieldsContainerComponent<HistologyComponentParams> key="3" onTabChange={(newTab=>{setSelectedDiagnosisTabKey(newTab)})} selectedTab={selectedDiagnosisTabKey} tabsContentParams={histologyParams!} isEditingEnable={editingComponentKey===undefined || editingComponentKey===histologyParams!.panelTitle} isMessageSending={isHistologyMessageSending} archivingProgress={histologyArchivingProgress} isEditMode={editingComponentKey===histologyParams!.panelTitle } onEditClick={()=>setEditingComponentKey(histologyParams!.panelTitle)} onRevertClick={()=>{
                                    setEditingComponentKey(undefined);
                                }} onSaveClick={(newValues, messages?: BaseFhirModel[],completeCallback?) => {
                                    if(!messages) return;
                                    setIsHistologyMessageSending(true);
                                    setHistologyArchivingProgress(0);
                                    
                                    const observations$ = (messages as HistologicalObservation[])!.map((m:HistologicalObservation): Observable<any> => {
                                        if(m.friendlyResourceName === FhirResourceFriendlyName.histological){
                                            const observation = m.wireData;
                                            observation.effectiveDateTime = m.occuredDate;
                                            observation.issuedDate = m.occuredDate;
                                            const observationFhir = fieldMapperService.mapObservationToFhir(observation, {tag:[{code:"histological"}]});
                                            return fhirService.addObservation(observationFhir);
                                        }
                                        else {
                                            throw new Error(`Unhandled Observation resource name: ${m.friendlyResourceName}!`);
                                        }
                                    });
                                    
                                    forkJoin(observations$)
                                        .subscribe({
                                            next(value) {
                                                setEditingComponentKey(undefined);
                                                setIsHistologyMessageSending(false);
                                                setHistologyArchivingProgress(undefined);
                                                notification.success({message:"Η υποβολή έγινε δεκτή και το μητρώο ενημερώθηκε"});
                                                if (!patient?.histologies) {
                                                    patient.histologies = [];
                                                }
                                                else {
                                                    patient.histologies = (patient?.histologies as Histology[]).filter((h: Histology) => h.conditionId !== (newValues as Histology[])[0].conditionId) ?? [];
                                                }
                                                (newValues as Histology[])?.forEach(v => patient?.histologies.push(v));
                                                const _histologyParams = new HistologyComponentParams(patient);
                                                _histologyParams.initialValuesOfTabs = histologyParams!.initialValuesOfTabs;
                                                _histologyParams.initialValuesOfTabs[Number(selectedDiagnosisTabKey)].initialValues = newValues as Histology[];
                                                completeCallback!(true);
                                                setHistologyParams(_histologyParams);
                                            },
                                            error(err) {
                                                completeCallback!(false);
                                                setIsHistologyMessageSending(false);
                                                setHistologyArchivingProgress(undefined);
                                            },
                                        });
                                }} />
                                <MultiTabFieldsContainerComponent<StagingComponentParams> key="4" onTabChange={(newTab=>{setSelectedDiagnosisTabKey(newTab)})} selectedTab={selectedDiagnosisTabKey} tabsContentParams={stagingParams!} isEditingEnable={editingComponentKey===undefined || editingComponentKey===stagingParams!.panelTitle} isMessageSending={isStagingMessageSending} archivingProgress={stagingArchivingProgress} isEditMode={editingComponentKey===stagingParams!.panelTitle } onEditClick={()=>setEditingComponentKey(stagingParams!.panelTitle)} onRevertClick={()=>{
                                    setEditingComponentKey(undefined);
                                }} onSaveClick={(newValues, messages?: BaseFhirModel[],completeCallback?) => {
                                    if(!messages) return;
                                    setIsStagingMessageSending(true);
                                    setStagingArchivingProgress(0);
                                    
                                    const observations$ = messages!.map((m): Observable<any> => {
                                        if(m.friendlyResourceName === FhirResourceFriendlyName.staging){
                                            const observation = (m as any).wireData;
                                            
                                            const observationFhir = fieldMapperService.mapObservationToFhir(observation, {tag:[{code:"staging"}]});
                                            return fhirService.addObservation(observationFhir);
                                        }
                                        else {
                                            throw new Error(`Unhandled Observation resource name: ${m.friendlyResourceName}!`);
                                        }
                                    });
                                    
                                    forkJoin(observations$)
                                        .subscribe({
                                            next(value) {
                                                setEditingComponentKey(undefined);
                                                setStagingArchivingProgress(undefined);
                                                setIsStagingMessageSending(false);
                                                notification.success({message:"Η υποβολή έγινε δεκτή και το μητρώο ενημερώθηκε"});
                                                if (!patient?.stagings) {
                                                    patient.stagings=[];
                                                }
                                                else {
                                                    patient.stagings = (patient?.stagings as Staging[]).filter((h: Staging) => h.conditionId !== (newValues as Staging[])[0].conditionId) ?? [];
                                                }
                                                (newValues as Staging[])?.forEach(v => patient.stagings.push(v));
                                                const _stagingParams = new StagingComponentParams(patient);
                                                _stagingParams.initialValuesOfTabs = stagingParams!.initialValuesOfTabs;
                                                _stagingParams.initialValuesOfTabs[Number(selectedDiagnosisTabKey)].initialValues = newValues as Staging[];
                                                completeCallback!(true);
                                                setStagingParams(_stagingParams);
                                            },
                                            error(err) {
                                                completeCallback!(false);
                                                setStagingArchivingProgress(undefined);
                                                setIsStagingMessageSending(false);
                                            },
                                        });
                                }} />
                                <MultiTabFieldsContainerComponent<BiomarkerComponentParams> key="5" onTabChange={(newTab=>{setSelectedDiagnosisTabKey(newTab)})} selectedTab={selectedDiagnosisTabKey} tabsContentParams={biomarkerParams!} isEditingEnable={editingComponentKey===undefined || editingComponentKey===biomarkerParams!.panelTitle} isMessageSending={isBiomarkerMessageSending} archivingProgress={biomarkerArchivingProgress} isEditMode={editingComponentKey===biomarkerParams!.panelTitle } onEditClick={()=>setEditingComponentKey(biomarkerParams!.panelTitle)} onRevertClick={()=>{
                                    setEditingComponentKey(undefined);
                                }} onSaveClick={(newValues, messages,completeCallback?) => {
                                    if(!messages || !messages.length) return;
                                    
                                    const observations$ = messages!.map((m): Observable<any> => {
                                        if(m.friendlyResourceName === FhirResourceFriendlyName.biomarker){
                                            const observation = (m as any).wireData as ObservationWireData;
                                            observation.issuedDate = m.occuredDate;
                                            const observationFhir = fieldMapperService.mapObservationToFhir(observation, {tag:[{code:"biomarker"}]});
                                            return fhirService.addObservation(observationFhir);
                                        }
                                        else {
                                            throw new Error(`Unhandled Observation resource name: ${m.friendlyResourceName}!`);
                                        }
                                    });
                                    
                                    setIsBiomarkerMessageSending(true);
                                    setBiomarkerArchivingProgress(0);

                                    forkJoin(observations$)
                                        .subscribe({
                                            next(value) {
                                                notification.success({message:"Η υποβολή έγινε δεκτή και το μητρώο ενημερώθηκε"});
                                                setEditingComponentKey(undefined);
                                                setIsBiomarkerMessageSending(false);
                                                setBiomarkerArchivingProgress(undefined);
                                                if (!patient?.biomarkers) {
                                                    patient.biomarkers = [];
                                                }
                                                else {
                                                    patient.biomarkers = patient?.biomarkers.filter(b => b.conditionId !== (newValues as BiomarkerContainerModel[])[0].conditionId) ?? [];
                                                }
                                                (newValues as BiomarkerContainerModel[])?.forEach(v => patient?.biomarkers.push(v));
                                                const _biomarkerParams = new BiomarkerComponentParams(patient);
                                                _biomarkerParams.initialValuesOfTabs = biomarkerParams!.initialValuesOfTabs;
                                                _biomarkerParams.initialValuesOfTabs[Number(selectedDiagnosisTabKey)].initialValues = newValues as BiomarkerContainerModel[];
                                                completeCallback!(true);
                                                setBiomarkerParams(_biomarkerParams);
                                            },
                                            error(err) {
                                                completeCallback!(false);
                                                setIsBiomarkerMessageSending(false);
                                                setBiomarkerArchivingProgress(undefined);
                                            },
                                        });
                                }} />
                                <MultiTabFieldsContainerComponent<TherapyComponentParams> key="6" onTabChange={(newTab=>{setSelectedDiagnosisTabKey(newTab)})} selectedTab={selectedDiagnosisTabKey} tabsContentParams={therapyParams!} isEditingEnable={editingComponentKey===undefined || editingComponentKey===therapyParams!.panelTitle} isMessageSending={isTherapyMessageSending} archivingProgress={therapyArchivingProgress} isEditMode={editingComponentKey===therapyParams!.panelTitle } onEditClick={()=>setEditingComponentKey(therapyParams!.panelTitle)} onRevertClick={()=>{
                                    setEditingComponentKey(undefined);
                                }} onSaveClick={(newValues, messages,completeCallback?)=>{
                                    if(!messages || messages.length <= 0) return;

                                    setIsTherapyMessageSending(true);
                                    setTherapyArchivingProgress(0);

                                    const fhirResources = messages.map(x => fieldMapperService.mapProcedureToFhir(((x as any).wireData as ProcedureWireData)));

                                    forkJoin(fhirResources.map(x => fhirService.updateProcedure(x)))
                                    .subscribe({
                                        next: (data) => {
                                            setTherapyArchivingProgress(undefined);
                                            setIsTherapyMessageSending(false);
                                            setEditingComponentKey(undefined);
                                            notification.success({message:"Η υποβολή έγινε δεκτή και το μητρώο ενημερώθηκε"});
                                            if (!patient?.therapies) {
                                                patient.therapies=[];
                                            }
                                            else {
                                                patient.therapies = (patient?.therapies as Therapy[]).filter((t: Therapy) => t.conditionId !== (newValues as Therapy[])[0].id) ?? [];
                                            }
                                            (newValues as Therapy[])?.forEach(v => patient?.therapies.push(v));
                                            const _therapyParams = new TherapyComponentParams(patient);
                                            _therapyParams.initialValuesOfTabs = therapyParams!.initialValuesOfTabs;
                                            _therapyParams.initialValuesOfTabs[Number(selectedDiagnosisTabKey)].initialValues = newValues as Therapy[];
                                            completeCallback!(true);
                                            setTherapyParams(_therapyParams);
                                        },
                                        error: async (err) => {
                                            setTherapyArchivingProgress(undefined);
                                            setIsTherapyMessageSending(false);
                                            completeCallback!(false);
                                        },
                                        complete: () => {
                                        },
                                    });

                                }} onAddClick={() => {
                                    const diagnosis = diagnosisParams!.initialValuesOfTabs[Number(selectedDiagnosisTabKey)].initialValues[0];
                                    const thisTherapyToAdd = new Therapy(null, diagnosis.id!);
                                    setTherapryToAdd(thisTherapyToAdd);
                                    setIsTherapyToAddValid(false);
                                }}/>
                                <MultiTabFieldsContainerComponent<ProgressComponentParams> key="7" onTabChange={(newTab=>{setSelectedDiagnosisTabKey(newTab)})} selectedTab={selectedDiagnosisTabKey} tabsContentParams={progressParams!} isEditingEnable={editingComponentKey===undefined || editingComponentKey===progressParams!.panelTitle} isMessageSending={isProgressMessageSending} archivingProgress={progressArchivingProgress} isEditMode={editingComponentKey===progressParams!.panelTitle } onEditClick={()=>setEditingComponentKey(progressParams!.panelTitle)} onRevertClick={()=>{
                                    setEditingComponentKey(undefined);
                                }} onSaveClick={(newValues, messages,completeCallback?) => {
                                    const observations$ = messages!.map((m):Observable<any> => {
                                        const observation = (m as Observation).wireData;
                                        observation.issuedDate = m.occuredDate;
                                        const obsToSend = fieldMapperService.mapObservationToFhir(observation, {tag:[{code:"progress"}]});
                                        return fhirService.updateObservation(obsToSend);
                                    });

                                    setIsProgressMessageSending(true);
                                    setProgressArchivingProgress(0);
                                    forkJoin(observations$)
                                        .subscribe({
                                            next(value) {
                                                notification.success({message:"Η υποβολή έγινε δεκτή και το μητρώο ενημερώθηκε"});
                                                setEditingComponentKey(undefined);
                                                setIsProgressMessageSending(false);
                                                setProgressArchivingProgress(undefined);
                                                if (!patient.progresses) {
                                                    patient.progresses=[];
                                                }
                                                else {
                                                    patient.progresses = (patient.progresses as Progress[]).filter((p: Progress) => p.conditionId !== (newValues as Progress[])[0].conditionId) ?? [];
                                                }
                                                newValues?.forEach(v => patient.progresses.push(v as Progress));
                                                const _progressParams = new ProgressComponentParams(patient);
                                                _progressParams.initialValuesOfTabs = progressParams!.initialValuesOfTabs;
                                                _progressParams.initialValuesOfTabs[Number(selectedDiagnosisTabKey)].initialValues = newValues as Progress[];
                                                completeCallback!(true);
                                                setProgressParams(_progressParams);
                                            },
                                            error(err) {
                                                completeCallback!(false);
                                                setIsProgressMessageSending(false);
                                                setProgressArchivingProgress(undefined);
                                            },
                                        });
                                }} onAddClick={()=> {
                                    const diagnosis = diagnosisParams!.initialValuesOfTabs[Number(selectedDiagnosisTabKey)].initialValues[0];
                                    const thisProgressToAdd = new Progress(null, diagnosis.id!);
                                    setProgressToAdd(thisProgressToAdd);
                                    setIsProgressToAddValid(false);
                                }}/>
                            </Space>
                        }
                        {currentView === 'doctors' &&
                            <PatientDoctorsComponent patientId={patient.id} />
                        }
                        {currentView === 'history' &&
                            <>
                                <PatientSubmissionsComponent patientId={patient.id}/>
                                
                                
                            </>
                        }
                        {currentView === 'findings' && <FieldsContainerComponent<PathologoReportsComponentParams> mode='card' contentParams={pathologoReportsParams!} isMessageSending={isPathologoReportsMessageSending} archivingProgress={pathologoReportsArchivingProgress} isEditMode={isPathologoReportsEditMode } onEditClick={()=>setIsPathologoReportsEditMode(true)} onRevertClick={()=>{
                                setIsPathologoReportsEditMode(false);
                            }} onSaveClick={(newValues, messages: DiagnosticReport[],completeCallback?) => {
                                if(!messages || !messages.length) return;

                                setIsPathologoReportsMessageSending(true);

                                const obsList$ = messages.map(m => {
                                    const reportFhir = fieldMapperService.mapDiagnosticReportToFhir(m.wireData!);
                                    return fhirService.updateDiagnosticReport(reportFhir);
                                });
                                
                                forkJoin(obsList$)
                                    .subscribe({
                                        next(value) {
                                            notification.success({message:"Η υποβολή έγινε δεκτή και το μητρώο ενημερώθηκε"});
                                            setIsPathologoReportsEditMode(false);
                                            setIsPathologoReportsMessageSending(false);
                                            setPathologoReportsArchivingProgress(undefined);
                                            const pathologoReportsParams = new PathologoReportsComponentParams(patient);
                                            pathologoReportsParams.initialValues = newValues as PathologoReport[];
                                            setPathologoReportsParams(pathologoReportsParams);
                                            completeCallback!(true);
                                        },
                                        error(err) {
                                            completeCallback!(false);
                                            setIsPathologoReportsMessageSending(false);
                                            setPathologoReportsArchivingProgress(undefined);
                                        },
                                    });
                            }} onAddClick={()=> {
                                const thisPathologoToAdd = new PathologoReport(null);
                                setPathologoReportToAdd(thisPathologoToAdd);
                                setIsPathologoReportToAddValid(false);
                            }}/>
                        }
                    </Layout.Content>


                    <Modal
                        open={diagnosisToAdd !== undefined} width={'fit-content'} 
                        closable={false}
                        title={diagnosisToAdd?.incidentType === 'IT.002' ? 'Προσθήκη μετάστασης' :
                            diagnosisToAdd?.incidentType === 'IT.004' ? 'Προσθήκη μετασχηματισμού' :
                            'Προσθήκη νέου νεοπλάσματος'}
                        onCancel={()=>setDiagnosisToAdd(undefined)}
                        destroyOnClose={true}
                        cancelText="Άκυρο"
                        okText="Υποβολή"
                        okButtonProps={{disabled:!isDiagnosisToAddValid, loading:isDiagnosisMessageSending}}
                        cancelButtonProps={{disabled:isDiagnosisMessageSending}}
                        style={{minWidth:1100}}
                        onOk={()=> {
                            const thisDiagnosisToAdd = {...diagnosisToAdd} as Diagnosis;
                            setIsDiagnosisMessageSending(true);
                            setDiagnosisArchivingProgress(0);
                            
                            const condtionToSend = fieldMapperService.mapConditionToFhir(amka!, thisDiagnosisToAdd);
                            fhirService.validateCreateCondition(condtionToSend).subscribe({
                                next: (validationResponse:QcsToolResponse) => {
                                    if ((validationResponse.errors && validationResponse.errors.length)) {
                                        setDiagnosisArchivingProgress(undefined);
                                        setIsDiagnosisMessageSending(false);
                                        setIsDiagnosisToAddValid(false);
                                        Modal.error({title:'Σφάλμα υποβολής', content:<div style={{whiteSpace:'pre-line'}}>{validationResponse.errors.map(e=>`${e.error_description_gr} (${e.var1_name_gr}: ${e.var1_value})` + "\r\n")}</div>});
                                        return;
                                    }
                                    if ((validationResponse.warnings && validationResponse.warnings.length)) {
                                        Modal.confirm({
                                            onCancel:()=>{
                                                setIsDiagnosisMessageSending(false);
                                                setDiagnosisArchivingProgress(undefined);
                                            },
                                            onOk: ()=>{
                                                fhirService.addCondition(condtionToSend).subscribe({
                                                    next: (data) => {
                                                        setIsDiagnosisMessageSending(false);
                                                        setDiagnosisArchivingProgress(undefined);
                                                        setEditingComponentKey(undefined);
                                                        setDiagnosisToAdd(undefined);
                                                        setIsDiagnosisToAddValid(false);
                                                        notification.success({message:"Η υποβολή έγινε δεκτή και το μητρώο ενημερώθηκε"});
                                                        setIsPatientLoading(true);
                                                        setTimeout(()=>setIsPatientLoading(false), 100);
                                                    },
                                                    error: (err) => {
                                                        setDiagnosisArchivingProgress(undefined);
                                                        setIsDiagnosisMessageSending(false);
                                                        setIsDiagnosisToAddValid(false);
                                                    }
                                                });
                                            },
                                            okText:'Επιβεβαίωση',
                                            cancelText:'Ακύρωση',
                                            title:'Απαιτείται επιβεβαίωση', 
                                            content:<div style={{whiteSpace:'pre-line'}}>{validationResponse.warnings.map(e=>`${e.error_description_gr} (${e.var1_name_gr}: ${e.var1_value})` + "\r\n")}</div>},                                            
                                        );
                                        return;
                                    }
                                    fhirService.addCondition(condtionToSend).subscribe({
                                        next: (data) => {
                                            setIsDiagnosisMessageSending(false);
                                            setDiagnosisArchivingProgress(undefined);
                                            setEditingComponentKey(undefined);
                                            setDiagnosisToAdd(undefined);
                                            setIsDiagnosisToAddValid(false);
                                            notification.success({message:"Η υποβολή έγινε δεκτή και το μητρώο ενημερώθηκε"});
                                            setIsPatientLoading(true);
                                            setTimeout(()=>setIsPatientLoading(false), 100);
                                        },
                                        error: (err) => {
                                            setDiagnosisArchivingProgress(undefined);
                                            setIsDiagnosisMessageSending(false);
                                            setIsDiagnosisToAddValid(false);
                                        }
                                    });
                                }, error: (err) => {
                                    setDiagnosisArchivingProgress(undefined);
                                    setIsDiagnosisMessageSending(false);
                                    setIsDiagnosisToAddValid(false);
                                }
                            });
                            
                        }}
                        >
                        <div style={{width:1000}}>
                            <DiagnosisComponent isEditMode={true} isAddingNew={true} initialValue={diagnosisToAdd!} patient={patient!} onValueChange={(newValue, fhirMessages, isValid)=> {
                                setIsDiagnosisToAddValid(isValid);
                                setDiagnosisToAdd(newValue);
                            }}></DiagnosisComponent>
                        </div>
                    </Modal>

                    <Modal
                        open={therapyToAdd!== undefined} width={'fit-content'}
                        closable={false} title='Προσθήκη νέας θεραπείας'
                        onCancel={()=>{
                            setTherapryToAdd(undefined);
                        }}
                        destroyOnClose={true}
                        cancelText="Άκυρο"
                        okText="Υποβολή"
                        okButtonProps={{disabled:!isTherapyToAddValid, loading:isTherapyMessageSending}}
                        cancelButtonProps={{disabled:isTherapyMessageSending}}
                        style={{minWidth:1000}}
                        onOk={()=> {
                            const thisTherapyToAdd = {...therapyToAdd} as Therapy;
                            setIsTherapyMessageSending(true);
                            setTherapyArchivingProgress(0);
                            fhirService.addProcedure(fieldMapperService.mapProcedureToFhir({
                                patientId: patient!.id,
                                conditionId: thisTherapyToAdd.conditionId,
                                code: thisTherapyToAdd.therapyKind,
                                surgeryEtipCode: thisTherapyToAdd.surgeryEtipCode,
                                chemoTherapyType: thisTherapyToAdd.chemoTherapyType,
                                radioTherapyType: thisTherapyToAdd.radioTherapyType,
                                otherTherapyType: thisTherapyToAdd.otherTherapyType,
                                surgeryLimitsType: thisTherapyToAdd.surgeryLimitsType,
                                occurrenceDateTime: thisTherapyToAdd.therapyDate,
                                numberOfExcludedLymphNodes: thisTherapyToAdd.surgeryNumberOfExcludedLymphNodes,
                                numberOfInfiltratedLymphNodes: thisTherapyToAdd.surgeryNumberOfInfiltratedLymphNodes,
                                presenceOfInfiltratedLymphNodes: thisTherapyToAdd.surgeryLymphInfiltrationExistenceType,
                                presenceOfInfiltratedNerves: thisTherapyToAdd.surgeryNevreFiltrationExistenceType,
                                // stagingObservationId: thisTherapyToAdd.surgeryStagingId,
                            } as ProcedureWireData))
                            .subscribe({
                                next: (data) => {
                                    setIsTherapyMessageSending(false);
                                    setTherapyArchivingProgress(undefined);
                                    setEditingComponentKey(undefined);
                                    setTherapryToAdd(undefined);
                                    setIsTherapyToAddValid(false);
                                    notification.success({message:"Η υποβολή έγινε δεκτή και το μητρώο ενημερώθηκε"});
                                    setIsPatientLoading(true);
                                    setTimeout(()=>setIsPatientLoading(false), 100);
                                },
                                error: async (err) => {
                                    setTherapyArchivingProgress(undefined);
                                    setIsTherapyMessageSending(false);
                                    setIsTherapyToAddValid(false);
                                }
                            });
                        }}
                    >
                        <div style={{width:900}}>
                             <TherapyComponent isEditMode={true} isAddingNew={true} initialValue={therapyToAdd!} patient={patient} onValueChange={(newValue, fhirMessages, isValid)=> {
                                setIsTherapyToAddValid(isValid);
                                setTherapryToAdd(newValue);
                            }}></TherapyComponent>
                        </div>
                    </Modal>

                    <Modal
                        open={progressToAdd!== undefined} width={'fit-content'}
                        closable={false} title='Νέα καταγραφή κατάστασης νόσου'
                        onCancel={()=>{
                            setProgressToAdd(undefined);
                        }}
                        destroyOnClose={true}
                        cancelText="Άκυρο"
                        okText="Υποβολή"
                        okButtonProps={{disabled:!isProgressToAddValid, loading:isProgressMessageSending}}
                        cancelButtonProps={{disabled:isProgressMessageSending}}
                        style={{minWidth:1000}}
                        onOk={() => {
                            const thisProgressToAdd = {...progressToAdd} as Progress;
                            const observation = (fhirMessages[0] as any).wireData as ObservationWireData;
                            const observationFhir = fieldMapperService.mapObservationToFhir(observation, {tag:[{code:"progress"}]});
                            setIsProgressMessageSending(true);
                            setProgressArchivingProgress(0);
                            fhirService.addObservation(observationFhir)
                                .subscribe({
                                    next(value) {
                                        setIsProgressMessageSending(false);
                                        setProgressArchivingProgress(undefined);
                                        setEditingComponentKey(undefined);
                                        setProgressToAdd(undefined);
                                        setIsProgressToAddValid(false);
                                        notification.success({message:"Η υποβολή έγινε δεκτή και το μητρώο ενημερώθηκε"});
                                        setIsPatientLoading(true);
                                        setTimeout(()=>setIsPatientLoading(false), 100);
                                    },
                                    error(err) {
                                        setProgressArchivingProgress(undefined);
                                        setIsProgressMessageSending(false);
                                        setIsProgressToAddValid(false);
                                    },
                                });

                        }}
                    >
                        <div style={{width:900}}>
                             <ProgressComponent isEditMode={true} isAddingNew={true} initialValue={progressToAdd!} patient={patient} onValueChange={(newValue, fhirMessages, isValid)=> {
                                setIsProgressToAddValid(isValid);
                                setProgressToAdd(newValue);
                                setFhirMessages(fhirMessages);
                            }}></ProgressComponent>
                        </div>
                    </Modal>

                    <Modal
                        open={pathologoReportToAdd !== undefined} width={'fit-content'}
                        closable={false} title='Προσθήκη παθολογοανατομικού πορίσματος'
                        onCancel={()=>{
                            setPathologoReportToAdd(undefined);
                        }}
                        destroyOnClose={true}
                        cancelText="Άκυρο"
                        okText="Υποβολή"
                        okButtonProps={{disabled:!isPathologoReportToAddValid, loading:isPathologoReportsMessageSending}}
                        cancelButtonProps={{disabled:isPathologoReportsMessageSending}}
                        style={{minWidth:1000}}
                        onOk={()=> {
                            if(fhirMessages.length <= 0) return;

                            const reportData = (fhirMessages[0] as DiagnosticReport).wireData;
                            const reportFhir = fieldMapperService.mapDiagnosticReportToFhir(reportData!);
                            
                            setIsPathologoReportsMessageSending(true);
                            setPathologoReportsArchivingProgress(0);
                            fhirService.addDiagnosticReport(reportFhir)
                                .subscribe({
                                    next(value) {
                                        setIsPathologoReportsMessageSending(false);
                                        setPathologoReportsArchivingProgress(undefined);
                                        setEditingComponentKey(undefined);
                                        setPathologoReportToAdd(undefined);
                                        setIsPathologoReportToAddValid(false);
                                        notification.success({message:"Η υποβολή έγινε δεκτή και το μητρώο ενημερώθηκε"});
                                        setIsPatientLoading(true);
                                        setTimeout(()=>setIsPatientLoading(false), 100);

                                    },
                                    error(err) {
                                        setPathologoReportsArchivingProgress(undefined);
                                        setIsPathologoReportsMessageSending(false);
                                        setIsPathologoReportToAddValid(false);
                                    },
                                });
                        }}
                    >
                        <div style={{width:900}}>
                             <PathologoReportComponent isEditMode={true} isAddingNew={true} initialValue={pathologoReportToAdd!} patient={patient}
                                onValueChange={(newValue, fhirMessages, isValid)=> {
                                    setIsPathologoReportToAddValid(isValid);
                                    setPathologoReportToAdd(newValue);
                                    setFhirMessages(fhirMessages);
                                }}></PathologoReportComponent>
                        </div>
                    </Modal>

                </Layout>}
            </>}
        </>
    );
}

export default PatientPage;