import { Alert, Button, Card, Form, FormProps, Input, Modal, Checkbox, Radio, Typography, Select, notification } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthService from '../services/auth-service';
import FhirService from '../services/fhir-service';
import ObjectOperationsService from '../services/object-operations-service';
import dayjs from 'dayjs';
import { DATE_FORMAT } from '../constants';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { User } from '../models/user';


type FieldType = {
    amka?: string;
};


function SearchByAmkaPage() {
    const fhirService = new FhirService();
    const objectOperationsService = new ObjectOperationsService();
    const navigate = useNavigate();
    const authService = AuthService.getInstance();
    const currentUser = AuthService.getInstance().getCurrentUser();
    const currentOrganization = AuthService.getInstance().getActiveOrganization();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [patientAmkaToVerifyMyAccess, setPatientAmkaToVerifyMyAccess] = useState<string | undefined>(undefined);
    const [isAccessVerifying, setIsAccessVerifying] = useState<boolean>(false);
    const [verifierUserId, setVerifierUserId] = useState<number|undefined>(undefined);
    const [usersOfActiveOrg, setUsersOfActiveOrg] = useState<User[]>([]);
    
    const isNewCitizen = useRef(false);
    const onFormSubmit: FormProps<FieldType>['onFinish'] = (values) => {
        setIsLoading(true);
        isNewCitizen.current = false;
        if(values.amka === undefined) return;
        
        fhirService.getPatientByAmka(values.amka)
            .subscribe({
                next: (data) => {
                    setIsLoading(false);
                    console.log(data);
                    navigate(`/patients/${values.amka}`);
                },
                error: (err) => {
                    if(err.statusCode === 404) {
                        isNewCitizen.current = true;
                        const obs$ = fhirService.getEPrescriptionPatientByAmka(values.amka!);
                        obs$.subscribe({
                            next: async (data) => {
                                setIsLoading(false);
                                const patientFhir = data;
                                const nameFamily = objectOperationsService.getPropertyByPath(patientFhir, 'name.0.family')?.propValue ?? '';
                                const nameGiven = objectOperationsService.getPropertyByPath(patientFhir, 'name.0.given.0')?.propValue ?? '';
                                const birthDate = objectOperationsService.getPropertyByPath(patientFhir, 'birthDate')?.propValue ?? '';
                                const fullName = nameGiven + ' ' + nameFamily;
                                Modal.confirm({
                                    onOk: () => navigate(`/citizens/${values.amka}`),
                                    okText:"ΝΑΙ, καταχώριση στο ΕΜΜΝ",
                                    cancelText:"ΟΧΙ",
                                    content: <div>
                                        <div>Ο/Η <strong>{fullName}</strong> με ημερομηνία γέννησης {birthDate ? dayjs(birthDate).format(DATE_FORMAT) : ''} δεν είναι καταχωρισμένος/η στο μητρώο. Επιθυμείτε να προβείτε στην καταχώρισή του/της τώρα καταγράφοντας τη διάγνωση για το νεοπλασματικό νόσημα;</div>
                                    </div>
                                });
                            },
                            error: (err) => {
                                if(err.statusCode === 404){
                                    setIsLoading(false);
                                    notification.error({message:"Δεν βρέθηκε πολίτης με αυτό το ΑΜΚΑ*."});
                                } else if (err.statusCode = 451) {
                                    axios.get(`/api/organizations/${currentOrganization!.id}/users`).then(usersResult => {
                                        const users = usersResult.data.data as User[];
                                        setUsersOfActiveOrg(users);
                                        setPatientAmkaToVerifyMyAccess(values.amka);
                                        setIsLoading(false);
                                    });
                                }
                                else {
                                    setIsLoading(false);
                                    notification.error({message:"Παρουσιάστηκε απρόσμενο σφάλμα"});
                                }
                            }
                        });
                    } else if (err.statusCode === 451) {
                        if (currentUser.is_trainee) {
                            axios.get(`/api/organizations/${currentOrganization!.id}/users`).then(usersResult => {
                                const users = usersResult.data.data as User[];
                                setUsersOfActiveOrg(users);
                                setPatientAmkaToVerifyMyAccess(values.amka);
                                setIsLoading(false);
                            });
                            return;
                        }
                        setPatientAmkaToVerifyMyAccess(values.amka);
                        setIsLoading(false);
                    }
                    else {
                        setIsLoading(false);
                    }
                }
            });
    };

    useEffect(()=>{
        if (!authService.getActiveOrganization()) {
            navigate('/active-organization');
            return;
        }
    },[]);



    return (
        <>
            <div style={{display:'flex',flexDirection:'column',alignItems: "center",justifyContent: "center",height:"100%"}}>
                <Card className='amka_search_card hipa_card_shadow' title="Αναζήτηση με ΑΜΚΑ*" style={{ width: 600 }}>
                    <Alert type='warning' description={<span>Τα δικαιώματα ανάσυρσης δεδομένων του μητρώου περιγράφονται στο <a href='/950b-24.pdf' target='_blank'>ΦΕΚ 950/9-2-2024 τ. β'</a>. Οι ενέργειες αναζήτησης καταγράφονται.</span>}/>
                    <Form
                        layout='vertical'
                        style={{ maxWidth: 600 }}
                        onFinish={onFormSubmit}
                        autoComplete="off"
                    >
                        <Form.Item<FieldType>
                            label="ΑΜΚΑ"
                            name="amka"
                            rules={[
                                { required: true },
                                { 
                                    validator: (_, value) => {
                                        if (!value || value.trim() === '') {
                                            return Promise.reject(new Error('Το πεδίο δεν μπορεί να είναι κενό.'));
                                        }
                                        return Promise.resolve();
                                    }
                                },
                            ]}
                            help="*Ο χρήστης μπορεί να εισάγει ΑΜΚΑ, ΠΑΜΚΑ, ΠΑΑΥΠΑ, ΠΑΥΠΕΚ."
                        >
                            <Input max={50} readOnly={isLoading} />
                        </Form.Item>

                        

                        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        <Button loading={isLoading} type="primary" htmlType="submit">
                            Αναζήτηση
                        </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </div>

            <Modal 
                open={patientAmkaToVerifyMyAccess !== undefined}
                closable={false}
                onClose={()=>setPatientAmkaToVerifyMyAccess(undefined)}
                onCancel={()=>setPatientAmkaToVerifyMyAccess(undefined)}
                cancelText="OXI"
                okText="ΝΑΙ"
                okButtonProps={{loading:isAccessVerifying, disabled:currentUser.is_trainee?verifierUserId===undefined:false}}
                onOk={()=>{
                    setIsAccessVerifying(true);
                    if (currentUser.is_trainee) {
                        const dataToPost = {user_id:currentUser.id, primary_user_id:verifierUserId, patient_identifers:[patientAmkaToVerifyMyAccess]};
                        axios.post(`/api/organizations/${currentOrganization!.id}/trainee-patient-assignments`, dataToPost ).then((response:AxiosResponse)=>{
                            setIsAccessVerifying(false);
                            if (isNewCitizen.current) navigate(`/citizens/${patientAmkaToVerifyMyAccess}`)
                            else navigate(`/patients/${patientAmkaToVerifyMyAccess}`);
                        }).catch(()=>{
                            
                        });
                    
                    } else {
                        axios.post(`/api/organizations/${currentOrganization!.id}/explicit-user-patient-corellations`, {patient_identifer:patientAmkaToVerifyMyAccess}).then((response:AxiosResponse)=>{
                            setIsAccessVerifying(false);
                            if (isNewCitizen.current) navigate(`/citizens/${patientAmkaToVerifyMyAccess}`)
                            else navigate(`/patients/${patientAmkaToVerifyMyAccess}`);
                        })
                    }
                }}
                
            >
                <Typography.Paragraph>
                    Ο ασθενής με ΑΜΚΑ* <strong>{patientAmkaToVerifyMyAccess}</strong>{isNewCitizen.current? ' δεν': ''} είναι καταχωρισμένος στο μητρώο. Πρόσβαση στα στοιχεία του έχουν οι ιατροί και οι χρήστες εργαστηριακής διερεύνησης των μονάδων υγείας από τις οποίες λαμβάνει θεραπεία και υπηρεσίες υγείας.
                </Typography.Paragraph>
                
                <Typography.Paragraph>
                    Δηλώνετε υπεύθυνα πως πληρούνται τα κριτήρια που περιγράφονται στο ΦΕΚ 950/9-2-2024 τ. β' άρθρο 3 παράγραφος 4;
                </Typography.Paragraph>

                {currentUser.is_trainee && <>
                    <Typography.Paragraph>
                        Για να αποκτήσετε πρόσβαση στα στοιχεία του μητρώου του ασθενούς ως ειδικευόμενος, απαιτείται η ρητή ανάθεση πρόσβασης από ιατρό που παρέχει υπηρεσίες υγείας στον συγκεκριμένο ασθενή.
                    </Typography.Paragraph>

                    <Form layout='vertical'>
                        <Form.Item
                            required={true}
                            validateStatus={verifierUserId?'success':'error'}
                            help={verifierUserId?'':'Το πεδίο απαιτείται'}
                            label="Ιατρός που σας ανέθεσε ρητά την πρόσβαση">
                            <Select placeholder="Επιλέξτε ιατρό" options={usersOfActiveOrg.filter(u=>!u.is_trainee).map(u=>{
                                return {value:u.id, label:`${u.name} (${u.idika_amka})`}
                            })} allowClear={true} value={verifierUserId} onClear={()=>setVerifierUserId(undefined)} onSelect={(id)=>setVerifierUserId(id)}/>
                        </Form.Item>
                    </Form>
                </>}
            </Modal>
        </>
    );
}

export default SearchByAmkaPage;